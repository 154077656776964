import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import i18next from 'i18next';
import i18n from '../i18n';
import {useTranslation} from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import {confirmAlert} from 'react-confirm-alert';
import '../css/App.css';

import {
  CASE_DESCRIPTION_TYPE_PROG_CALL,
  INSTALLATION_STATUS_OBJID_VAR,
  UnauthorizedError,
  WiMHomeAPI
} from '../api/wimhome-api';
import {connectMqtt, mqttSetInstallationSerial} from '../api/wimhome-mqtt';
import {wiMHomeMQTTClient} from '../api/WiMHomeMQTT';

import {CURRENT_USER_ACTIONS, CurrentUser} from './CurrentUser';

import InstallationsPage from './InstallationsPage';
import StatusPage from './StatusPage';
import LogPage from './LogPage';
import TechPage from './TechPage';
import Login from './Login';
import GraphPage from './GraphPage';
import TrendPage from './TrendPage';
import RemindersAdminPage from './RemindersAdminPage';
import ReminderActionsAdminPage from './ReminderActionsAdminPage';
import Error404 from './Error404';
import Privacy from './Privacy';
import Cookies from './Cookies';
import {LOCAL_STORAGE_KEYS, LOCAL_VERSION, REACT_APP_NEW_MQTT_AUTH, SITE_LINKS} from '../Contants';
import AlertDetailPage from './AlertDetailPage';
import {not_null_or_undefined, null_or_undefined} from '../utilities';
import {deletePushToken, getPushToken, requestPushPermission} from '../push-notification';

import dateAdd from 'date-fns/add';

import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import ReportsPage from "./ReportsPage";

export const MqttContext = React.createContext();
export const InstallationsContext = React.createContext();
export const GenericContext = React.createContext();

export const NO_ERROR_OBJECT = { lastErrorCode: 0, lastErrorMessage: null };

const wiMHomeAPIclient = new WiMHomeAPI(process.env.REACT_APP_WIMHOME_API_SERVER_URL + '/api/');

const MQTT_CONN_STATE = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected',
};

const SELF_DISCONNECTION_WARNING_TIME = 60; // seconds

let autoLogoutTimer = null;
let tokenTimestamp = null;

export function hasCapability(instData, cap) {
  if (null_or_undefined(instData)) return false;

  return instData.userCapabilities.includes(cap);
}

function saveTokenUpdateTimestamp() {
  tokenTimestamp = new Date();
  // console.log('saveTokenUpdateTimestamp: ', tokenTimestamp)
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN_TIMESTAMP, JSON.stringify(tokenTimestamp));
}

function App() {
  const [lastError, setLastError] = useState(NO_ERROR_OBJECT);
  const [currentUser, dispatchCurrentUser] = CurrentUser();
  const [mqttStatus, setMqttStatus] = useState(defaultEmptyMqttState);
  const [installations, setInstallations] = useState({});
  const [selectedInst, setSelectedInst] = useState(null);
  const [devicesState, setDevicesState] = useState({});
  const instDevicesUpdateMapping= useRef({});
  const [installationGuests, setInstallationGuests] = useState({});
  const [installationContacts, setInstallationContacts] = useState({});
  const [installationServices, setInstallationServices] = useState(null);
  const [installationReports, setInstallationReports] = useState(null);
  const [isFirstMqttConnection, setIsFirstMqttConnection] = useState(true);
  const [phyDevicesInfo, setPhyDevicesInfo] = useState(null);
  const [ackAlerts, setAckAlerts] = useState(new Map());
  const [alertSoundInfo, setAlertSoundInfo] = useState(null);
  const [alertCodes, setAlertCodes] = useState(new Map());

  const [jwtToken, setJwtToken] = useState(null);
  // const [authTokenTimestamp, setAuthTokenTimestamp] = useState(null);
  const [mustRedirectHome, setMustRedirectHome] = useState(false);
  const [alerter, setAlerter] = useState({ timer: null });
  const [alerts, setAlerts] = useState(new Map());
  const [curAlertsNumber, setCurAlertsNumber] = useState({"alarms": 0, "warnings": 0, "disconnections": 0});
  const [, /*theme*/ setTheme] = useState({ theme: 'default' });
  const [enableWebPush, setEnableWebPush] = useState(0);
  const [logoutWarningTimer, setLogoutWarningTimer] = useState(null);

  const [lastHeight, _setLastHeight] = useState(0);

  const [programmedCalls, setProgrammedCalls] = useState({"loaded": false, "calls": []});

  const lastHeightRef = React.useRef(lastHeight);
  const setLastHeight = (value) => {
    lastHeightRef.current = value;
    _setLastHeight(value);
  };

  const [mustUpdateStatus, setMustUpdateStatus] = useState(false);

  const { t /*, i18n*/ } = useTranslation();

  const history = useHistory();

  /* ***************************************************************************************
   *                              DYNAMIC vh MANAGEMENT                                    *
   *************************************************************************************** */
  const resizeVh = useCallback(() => {
    if (window.innerWidth > window.innerHeight || Math.abs(lastHeightRef.current - window.innerHeight) > 1) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      setLastHeight(window.innerHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeVh);
    return () => {
      window.removeEventListener('resize', resizeVh);
    };
  }, [resizeVh]);

  /* ***************************************************************************************
   *                          LOGIN AND LOGOUT MANAGEMENT                                  *
   *************************************************************************************** */
  const handleLogout = useCallback(() => {
    // console.log('Handle logout', new Date());
    if(logoutWarningTimer !== null) {
      clearTimeout(logoutWarningTimer);
      setLogoutWarningTimer(null);
    }
    if(autoLogoutTimer !== null)
      clearTimeout(autoLogoutTimer);
    // wiMHomeMQTTClient.disconnect();
    wiMHomeAPIclient.cancelTimeout();
    setLastError(NO_ERROR_OBJECT);
    dispatchCurrentUser({ type: CURRENT_USER_ACTIONS.LOGOUT });
    setMustRedirectHome(true);
  }, [dispatchCurrentUser, logoutWarningTimer]);

  const handleSignOut = useCallback(async () => {
    //    console.log('Handle sign-out');
    if (enableWebPush === 0) {
      handleLogout();
      return true;
    }

    return requestPushPermission().then((allowed) => {
      if (!allowed) {
        return wiMHomeAPIclient.signOut()
          .then((res) => {
              if (res !== true) {
                alert(`${t('applicationErrors.cannotDeregisterPush')}`);
              }
              handleLogout();
              return true;
            }
          );
      }
      return deletePushToken()
        .then((result) => {
          if (result !== true) {
            alert(`${t('applicationErrors.cannotDeregisterPush')}`);
          }
          return wiMHomeAPIclient.signOut();
        })
        .then((res) => {
          if (res !== true) {
            alert(`${t('applicationErrors.cannotDeregisterPush')}`);
          }
          handleLogout();
          return true;
        });
    });
  }, [enableWebPush, handleLogout, t]);

  useEffect(() => {
    if (currentUser.username === null && mustRedirectHome) {
      setMustRedirectHome(false);
      localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_USER, JSON.stringify(currentUser)); //This Ensure the lastUser is updated before redirect!
      //window.location.href = process.env.PUBLIC_URL+SITE_LINKS.LOGIN;
      history.push(SITE_LINKS.LOGIN);
    }
  }, [currentUser, history, mustRedirectHome]);

  useEffect(() => {
    if (currentUser.username === null) {
      if (mqttStatus.mqttConnected === 'connected') {
        // console.log('MQTT set as disconnected');
        wiMHomeMQTTClient.disconnect();
        setMqttStatus(defaultEmptyMqttState);
      }

      if (installations !== null) setInstallations(null);

      if (selectedInst !== null) setSelectedInst(null);

      if (devicesState !== null) setDevicesState(null);

      if (installationGuests !== null) setInstallationGuests(null);

      if (installationContacts !== null) setInstallationContacts(null);

      if (installationServices !== null) setInstallationServices(null);

      if (installationReports !== null) setInstallationReports(null);

      if (ackAlerts.size > 0) {
        setAckAlerts(new Map());
      }

      if (LOCAL_VERSION) setJwtToken(null);
    }
  }, [
    currentUser,
    mqttStatus,
    installations,
    selectedInst,
    devicesState,
    installationGuests,
    installationContacts,
    installationServices,
    installationReports,
    ackAlerts,
  ]);

  /*
   * Create momoized function to load installation device information
   */
  const loadAllInstallationData = useCallback(
    async (instSerial, returnHidden, returnSafety, includeTech) => {
      let instDevices = new Map();
      let iDevicesUpdateMapping = new Map();

      return wiMHomeAPIclient
        .getInstallationDevices(instSerial, returnHidden, returnSafety, includeTech)
        .then((installationData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (!returnHidden && !includeTech) {
            installationData.forEach((device) => {
              if (device.isHidden !== true) {
                device.key = device.id;
                instDevices.set(device.id, device);
                iDevicesUpdateMapping.set(device.id, [device.id]);
                if(Array.isArray(device.deviceStatusDep) && device.deviceStatusDep.length) {
                  device.deviceStatusDep.forEach((deviceDep) => {
                    if(!iDevicesUpdateMapping.has(deviceDep)) {
                      iDevicesUpdateMapping.set(deviceDep, [device.id])
                      // console.log("iDevicesUpdateMapping.get(" + deviceDep + "): ", iDevicesUpdateMapping.get(deviceDep));
                    } else {
                      let curDep = iDevicesUpdateMapping.get(deviceDep)
                      if(!curDep.includes(device.id)) {
                        curDep.push(device.id)
                      }
                      iDevicesUpdateMapping.set(deviceDep, curDep);
                      // console.log("iDevicesUpdateMapping.get(" + deviceDep + "): ", iDevicesUpdateMapping.get(deviceDep));
                    }
                  });
                }
              }
            });
            setDevicesState(instDevices);
            // console.log(iDevicesUpdateMapping)
            instDevicesUpdateMapping.current = iDevicesUpdateMapping;
          } else {
            installationData.forEach((device) => {
              device.key = device.id;
              instDevices.set(device.id, device);
            });
          }
          return instDevices;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 3, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const loadLastJournalEntry = useCallback(
    (installationSerial) => {
      const prom = wiMHomeAPIclient
        .getInstallationLastJournalEntry(installationSerial)
        .then((journalEntry) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
          return journalEntry;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 6, lastErrorMessage: exception.message });
        });
      return prom;
    },
    [handleLogout]
  );

  const loadInstPhyDevicesInfos = useCallback(
    async (installationSerial) => {
      wiMHomeAPIclient
        .getInstallationPhyDevices(installationSerial)
        .then((installationData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          let phyDevices = new Map();
          installationData.forEach((phyDevice) => {
            phyDevices.set(phyDevice.id, phyDevice);
          });
          setPhyDevicesInfo(phyDevices);
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 15, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const downloadInstReport = useCallback(
      async (installationSerial, reportId) => {
        return wiMHomeAPIclient
            .getInstallationReport(installationSerial, reportId)
            .then((result) => {
              if(result) {
                if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
                return true;
              } else
                return false;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }
              setLastError({ lastErrorCode: 15, lastErrorMessage: exception.message });
              return false;
            });
      },
      [handleLogout]
  );

  function countUnreadReports(countOnlyThese = null) {
    if(installationReports == null)
        return 0;
    let unread = 0;
    for(let repTypeIdx in installationReports) {
      if(countOnlyThese != null)
        if(installationReports[repTypeIdx].name !== countOnlyThese)
          continue;
      for(let rep of installationReports[repTypeIdx].reports) {
        if(!rep.shown)
          unread += 1;
      }
    }
    return unread;
  }

  async function directInstGuestLoad(installationSerial) {
    return wiMHomeAPIclient.getInstallationGuests(installationSerial);
  }

  async function loadProgrammedCalls(installations, force = false) {
    if(programmedCalls.loaded && force === false) {
      // console.log(programmedCalls.calls)
      return programmedCalls.calls;
    }
    // console.log(installations);
    let newEntries = []
    if(installations == null) {
      return null;
    }
    for (const installation of Object.values(installations)) {
      // console.log(installation)
      // console.log(installation.title)
      let cases = await loadInstallationCases(installation.id, true, true, true, 0, 1, true, CASE_DESCRIPTION_TYPE_PROG_CALL)
      if (cases != null && cases.length > 0) {
        if(cases[0]?.caseData?.daysBetweenCalls > 0) {
          cases[0].installationSerial = installation.id;
          cases[0].installationTitle = installation.title
          newEntries.push(cases[0]);
        }
      }
    }
    return newEntries;
  }

  function reloadProgrammedCalls() {
    // console.log(installations)
    loadProgrammedCalls(installations, true)
    .then((newEntries) => {
      // console.log(newEntries);
      if(newEntries != null) {
        setProgrammedCalls({"loaded": true, "calls": newEntries})
      }
    })
  }

  /*
   * Create memoized function to update selected installation serial, load related
   * information and subscribe (or unsubscribe) MQTT installation topics
   */
  const handleSelectInstallation = useCallback(
    async (installationSerial) => {
      setSelectedInst(installationSerial);
      const loadInstGuests = async (installationSerial) => {
        const prom = wiMHomeAPIclient
          .getInstallationGuests(installationSerial)
          .then((installationData) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

            let guests = new Map();
            installationData.forEach((guest) => {
              guests.set(guest.id, guest);
            });
            setInstallationGuests(guests);
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            }

            setLastError({ lastErrorCode: 4, lastErrorMessage: exception.message });
          });
        return prom;
      };

      const loadInstContacts = async (installationSerial) => {
        const prom = wiMHomeAPIclient
            .getInstallationContacts(installationSerial)
            .then((installationData) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              let contacts = new Map();
              installationData.forEach((contact) => {
                contacts.set(contact.userId, contact);
              });
              setInstallationContacts(contacts);
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 4, lastErrorMessage: exception.message });
            });
        return prom;
      };

      const loadInstServices = async (installationSerial) => {
        const prom = wiMHomeAPIclient
          .getInstallationServices(installationSerial)
          .then((installationData) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
            let services = new Map();
            installationData.forEach((service) => {
              services.set(service.id, service);
            });
            setInstallationServices(services);
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            }

            setLastError({ lastErrorCode: 5, lastErrorMessage: exception.message });
          });
        return prom;
      };

      const loadInstallationReports = async (installationSerial) => {
        return wiMHomeAPIclient
          .getInstallationReports(installationSerial)
          .then((reports) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
            setLastError(NO_ERROR_OBJECT);
            return reports;
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            }

            setLastError({ lastErrorCode: 35, lastErrorMessage: exception.message });
          });
      };

      if (installationSerial === null) {
        setInstallationGuests(null);
        setInstallationContacts(null);
        setInstallationServices(null);
        mqttSetInstallationSerial(null);
        setDevicesState(null);
        instDevicesUpdateMapping.current = null;
        setPhyDevicesInfo(null);
        setInstallationReports(null);
      } else {
        await loadInstGuests(installationSerial);
        await loadInstContacts(installationSerial);
        // let contacts = new Map();
        // installations[installationSerial].contactPerson.forEach((contact) => {
        //   contacts.set(contact.userId, contact);
        // });
        // setInstallationContacts(contacts);
        if (installations[installationSerial].userCapabilities.includes('services-edit')) {
          await loadInstServices(installationSerial);
        }

        await loadAllInstallationData(installationSerial, false, true, false);
        await loadInstPhyDevicesInfos(installationSerial);
        if (installations[installationSerial].userCapabilities.includes('logs')) {
          setInstallationReports(await loadInstallationReports(installationSerial))
        }
        mqttSetInstallationSerial(installationSerial);
      }
    },
    [handleLogout, installations, loadAllInstallationData, loadInstPhyDevicesInfos]
  );

  useEffect(() => {
    const tokenTimestampJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN_TIMESTAMP);
    if(tokenTimestampJSON != null) {
      tokenTimestamp = (new Date(JSON.parse(tokenTimestampJSON)));
    } else {
      tokenTimestamp = null;
    }
  }, []);

  /*
   * ON (RE)LOAD - Restore Local Storage information
   */
  useEffect(() => {
    const installationsJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.INSTALLATIONS);
    if (installationsJSON != null) {
      setInstallations(JSON.parse(installationsJSON));
    }

    const selectedInstJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_INST);
    if (selectedInstJSON != null) {
      setSelectedInst(JSON.parse(selectedInstJSON));
    }

    const guestsJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.GUESTS);
    if (guestsJSON != null) {
      const parsedGuests = JSON.parse(guestsJSON);
      if (parsedGuests.length === 0) {
        setInstallationGuests(new Map());
      } else {
        let guests = new Map();
        parsedGuests.forEach((guest) => {
          guests.set(guest.id, guest);
        });
        setInstallationGuests(guests);
      }
    }

    const contactsJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.CONTACTS);
    if (contactsJSON != null) {
      const parsedContacts = JSON.parse(contactsJSON);
      if (parsedContacts.length === 0) {
        setInstallationContacts(new Map());
      } else {
        let contacts = new Map();
        parsedContacts.forEach((contact) => {
          contacts.set(contact.userId, contact);
        });
        setInstallationContacts(contacts);
      }
    }

    const servicesJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.SERVICES);
    if (servicesJSON != null) {
      const parsedServices = JSON.parse(servicesJSON);
      if (parsedServices.length === 0) {
        setInstallationServices(new Map());
      } else {
        let services = new Map();
        parsedServices.forEach((service) => {
          services.set(service.id, service);
        });
        setInstallationServices(services);
      }
    }

    const reportsJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.REPORTS);
    if (reportsJSON != null) {
      const parsedReports = JSON.parse(reportsJSON);
      if (parsedReports.length === 0) {
        setInstallationReports([]);
      } else {
        setInstallationReports(parsedReports);
      }
    }

    const ackAlertsJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.ACK_ALERTS);
    if (ackAlertsJSON != null) {
      const parsedAckAlerts = JSON.parse(ackAlertsJSON);
      if (parsedAckAlerts.length === 0) {
        setAckAlerts(new Map());
      } else {
        let loadedAckAlerts = new Map();
        parsedAckAlerts.forEach((inst) => {
          loadedAckAlerts.set(inst.id, inst);
        });
        setAckAlerts(loadedAckAlerts);
      }
    }

    const alertSoundInfoJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.ALERTS_SOUND_INFO);
    if (alertSoundInfoJSON != null) {
      setAlertSoundInfo(JSON.parse(alertSoundInfoJSON));
    }

    const alertCodesJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.ALERT_CODES);
    if (alertCodesJSON != null) {
      setAlertCodes(JSON.parse(alertCodesJSON));
    }

    if (LOCAL_VERSION) {
      const jwtJSON = sessionStorage.getItem(LOCAL_STORAGE_KEYS.JWT);
      if (jwtJSON != null) {
        setJwtToken(JSON.parse(jwtJSON));
      }
    }
  }, []);

  useEffect(() => {
    if (LOCAL_VERSION && jwtToken !== null && wiMHomeAPIclient.getJWT() === null) {
      wiMHomeAPIclient.setJWT(jwtToken, null, currentUser.language, currentUser.sessionDuration);
    }
  }, [currentUser, jwtToken]);

  /*
   * ON currentUser, jwtToken update - Set JWT token (if needed) OR load
   * installation list and information
   */
  useEffect(() => {
    if (!LOCAL_VERSION) {
      wiMHomeAPIclient.setUseCookie(true);
      wiMHomeAPIclient.setLanguage(currentUser.language);
      document.documentElement.setAttribute('lang', currentUser.language);
    }

    // console.log('currentUser.stayConnected', currentUser.stayConnected)
    wiMHomeAPIclient.setTimeoutCallbackMargin(60);
    wiMHomeAPIclient.setTokenUpdateCallback(saveTokenUpdateTimestamp);
    wiMHomeAPIclient.setTimeoutCallback(handleKeepAlive, currentUser.sessionDuration, tokenTimestamp);

    if (currentUser.username != null) {
      wiMHomeAPIclient
        .getInstallationList()
        .then((instListResponse) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (instListResponse) {
            if (instListResponse.length === 0) {
              confirmAlert({
                title: `${t('Error')}`,
                message: `${t('login.noInstallation')}`,
                buttons: [
                  {
                    label: `${t('OK')}`,
                    onClick: () => {
                      handleLogout();
                    },
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
              });
            }
            setInstallations(() => {
              const newInstallations = {};
              instListResponse.forEach((instInfo) => {
                newInstallations[instInfo.id] = cloneDeep(instInfo);
              });
              // console.log(newInstallations)
              return newInstallations;
            });
          }
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 7, lastErrorMessage: exception.message });
        });

      setLastError(NO_ERROR_OBJECT);
    }
  }, [currentUser, handleLogout, t]);

  const requestDeviceDetails = useCallback(
    async (installationSerial, deviceId, markNewEventsVisualized) => {
      return wiMHomeAPIclient
        .getInstallationDeviceDetails(installationSerial, deviceId, markNewEventsVisualized)
        .then((deviceDetails) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceDetails;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestDeviceEvents = useCallback(
    async (installationSerial, deviceId, startDateTime, endDateTime, markNewEventsVisualized) => {
      return wiMHomeAPIclient
        .getInstallationDeviceEvents(installationSerial, deviceId, startDateTime, endDateTime, markNewEventsVisualized)
        .then((deviceEvents) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceEvents;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestDeviceData = useCallback(
    async (installationSerial, deviceId, startDateTime, endDateTime) => {
      return wiMHomeAPIclient
        .getInstallationDeviceData(installationSerial, deviceId, startDateTime, endDateTime)
        .then((deviceData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceData;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestInstallationData = useCallback(
    async (
      instSerial,
      deviceList,
      returnEvents,
      showTechData,
      latestFirst,
      startDateTime,
      endDateTime,
      offset,
      limit,
      decodeData
    ) => {
      return wiMHomeAPIclient
        .getInstallationData(
          instSerial,
          deviceList,
          returnEvents,
          showTechData,
          latestFirst,
          startDateTime,
          endDateTime,
          offset,
          limit,
          decodeData
        )
        .then((deviceData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceData;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestInstallationDataNumber = useCallback(
    async (instSerial, deviceList, returnEvents, showTechData, startDateTime, endDateTime) => {
      return wiMHomeAPIclient
        .getInstallationDataNumber(instSerial, deviceList, returnEvents, showTechData, startDateTime, endDateTime)
        .then((deviceData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceData;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestInstallationEvents = useCallback(
    async (instSerial, deviceList, latestFirst, startDateTime, endDateTime, offset, limit) => {
      return wiMHomeAPIclient
        .getInstallationEvents(instSerial, deviceList, latestFirst, startDateTime, endDateTime, offset, limit)
        .then((deviceEvents) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return deviceEvents;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestInstallationEventsNumber = useCallback(
    async (instSerial, deviceList, startDateTime, endDateTime) => {
      return wiMHomeAPIclient
        .getInstallationEventsNumber(instSerial, deviceList, startDateTime, endDateTime)
        .then((eventsCount) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return eventsCount;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                              INSTALLATION GUESTS                                      *
   *************************************************************************************** */
  const deleteInstGuest = useCallback(
    async (instSerial, id) => {
      return wiMHomeAPIclient
        .deleteInstallationGuest(instSerial, id)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 9, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const updateInstGuest = useCallback(
    async (instSerial, id, guest) => {
      return wiMHomeAPIclient
        .patchInstallationGuest(instSerial, id, guest)
        .then((guest) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return guest;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }
          setLastError({ lastErrorCode: 41, lastErrorMessage: exception.message });
          return { lastErrorCode: 41, lastErrorMessage: exception.message }
        });
    },
    [handleLogout]
  );

  const createInstGuest = useCallback(
    async (instSerial, guest) => {
      return wiMHomeAPIclient
        .postInstallationGuest(instSerial, guest)
        .then((createdGuest) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return createdGuest;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }
          setLastError({ lastErrorCode: 11, lastErrorMessage: exception.message });
          return { lastErrorCode: 11, lastErrorMessage: exception.message }
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                              INSTALLATION CONTACTS                                    *
   *************************************************************************************** */
  const deleteInstContact = useCallback(
      async (instSerial, id) => {
        return wiMHomeAPIclient
            .deleteInstallationContact(instSerial, id)
            .then((res) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return true;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 9, lastErrorMessage: exception.message });
            });
      },
      [handleLogout]
  );

  const updateInstContact = useCallback(
      async (instSerial, id, contact) => {
        return wiMHomeAPIclient
            .patchInstallationContact(instSerial, id, contact)
            .then((guest) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return guest;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }
              setLastError({ lastErrorCode: 41, lastErrorMessage: exception.message });
              return { lastErrorCode: 41, lastErrorMessage: exception.message }
            });
      },
      [handleLogout]
  );

  const createInstContact = useCallback(
      async (instSerial, guest) => {
        return wiMHomeAPIclient
            .postInstallationContact(instSerial, guest)
            .then((createdGuest) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return createdGuest;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }
              setLastError({ lastErrorCode: 11, lastErrorMessage: exception.message });
              return { lastErrorCode: 11, lastErrorMessage: exception.message }
            });
      },
      [handleLogout]
  );

  /* ***************************************************************************************
   *                              INSTALLATION SERIVCES                                    *
   *************************************************************************************** */
  const deleteInstService = useCallback(
    async (instSerial, id) => {
      return wiMHomeAPIclient
        .deleteInstallationService(instSerial, id)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 9, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const updateInstService = useCallback(
    async (instSerial, id, service) => {
      return wiMHomeAPIclient
        .patchInstallationService(instSerial, id, service)
        .then((extServiceProv) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return extServiceProv;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 41, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const createInstService = useCallback(
    async (instSerial, service) => {
      return wiMHomeAPIclient
        .postInstallationService(instSerial, service)
        .then((createdService) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return createdService;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 11, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestServiceTemplates = useCallback(async () => {
    return wiMHomeAPIclient
      .getInstallationServiceTemplates()
      .then((extServiceProv) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        setLastError(NO_ERROR_OBJECT);
        return extServiceProv;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 4, lastErrorMessage: exception.message });
      });
  }, [handleLogout]);

  const requestExternalServiceProviders = useCallback(async () => {
    return wiMHomeAPIclient
      .getExternalServiceProviders()
      .then((extServiceProv) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        setLastError(NO_ERROR_OBJECT);
        return extServiceProv;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 12, lastErrorMessage: exception.message });
      });
  }, [handleLogout]);

  /* ***************************************************************************************
   *                              INSTALLATION JOURNAL                                     *
   *************************************************************************************** */
  const loadInstJournal = async (installationSerial, latestFirst, offset, limit, startDateTime, endDateTime, onlyProgCalls) => {
    return wiMHomeAPIclient
        .getInstallationJournal(installationSerial, latestFirst, offset, limit, startDateTime, endDateTime, onlyProgCalls)
        .then((journalData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
          let journal = new Map();
          journalData.forEach((entry) => {
            journal.set(entry.id, entry);
          });
          return journal;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({lastErrorCode: 13, lastErrorMessage: exception.message});
        });
  };

  const loadInstJournalCount = (installationSerial, startDateTime, endDateTime, onlyProgCalls) => {
    return wiMHomeAPIclient
      .getInstallationJournalCount(installationSerial, startDateTime, endDateTime, onlyProgCalls)
      .then((count) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
        return count;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 13, lastErrorMessage: exception.message });
      });
  };

  const createInstJournalEntry = useCallback(
    async (instSerial, entry) => {
      return wiMHomeAPIclient
        .postInstallationJouralEntry(instSerial, entry)
        .then((updatedJournal) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return updatedJournal;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 4, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const loadInstallationCases = useCallback(
    async (instSerial, includeClosed, latestFirst, orderByLastUpdate, offset, limit, includeJournal, caseType) => {
      return wiMHomeAPIclient
        .getInstallationCases(instSerial, includeClosed, latestFirst, orderByLastUpdate, offset, limit, includeJournal, caseType)
        .then((cases) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return cases;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 70, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const loadInstallationCasesNumber = useCallback(
    async (instSerial, includeClosed, includeJournal, caseType) => {
      return wiMHomeAPIclient
        .getInstallationCasesNumber(instSerial, includeClosed, includeJournal, caseType)
        .then((cases) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return cases;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 70, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const addInstallationNewCase = useCallback(
    async (instSerial, newCase) => {
      return wiMHomeAPIclient
        .postInstallationNewCase(instSerial, newCase)
        .then((caseAdded) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return caseAdded;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 71, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const addInstallationCaseEntry = useCallback(
    async (instSerial, caseId, newEntry) => {
      return wiMHomeAPIclient
        .postInstallationCaseEntry(instSerial, caseId, newEntry)
        .then((updatedCase) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return updatedCase;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 72, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const changeDeviceValue = useCallback(
    async (installationSerial, deviceId, newValue, skipAlertCaseUpdate) => {
      wiMHomeAPIclient
        .patchInstallationDeviceState(installationSerial, deviceId, newValue, skipAlertCaseUpdate)
        .then((deviceDetails) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 14, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestPhyDeviceDetails = useCallback(
    async (installationSerial, phyDevice) => {
      return wiMHomeAPIclient
        .getInstallationPhyDeviceDetail(installationSerial, phyDevice)
        .then((phyDeviceDetails) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return phyDeviceDetails;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 16, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                              INSTALLATION REMINDER                                    *
   *************************************************************************************** */
  const loadInstReminders = useCallback(
    async (installationSerial, returnExpired, returnFuture, starDateTime, endDateTime) => {
      return wiMHomeAPIclient
        .getInstallationReminders(installationSerial, returnExpired, returnFuture, starDateTime, endDateTime)
        .then((remindersData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          let reminders = new Map();
          remindersData.forEach((reminder) => {
            reminders.set(reminder.id, reminder);
          });
          return reminders;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 17, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestReminderDetails = useCallback(
    async (installationSerial, reminder) => {
      return wiMHomeAPIclient
        .getInstallationReimnder(installationSerial, reminder)
        .then((reminderDetails) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return reminderDetails;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 18, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const createReminder = useCallback(
    async (instSerial, reminder) => {
      return wiMHomeAPIclient
        .postInstallationReminderAdd(instSerial, reminder)
        .then((createdReminder) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (not_null_or_undefined(createdReminder.userMessage)) {
            return createdReminder.userMessage;
          }
          return createdReminder;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 19, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const updateReminder = useCallback(
    async (instSerial, id, reminder) => {
      return wiMHomeAPIclient
        .patchInstallationReminder(instSerial, id, reminder)
        .then((reminder) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (not_null_or_undefined(reminder.userMessage)) {
            return reminder.userMessage;
          }
          return reminder;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 20, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const deleteReminder = useCallback(
    async (instSerial, id) => {
      return wiMHomeAPIclient
        .deleteInstallationReminder(instSerial, id)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (res !== true) {
            return res.userMessage;
          }
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 21, lastErrorMessage: exception.message });
          return exception.message;
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                              INSTALLATION USER DISPLAY                                *
   *************************************************************************************** */
  const loadUserDisplay = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationUserDisplay(installationSerial)
        .then((displayOptions) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(displayOptions.userMessage)) {
            return displayOptions.userMessage;
          }
          return displayOptions;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 22, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const updateUserDisplay = useCallback(
    async (instSerial, options) => {
      return wiMHomeAPIclient
        .patchInstallationUserDisplay(instSerial, options)
        .then((instUserDisplay) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(instUserDisplay.userMessage)) {
            return instUserDisplay.userMessage;
          }
          setLastError(NO_ERROR_OBJECT);
          return instUserDisplay;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 41, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                                    USER OPTIONS                                       *
   *************************************************************************************** */
  const loadUserOptions = useCallback(
    async (userId = currentUser.userId) => {
      let readOptions = null;
      return wiMHomeAPIclient
        .getUserOptions(userId)
        .then((options) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(options.userMessage)) {
            return options.userMessage;
          }
          readOptions = options;
          setAlertSoundInfo({
            sound: options.alertSoundUrl,
            interval: options.alertSoundInterval,
            playForAlarms: options.alertSoundOnAlarm ?? 1,
            playForWarnings: options.alertSoundOnWarning ?? 0,
            playForDisconnections: options.alertSoundOnDisconnection ?? 0 });
          setTheme({ theme: options.theme });
          if (enableWebPush > 0 && options.enableWebPush === 0) {
            return requestPushPermission().then((allowed) => {
              if (!allowed) {
                alert(`${t('applicationErrors.pushNotAllowed')}`);
                return false;
              }
              return deletePushToken();
            });
          } else if (enableWebPush === 0 && options.enableWebPush > 0) {
            return requestPushPermission().then((allowed) => {
              if (!allowed) {
                alert(`${t('applicationErrors.pushNotAllowed')}`);
                return false;
              }
              return getPushToken();
            });
          } else {
            return true;
          }
        })
        .then((res) => {
          if(res === false)
            return false;

          if (enableWebPush > 0 && readOptions.enableWebPush === 0) {
            return wiMHomeAPIclient.postPushToken('');
          } else if (enableWebPush === 0 && readOptions.enableWebPush > 0 && res.result === 0) {
            return wiMHomeAPIclient.postPushToken(res.token);
          } else {
            return true;
          }
        })
        .then((res) => {
          // if(res === false)
          //   return readOptions;
          //
          setEnableWebPush(readOptions.enableWebPush);
          return readOptions;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 22, lastErrorMessage: exception.message });
          return readOptions;
        });
    },
    [currentUser.userId, enableWebPush, handleLogout]
  );

  // const updateUserOptions = useCallback(async (options) => {
  //   return wiMHomeAPIclient.patchUserOptions(currentUser.userId, options)
  //   .then(optionsRead => {
  //     if(LOCAL_VERSION)
  //       setJwtToken(wiMHomeAPIclient.getJWT());

  //     if(not_null_or_undefined(optionsRead.userMessage)) {
  //       return optionsRead.userMessage;
  //     }
  //     setLastError(NO_ERROR_OBJECT);
  //     setAlertSoundInfo({sound: optionsRead.alertSoundUrl, interval: optionsRead.alertSoundInterval});
  //     return optionsRead;
  //   })
  //   .catch((exception) => {
  //     if(exception instanceof UnauthorizedError) {
  //       handleLogout();
  //     }

  //     setLastError({lastErrorCode: 41, lastErrorMessage: exception.message});
  //   });
  // }, [handleLogout, currentUser.userId]);

  const updateUserOptions = useCallback(
    async (options) => {
      let readOptions = null;
      return wiMHomeAPIclient
        .patchUserOptions(currentUser.userId, options)
        .then((options) => {
//          console.log(options)
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(options.userMessage)) {
            return options.userMessage;
          }
          readOptions = options;
          setAlertSoundInfo({
            sound: options.alertSoundUrl,
            interval: options.alertSoundInterval,
            playForAlarms: options.alertSoundOnAlarm ?? 1,
            playForWarnings: options.alertSoundOnWarning ?? 0,
            playForDisconnections: options.alertSoundOnDisconnection ?? 0
          });
          setTheme({ theme: options.theme });
          if (enableWebPush > 0 && options.enableWebPush === 0) {
            return requestPushPermission().then((allowed) => {
              if (!allowed) {
                return false;
              }
              return deletePushToken();
            });
          } else if (enableWebPush === 0 && options.enableWebPush > 0) {
            return requestPushPermission().then((allowed) => {
              if (!allowed) {
                alert(`${t('applicationErrors.pushNotAllowed')}`);
                return false;
              }
              return getPushToken();
            });
          } else {
            return true;
          }
        })
        .then((res) => {
          // console.log(res)
          if(res === false)
            return false;

          if (enableWebPush > 0 && readOptions.enableWebPush === 0) {
            return true;
          } else if (enableWebPush === 0 && readOptions.enableWebPush > 0 && res.result === 0) {
            return wiMHomeAPIclient.postPushToken(res.token);
          } else {
            return true;
          }
        })
        .then((res) => {
          // if(res === false)
          //   return readOptions;
          //
          setEnableWebPush(readOptions.enableWebPush);
          return readOptions;
        })
        .catch((exception) => {
          console.log(exception)
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 41, lastErrorMessage: exception.message });
        });
    },
    [currentUser.userId, enableWebPush, handleLogout]
  );

  /* ***************************************************************************************
   *                                    USER INFO                                       *
   *************************************************************************************** */
  const loadUserInfo = useCallback(async () => {
    return wiMHomeAPIclient
      .getUserInfo(currentUser.userId)
      .then((userInfo) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        if (not_null_or_undefined(userInfo.userMessage)) {
          return userInfo.userMessage;
        }
        return userInfo;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 42, lastErrorMessage: exception.message });
      });
  }, [handleLogout, currentUser.userId]);

  const updateUserInfo = useCallback(
    async (info) => {
      return wiMHomeAPIclient
        .patchUserInfo(currentUser.userId, info)
        .then((userInfo) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(userInfo.errorCode)) {
            return userInfo.userMessage;
          }
          setLastError(NO_ERROR_OBJECT);
          return userInfo;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 43, lastErrorMessage: exception.message });
        });
    },
    [handleLogout, currentUser.userId]
  );

  const updateUserPassword = useCallback(
    async (oldPasword, newPassword) => {
      return wiMHomeAPIclient
        .patchUserPassword(currentUser.userId, currentUser.username, oldPasword, newPassword)
        .then((userInfo) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          if (not_null_or_undefined(userInfo.errorCode)) {
            return userInfo.userMessage;
          }
          setLastError(NO_ERROR_OBJECT);
          return userInfo;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 44, lastErrorMessage: exception.message });
        });
    },
    [handleLogout, currentUser.userId, currentUser.username]
  );

  const requestUserSounds = useCallback(async () => {
    return wiMHomeAPIclient
      .getUserSounds(currentUser.userId)
      .then((sounds) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        setLastError(NO_ERROR_OBJECT);
        return sounds;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 45, lastErrorMessage: exception.message });
      });
  }, [handleLogout, currentUser.userId]);

  /* ***************************************************************************************
   *                                    AUX FUNCTIONS                                      *
   *************************************************************************************** */

  const requestCountries = useCallback(async () => {
    return wiMHomeAPIclient
      .getAuxCountries()
      .then((countries) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        let countriesMap = new Map();
        for (const [key, value] of Object.entries(countries)) {
          countriesMap.set(key, value);
        }

        setLastError(NO_ERROR_OBJECT);
        return countriesMap;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 46, lastErrorMessage: exception.message });
      });
  }, [handleLogout]);

  const requestTimezones = useCallback(async () => {
    return wiMHomeAPIclient
      .getAuxTimezones()
      .then((timezones) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        let timezonesMap = new Map();
        for (const [key, value] of Object.entries(timezones)) {
          timezonesMap.set(key, value);
        }

        setLastError(NO_ERROR_OBJECT);
        return timezonesMap;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 47, lastErrorMessage: exception.message });
      });
  }, [handleLogout]);

  const requestLocales = useCallback(
    async (onlySupported) => {
      return wiMHomeAPIclient
        .getAuxLocales(onlySupported)
        .then((locales) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          let localesMap = new Map();
          for (const [key, value] of Object.entries(locales)) {
            localesMap.set(key, value);
          }
          setLastError(NO_ERROR_OBJECT);
          return localesMap;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 48, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestAlertCodes = useCallback(
      async () => {
        return wiMHomeAPIclient
            .getAuxAlertCodes()
            .then((alertCodes) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              let alertCodesMap = new Map();
              for (const [key, value] of Object.entries(alertCodes)) {
                alertCodesMap.set(value.code, value);
              }
              setLastError(NO_ERROR_OBJECT);
              setAlertCodes(alertCodesMap);
              return alertCodesMap;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 52, lastErrorMessage: exception.message });
            });
      },
      [handleLogout]
  );

  const requestFiscalIdentifierTypes = useCallback(async () => {
    return wiMHomeAPIclient
      .getAuxFiscalIdentifierTypes()
      .then((fiscalIdentifierTypes) => {
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
        setLastError(NO_ERROR_OBJECT);
        return fiscalIdentifierTypes;
      })
      .catch((exception) => {
        if (exception instanceof UnauthorizedError) {
          handleLogout();
        }

        setLastError({ lastErrorCode: 49, lastErrorMessage: exception.message });
      });
  }, [handleLogout]);

  const requestContactRoles = useCallback(async () => {
    return wiMHomeAPIclient
        .getContactRoles()
        .then((contactRoles) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
          setLastError(NO_ERROR_OBJECT);
          return contactRoles;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 51, lastErrorMessage: exception.message });
        });
  }, [handleLogout]);

  /* ***************************************************************************************
   *                          INSTALLATION REMINDER ACTION                                 *
   *************************************************************************************** */
  const loadInstReminderActions = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationReimnderActions(installationSerial)
        .then((actionsData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          let actions = new Map();
          actionsData.forEach((action) => {
            actions.set(action.id, action);
          });
          return actions;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 23, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestReminderActionDetails = useCallback(
    async (installationSerial, reminderActionId) => {
      return wiMHomeAPIclient
        .getInstallationReimnderAction(installationSerial, reminderActionId)
        .then((reminderDetails) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return reminderDetails;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 24, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const createReminderAction = useCallback(
    async (instSerial, reminderAction) => {
      return wiMHomeAPIclient
        .postInstallationReminderActionAdd(instSerial, reminderAction)
        .then((createdReminderAction) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return createdReminderAction;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 25, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const updateReminderAction = useCallback(
    async (instSerial, id, reminderAction) => {
      return wiMHomeAPIclient
        .patchInstallationReminderAction(instSerial, id, reminderAction)
        .then((reminderAction) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return reminderAction;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 26, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const deleteReminderAction = useCallback(
    async (instSerial, id) => {
      return wiMHomeAPIclient
        .deleteInstallationReminderAction(instSerial, id)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (res !== true) {
            return res.userMessage;
          }
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 27, lastErrorMessage: exception.message });
          return exception.message;
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                           INSTALLATION REMINDER ICON                                  *
   *************************************************************************************** */
  const requestReminderIcons = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationReimnderIcons(installationSerial)
        .then((icons) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return icons;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 28, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                           INSTALLATION REMINDER IMAGE                                 *
   *************************************************************************************** */
  const requestReminderImages = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationReimnderImages(installationSerial)
        .then((images) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return images;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 29, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const postReminderImage = useCallback(
    async (installationSerial, fileObject) => {
      return wiMHomeAPIclient
        .postInstallationReminderImage(installationSerial, fileObject)
        .then((soundFile) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return soundFile;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 30, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const deleteReminderImage = useCallback(
    async (installationSerial, filename) => {
      return wiMHomeAPIclient
        .deleteInstallationReminderImage(installationSerial, filename)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (res !== true) {
            return res.userMessage;
          }
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 31, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                           INSTALLATION REMINDER SOUND                                 *
   *************************************************************************************** */
  const requestReminderSounds = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationReminderSounds(installationSerial)
        .then((sounds) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return sounds;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 32, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const postReminderSound = useCallback(
    async (installationSerial, fileObject) => {
      return wiMHomeAPIclient
        .postInstallationReminderSound(installationSerial, fileObject)
        .then((soundFile) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return soundFile;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 33, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const deleteReminderSound = useCallback(
    async (installationSerial, filename) => {
      return wiMHomeAPIclient
        .deleteInstallationReminderSound(installationSerial, filename)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (res !== true) {
            return res.userMessage;
          }
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 34, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
  *                           INSTALLATION REMINDER RECIPIENTS                             *
  *************************************************************************************** */
  const requestReminderRecipients = useCallback(
      async (installationSerial, requestResultRecipients) => {
        if(requestResultRecipients) {
          return wiMHomeAPIclient
              .getInstallationReminderResultRecipients(installationSerial)
              .then((recipients) => {
                if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

                setLastError(NO_ERROR_OBJECT);
                return recipients;
              })
              .catch((exception) => {
                if (exception instanceof UnauthorizedError) {
                  handleLogout();
                }

                setLastError({ lastErrorCode: 29, lastErrorMessage: exception.message });
              });
        } else {
          return wiMHomeAPIclient
              .getInstallationReminderRecipients(installationSerial)
              .then((recipients) => {
                if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

                setLastError(NO_ERROR_OBJECT);
                return recipients;
              })
              .catch((exception) => {
                if (exception instanceof UnauthorizedError) {
                  handleLogout();
                }

                setLastError({ lastErrorCode: 29, lastErrorMessage: exception.message });
              });
        }
      },
      [handleLogout]
  );

  const requestInstallationLinks = useCallback(
      async (installationSerial) => {
        return wiMHomeAPIclient
            .getInstallationLinks(installationSerial)
            .then((links) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return links;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 52, lastErrorMessage: exception.message });
            });
      },
      [handleLogout]
  );

  const updateInstallationLinks = useCallback(
      async (instSerial, links) => {
        return wiMHomeAPIclient
            .patchInstallationLinks(instSerial, links)
            .then((readLinks) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              setInstallations( (cur) => {
                cur[instSerial].links = readLinks;
                return cloneDeep(cur);
              });
              return readLinks;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 53, lastErrorMessage: exception.message });
            });
      },
      [handleLogout]
  );

  const requestInstallationInfoDocs = useCallback(
    async (installationSerial) => {
      return wiMHomeAPIclient
        .getInstallationInfosDocs(installationSerial)
        .then((images) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return images;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 35, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const postInstallationInfoDoc = useCallback(
    async (installationSerial, fileObject, linkName, description) => {
      return wiMHomeAPIclient
        .postInstallationInfoDoc(installationSerial, fileObject, linkName, description)
        .then((soundFile) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          return soundFile;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 36, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const deleteInstallationInfoDoc = useCallback(
    async (installationSerial, filename) => {
      return wiMHomeAPIclient
        .deleteInstallationInfoDoc(installationSerial, filename)
        .then((res) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          setLastError(NO_ERROR_OBJECT);
          if (res !== true) {
            return res.userMessage;
          }
          return true;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 37, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  const requestWithingsAuthorization = useCallback(
      async (userId = currentUser.userId) => {
        return wiMHomeAPIclient
            .postWithingsAuthorizationRequest(userId)
            .then((redirectUrl) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return redirectUrl;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
              return null;
            });
      },
      [handleLogout, currentUser.userId]
  );

  const requestWithingsUnlink = useCallback(
      async (userId = currentUser.userId) => {
        return wiMHomeAPIclient
            .deleteWithingsAuthorization(userId)
            .then((result) => {
              if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

              setLastError(NO_ERROR_OBJECT);
              return result;
            })
            .catch((exception) => {
              if (exception instanceof UnauthorizedError) {
                handleLogout();
              }

              setLastError({ lastErrorCode: 8, lastErrorMessage: exception.message });
              return null;
            });
      },
      [handleLogout, currentUser.userId]
  );

  function acknowledgeAlerts(serial, alertsToMark) {
    setAckAlerts( (cur) => {
      let newAcks = new Map(cur);
      if(newAcks.has(serial)) {
        newAcks.get(serial).alerts.concat(alertsToMark);
      } else {
        newAcks.set(serial, {id: serial, alerts: alertsToMark});
      }
      return newAcks;
    });
  }

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
    document.documentElement.setAttribute('lang', lang);
    wiMHomeAPIclient.setLanguage(lang);
    dispatchCurrentUser({
      type: CURRENT_USER_ACTIONS.SET_LANGUAGE,
      info: { language: lang },
    });
    handleSelectInstallation(selectedInst);
  }

  const requestDeviceUpdate = useCallback(
    async (instSerial, deviceId) => {
      return wiMHomeAPIclient
        .getInstallationDevice(instSerial, deviceId)
        .then((deviceData) => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

          return deviceData;
        })
        .catch((exception) => {
          if (exception instanceof UnauthorizedError) {
            handleLogout();
          }

          setLastError({ lastErrorCode: 50, lastErrorMessage: exception.message });
        });
    },
    [handleLogout]
  );

  /* ***************************************************************************************
   *                                   USE EFFETCTS                                        *
   *************************************************************************************** */

  /*
   * ON installations update - Store Installations to local storage
   */
  useEffect(() => {
    // console.log("Saving installations", installations, JSON.stringify(installations));
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.INSTALLATIONS, JSON.stringify(installations));
    if(JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.INSTALLATIONS)) != null && installations == null) {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN_TIMESTAMP, null);
    }
  }, [installations]);

  /*
   * ON selectedInst update - Store selectedInst to local storage
   */
  useEffect(() => {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_INST, JSON.stringify(selectedInst));
  }, [selectedInst]);

  /*
   * ON installationGuests update - Store installationGuests to local storage
   */
  useEffect(() => {
    // console.log("Saving installationGuests", installationGuests, JSON.stringify(installationGuests));
    if (installationGuests instanceof Map)
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.GUESTS, JSON.stringify([...installationGuests.values()]));
    else sessionStorage.setItem(LOCAL_STORAGE_KEYS.GUESTS, JSON.stringify([]));
  }, [installationGuests]);

  /*
   * ON installationContacts update - Store installationContacts to local storage
   */
  useEffect(() => {
    // console.log("Saving installationContacts", installationContacts, JSON.stringify(installationContacts));
    if (installationContacts instanceof Map)
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.CONTACTS, JSON.stringify([...installationContacts.values()]));
    else sessionStorage.setItem(LOCAL_STORAGE_KEYS.CONTACTS, JSON.stringify([]));
  }, [installationContacts]);

  /*
   * ON installationServices update - Store installationServices to local storage
   */
  useEffect(() => {
    if (installationServices instanceof Map) {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.SERVICES, JSON.stringify([...installationServices.values()]));
    } else {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.SERVICES, JSON.stringify([]));
    }
  }, [installationServices]);

  /*
   * ON installationReports update - Store installationReports to local storage
   */
  useEffect(() => {
    if (installationReports instanceof Array) {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.REPORTS, JSON.stringify(installationReports));
    } else {
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.REPORTS, JSON.stringify([]));
    }
  }, [installationReports]);

  /*
   * ON ackAlerts update - Store ackAlerts to local storage
   */
  useEffect(() => {
    if (ackAlerts instanceof Map)
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.ACK_ALERTS, JSON.stringify([...ackAlerts.values()]));
    else sessionStorage.setItem(LOCAL_STORAGE_KEYS.ACK_ALERTS, JSON.stringify([]));
  }, [ackAlerts]);

  /*
   * ON jwtToken update - Store jwtToken to local storage
   */
  useEffect(() => {
    // console.log("Storing JWT")
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.JWT, JSON.stringify(jwtToken));
  }, [jwtToken]);

  /*
   * ON alertSoundInfo update - Store alertSoundInfo to local storage
   */
  useEffect(() => {
    // console.log("Storing alertSoundInfo")
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.ALERTS_SOUND_INFO, JSON.stringify(alertSoundInfo));
  }, [alertSoundInfo]);

  /*
   * ON alertCodes update - Store alertCodes to local storage
   */
  useEffect(() => {
    // console.log("Storing alertCodes")
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.ALERT_CODES, JSON.stringify(alertCodes));
  }, [alertCodes]);

  // /*
  //  * ON userOptions update - Store alertSoundInfo to local storage
  //  */
  // useEffect(() => {
  //   // console.log("Storing JWT")
  //   sessionStorage.setItem(LOCAL_STORAGE_KEYS.ALERTS_SOUND_INFO, JSON.stringify(alertSoundInfo));
  // }, [alertSoundInfo]);

  /*
   * ON first login - retrieve userOptions to set alertSounds & co.
   */
  // useEffect(() => {
  //   if((not_null_or_undefined(currentUser.userId) && (currentUser.userId !== 0)) && (alertSoundInfo === null)) {
  //     loadUserOptions(currentUser.userId);
  //   }
  // }, [alertSoundInfo, currentUser.userId, loadUserOptions]);

  useEffect(() => {
    if(currentUser.type !== "remote_assistance")
      return;

    // console.log(installations)
    if(installations == null) {
      setProgrammedCalls({"loaded": false, "calls": []})
      return
    }

    loadProgrammedCalls(installations)
    .then((newEntries) => {
      // console.log(newEntries);
      if(newEntries != null) {
        setProgrammedCalls({"loaded": true, "calls": newEntries})
      }
    })
  }, [installations, currentUser]);

  /*
   * ON isFirstMqttConnection, jwtToken, mqttStatus, installations update - manage MQTT connection / reconnection
   */
  useEffect(() => {
    async function connectMQTT() {
      function execAlertsUpdate(instSerial, deviceId) {
        wiMHomeAPIclient
          .getInstallationList()
          .then((instListResponse) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

            // console.log(instListResponse);
            if (instListResponse.length === 0) {
              confirmAlert({
                title: `${t('Error')}`,
                message: `${t('login.noInstallation')}`,
                buttons: [
                  {
                    label: `${t('OK')}`,
                    onClick: () => {
                      handleLogout();
                    },
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
              });
            }

            if (instListResponse) {
              setInstallations(() => {
                const newInstallations = {};
                instListResponse.forEach((instInfo) => {
                  newInstallations[instInfo.id] = cloneDeep(instInfo);
                });
                return newInstallations;
              });
            }
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            }

            setLastError({ lastErrorCode: 38, lastErrorMessage: exception.message });
          });

        setLastError(NO_ERROR_OBJECT);
      }

      function execDeviceUpdate(instSerial, updatedDeviceId) {
        let deviceIds = [updatedDeviceId];
        // console.log(instDevicesUpdateMapping.current)
        if(updatedDeviceId !== INSTALLATION_STATUS_OBJID_VAR && instDevicesUpdateMapping.current instanceof Map) {
          if(!instDevicesUpdateMapping.current.has(updatedDeviceId)) {
            // console.log("ignored update for " + instSerial + ":" + updatedDeviceId);
            return;
          }

          deviceIds = instDevicesUpdateMapping.current.get(updatedDeviceId);
          // console.log("update for " + instSerial + ":" + updatedDeviceId + " request status for device: " + deviceIds);
        }
        for(const deviceId of deviceIds) {
          // console.log("About to request update for deviceId: " + deviceId);
          wiMHomeAPIclient
              .getInstallationDevice(instSerial, deviceId)
              .then((deviceData) => {
                if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

                setDevicesState((devicesState) => {
                  let newDeviceData = cloneDeep(devicesState);
                  // if(newDeviceData.get(instSerial).has(deviceId))
                  //   newDeviceData.get(instSerial).set(deviceId, deviceData);
                  if (newDeviceData instanceof Map && newDeviceData.has(deviceId)) newDeviceData.set(deviceId, deviceData);

                  return newDeviceData;
                });

                if(deviceId === INSTALLATION_STATUS_OBJID_VAR) {
                  setInstallations((installations) => {
                    installations[instSerial].status = parseInt(deviceData.value);
                    // console.log(installations);
                    return cloneDeep(installations);
                  });
                }
              })
              .catch((exception) => {
                if (exception instanceof UnauthorizedError) {
                  handleLogout();
                }

                setLastError({ lastErrorCode: 1, lastErrorMessage: exception.message });
              });
        }
      }

      function setMqttConnectionStatus(isConnected) {
        setMqttStatus((mqttStatus) => {
          const newMqttStatus = { ...mqttStatus };
          newMqttStatus.mqttConnected = isConnected ? MQTT_CONN_STATE.CONNECTED : MQTT_CONN_STATE.DISCONNECTED;
          return newMqttStatus;
        });
      }

      if (REACT_APP_NEW_MQTT_AUTH) {
        wiMHomeAPIclient
          .getMqttToken(true)
          .then((mqttToken) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

            // console.log(mqttToken);
            // console.log('Token available, connecting to MQTT server: ' + process.env.REACT_APP_MQTT_SERVER);
            const result = connectMqtt(
              process.env.REACT_APP_MQTT_SERVER,
              process.env.REACT_APP_MQTT_PORT,
              process.env.REACT_APP_MQTT_TLS !== 'no',
              mqttToken.username,
              mqttToken.jwt,
              setMqttConnectionStatus,
              execDeviceUpdate,
              execAlertsUpdate
            );
            if (result === false) {
              setLastError(() => {
                setMqttStatus({ mqttConnected: 'disconnected' });
                console.log('MQTT Connection failed!');
                return { lastErrorCode: 39, lastErrorMessage: 'Unable to connect with MQTT server' };
              });
            }
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            } else {
              // console.log('Error getting MQTT token!');
              setTimeout(
                async () => {
                  await connectMQTT();
                },
                5000
              );
            }

            setLastError({ lastErrorCode: 40, lastErrorMessage: exception.message });
          });
      } else {
        wiMHomeAPIclient
          .getMqttToken()
          .then((mqttToken) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

            // console.log(mqttToken);
            // console.log("Token available, connecting to MQTT...");
            const result = connectMqtt(
              process.env.REACT_APP_MQTT_SERVER,
              process.env.REACT_APP_MQTT_PORT,
              process.env.REACT_APP_MQTT_TLS !== 'no',
              mqttToken.username,
              mqttToken.token,
              setMqttConnectionStatus,
              execDeviceUpdate,
              execAlertsUpdate
            );
            if (result === false) {
              setLastError(() => {
                setMqttStatus({ mqttConnected: 'disconnected' });
                // console.log("MQTT Connection failed!");
                return { lastErrorCode: 39, lastErrorMessage: 'Unable to connect with MQTT server' };
              });
            }
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            } else {
              // console.log('Error getting MQTT token!');
              setTimeout(
                async () => {
                  await connectMQTT();
                },
                5000
              );
            }

            setLastError({ lastErrorCode: 40, lastErrorMessage: exception.message });
          });
      }
      setLastError(NO_ERROR_OBJECT);
    }

    if (!installations || Object.keys(installations).length === 0) {
      return;
    }

    if (mqttStatus.mqttConnected === MQTT_CONN_STATE.CONNECTED) {
      // console.log("MQTT Connected +++");

      if (selectedInst !== null) {
        if (wiMHomeMQTTClient.instSerial === null) {
          loadAllInstallationData(selectedInst, false, true, false);
          mqttSetInstallationSerial(selectedInst);
        }
      }
    }
    else if (mqttStatus.mqttConnected === MQTT_CONN_STATE.DISCONNECTED) {
      if (LOCAL_VERSION && jwtToken !== null && wiMHomeAPIclient.getJWT() === null) wiMHomeAPIclient.setJWT(jwtToken);

      mqttSetInstallationSerial(null);

      // console.log("MQTT Disconnected ---");
      if(currentUser.username !== null) {
        setMqttStatus({mqttConnected: MQTT_CONN_STATE.CONNECTING});
        setTimeout(
          async () => {
            await connectMQTT();
          },
          isFirstMqttConnection ? 1 : 5000
        );

        setIsFirstMqttConnection(false);
      }
    }
  }, [
    currentUser,
    isFirstMqttConnection,
    jwtToken,
    mqttStatus,
    installations,
    selectedInst,
    loadAllInstallationData,
    handleLogout,
    t,
  ]);

  useEffect(() => {
    // console.log(mqttStatus);
    if(mustUpdateStatus === true && mqttStatus.mqttConnected === MQTT_CONN_STATE.CONNECTED) {
      wiMHomeAPIclient
          .getInstallationList()
          .then((instListResponse) => {
            if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

            if (instListResponse) {
              setInstallations(() => {
                const newInstallations = {};
                instListResponse.forEach((instInfo) => {
                  newInstallations[instInfo.id] = cloneDeep(instInfo);
                });
                return newInstallations;
              });
            }
          })
          .catch((exception) => {
            if (exception instanceof UnauthorizedError) {
              handleLogout();
            }

            setLastError({ lastErrorCode: 7, lastErrorMessage: exception.message });
          });

      if(selectedInst !== null) {
        loadAllInstallationData(selectedInst, true, true, false);
        setMustUpdateStatus(false);
      }
    }
    if(mustUpdateStatus === false && mqttStatus.mqttConnected === MQTT_CONN_STATE.DISCONNECTED) {
      setMustUpdateStatus(true);
    }
  }, [mqttStatus, mustUpdateStatus]);

  function handleKeepAlive() {
    if(currentUser?.username == null)
      return;

    // console.log("Keep Alive timeout...", new Date());
    wiMHomeAPIclient.keepAlive()
        .then(() => {
          if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());
          setLastError(NO_ERROR_OBJECT);
        })
        .catch((exception) => {
      setLastError({ lastErrorCode: 10, lastErrorMessage: exception.message });
    });
  }

  function handleSessionTimeout() {
    // console.log("Session timeout...", new Date());
    setLogoutWarningTimer(
      setTimeout(() => {
        // console.log('Logout after warning', new Date());
        setLogoutWarningTimer(null);
        handleLogout();
      }, SELF_DISCONNECTION_WARNING_TIME * 1000)
    );
    //handleLogout();
  }

  /*
   * Function to handle the login form information
   */
  function handleLogin(username, password, stayConnected) {
    setIsFirstMqttConnection(true);
    wiMHomeAPIclient
      .authenticateAPI(
        username,
        password,
        null,
        i18next.languages[0],
        LOCAL_VERSION ? false : true,
        handleKeepAlive,
        60,
          saveTokenUpdateTimestamp
      )
      .then((response) => {
        // console.log("Login...", new Date());
        if (LOCAL_VERSION) setJwtToken(wiMHomeAPIclient.getJWT());

        if (response) {
          dispatchCurrentUser({
            type: CURRENT_USER_ACTIONS.SET_ALL,
            info: {
              username: username,
              userId: response.userId,
              firstName: response.firstName,
              lastName: response.lastName,
              type: response.type,
              sessionDuration: response.sessionDuration,
              language: i18next.languages[0],
              rememberUsername: currentUser.rememberUsername,
              storedUsername: currentUser.rememberUsername ? username : null,
              stayConnected: currentUser.stayConnected,
            },
          });
        }
        setLastError({ lastErrorCode: 0, lastErrorMessage: null });
        return response.userId;
      })
      .then((userId) => {
        return loadUserOptions(userId);
      })
      .then((userOptions) => {
        if (not_null_or_undefined(userOptions.enableWebPush) && userOptions.enableWebPush > 0) {
          return requestPushPermission().then((allowed) => {
            if (!allowed) {
              alert(`${t('applicationErrors.pushNotAllowed')}`);
              return false;
            }
            return getPushToken();
          });
        } else {
          return true;
        }
      })
      .then((pushTokenResult) => {
        if (pushTokenResult === false) return false;

        if (pushTokenResult === true) return true;

        if (pushTokenResult.result === 0 && pushTokenResult.token !== null) {
          return wiMHomeAPIclient.postPushToken(pushTokenResult.token);
        } else {
          if (pushTokenResult.result === 2) {
            alert(pushTokenResult.err);
          }
          return false;
        }
      })
      .then((pushTokenRes) => {
        if (pushTokenRes === true) {
          setLastError({ lastErrorCode: 0, lastErrorMessage: null });
        } else {
          setLastError({ lastErrorCode: 100, lastErrorMessage: `${t('applicationErrors.cannotRegisterPush')}` });
        }
        // setIsFirstMqttConnection(true);
        return requestAlertCodes();
      })
      .then((alertCodes) => {
        return true;
      })
      .catch((exception) => {
        setLastError({ lastErrorCode: 2, lastErrorMessage: exception.message });
      });
  }

  /*
   * Function to handle the "remember login checkbox" from login form
   */
  function handleRememberLoginChange() {
    dispatchCurrentUser({
      type: CURRENT_USER_ACTIONS.SET_REMEMBER_USERNAME,
      info: { rememberUsername: !currentUser.rememberUsername },
    });
  }

  /*
   * Function to handle the "remember login checkbox" from login form
   */
  function handleStayConnectedChange() {
    dispatchCurrentUser({
      type: CURRENT_USER_ACTIONS.SET_STAY_CONNECTED,
      info: { stayConnected: !currentUser.stayConnected },
    });
  }

  function bodyClickCallback()
  {
    if(autoLogoutTimer !== null) {
      clearTimeout(autoLogoutTimer);
    }

    if(currentUser.stayConnected) {
      autoLogoutTimer = null;
      return;
    }

    if (currentUser.sessionDuration > 0) {
      let timeToSessionExpiration = (currentUser.sessionDuration - SELF_DISCONNECTION_WARNING_TIME) * 1000;
      if(autoLogoutTimer === null && tokenTimestamp != null) {
        timeToSessionExpiration = (dateAdd(tokenTimestamp, {seconds: (currentUser.sessionDuration - SELF_DISCONNECTION_WARNING_TIME)}) - new Date());
      }

      //console.log("timeToSessionExpiration: ", timeToSessionExpiration)
      if(timeToSessionExpiration <= 0) {
        handleLogout();
      } else {
        autoLogoutTimer = setTimeout(function () {
          // console.log('Auto logout', new Date());
          handleSessionTimeout();
        }, timeToSessionExpiration);
        // console.log('Debug timeToSessionExpiration: ', timeToSessionExpiration);
      }
    }
  }

  useEffect(() => {
    if (currentUser.username !== null && currentUser.stayConnected === false) {
      bodyClickCallback();
      document.body.addEventListener('click', bodyClickCallback, true);
    }
    return () => {
      document.body.removeEventListener('click', bodyClickCallback, true);
    }
  }, [currentUser]);

  return (
    <GenericContext.Provider
      value={{
        lastError: lastError,
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userLanguage: currentUser.language,
        userType: currentUser.type,
        userId: currentUser.userId,
        handleLogout: handleLogout,
        handleSignOut: handleSignOut,
        handleLanguageChange: handleLanguageChange,
        requestWithingsAuthorization,
        requestWithingsUnlink
      }}
    >
      <MqttContext.Provider value={{ mqttStatus, wiMHomeMQTTClient }}>
        <InstallationsContext.Provider
          value={{
            installations,
            selectedInst,
            devicesState,
            handleSelectInstallation,
            installationGuests,
            setInstallationGuests,
            deleteInstGuest,
            updateInstGuest,
            createInstGuest,
            installationContacts,
            setInstallationContacts,
            deleteInstContact,
            updateInstContact,
            createInstContact,
            requestDeviceUpdate,
            requestDeviceDetails,
            requestDeviceEvents,
            requestDeviceData,
            requestInstallationData,
            requestInstallationDataNumber,
            requestInstallationEvents,
            requestInstallationEventsNumber,
            changeDeviceValue,
            loadAllInstallationData,
            loadInstPhyDevicesInfos,
            requestPhyDeviceDetails,
            loadInstReminders,
            deleteReminder,
            updateReminder,
            createReminder,
            requestReminderDetails,
            loadInstReminderActions,
            requestReminderActionDetails,
            deleteReminderAction,
            updateReminderAction,
            createReminderAction,
            requestReminderIcons,
            requestReminderImages,
            postReminderImage,
            deleteReminderImage,
            requestReminderSounds,
            postReminderSound,
            deleteReminderSound,
            requestReminderRecipients,
            requestInstallationLinks,
            updateInstallationLinks,
            requestInstallationInfoDocs,
            postInstallationInfoDoc,
            deleteInstallationInfoDoc,
            deleteInstService,
            updateInstService,
            createInstService,
            requestExternalServiceProviders,
            requestServiceTemplates,
            loadInstJournal,
            loadInstJournalCount,
            loadLastJournalEntry,
            createInstJournalEntry,
            loadInstallationCases,
            loadInstallationCasesNumber,
            addInstallationNewCase,
            addInstallationCaseEntry,
            loadUserDisplay,
            updateUserDisplay,
            loadUserOptions,
            updateUserOptions,
            loadUserInfo,
            updateUserInfo,
            updateUserPassword,
            requestUserSounds,
            ackAlerts,
            setAckAlerts,
            acknowledgeAlerts,
            requestCountries,
            requestTimezones,
            requestLocales,
            requestAlertCodes,
            alertCodes,
            requestFiscalIdentifierTypes,
            requestContactRoles,
            alertSoundInfo,
            alerter,
            setAlerter,
            alerts,
            setAlerts,
            curAlertsNumber,
            setCurAlertsNumber,
            directInstGuestLoad,
            loadProgrammedCalls,
            programmedCalls,
            reloadProgrammedCalls,
            installationReports,
            setInstallationReports,
            downloadInstReport,
            countUnreadReports
          }}
        >
          {/* <Router basename={process.env.REACT_APP_SITE_ROOT}> */}
          <Modal closeOnClickOutside={false} closeOnEsc={false} onClose={() => {}} open={logoutWarningTimer !== null}>
            <h1>{`${t('disconnectionWarningTitle')}`}</h1>
            <p>{`${t('disconnectionWarningDesc')}`}</p>
            <button
              className='cbutton ok-button'
              onClick={() => {
                clearTimeout(logoutWarningTimer);
                setLogoutWarningTimer(null);
              }}
              type='button'
            >
              {t('OK')}
            </button>
          </Modal>
          <Switch>
            <Route
              exact
              path={SITE_LINKS.LOGIN}
              render={props => (
                <Login
                  {...props}
                  authenticatedUser={currentUser}
                  handleLogin={handleLogin}
                  handleRememberLoginChange={handleRememberLoginChange}
                  handleStayConnectedChange={handleStayConnectedChange}
                  lang={i18n.services.languageDetector.detect()}
                />
              )}
            />
            <Route
              exact
              path={SITE_LINKS.INSTALLATIONS}
              render={props => <InstallationsPage {...props} currRoute={SITE_LINKS.INSTALLATIONS} />}
            />
            <Route
              exact
              path={SITE_LINKS.STATUS}
              render={props => (
                <StatusPage
                  {...props}
                  currRoute={SITE_LINKS.STATUS}
                  devicesState={devicesState}
                  installationServices={installationServices}
                  setInstallationServices={setInstallationServices}
                />
              )}
            />
            <Route
                exact
                path={SITE_LINKS.LOG}
                render={props => <LogPage {...props} currRoute={SITE_LINKS.LOG} />}
            />
            <Route
              exact
              path={SITE_LINKS.TECH}
              render={props => <TechPage {...props} currRoute={SITE_LINKS.TECH} phyDevicesInfo={phyDevicesInfo} />}
            />
            <Route
              exact
              path={SITE_LINKS.GRAPHS}
              render={props => <GraphPage {...props} currRoute={SITE_LINKS.GRAPHS} />}
            />
            <Route
              exact
              path={SITE_LINKS.TRENDS}
              render={props => <TrendPage {...props} currRoute={SITE_LINKS.TRENDS} />}
            />
            <Route
              exact
              path={SITE_LINKS.REMINDERS_ADMIN}
              render={props => <RemindersAdminPage {...props} currRoute={SITE_LINKS.REMINDERS_ADMIN} />}
            />
            <Route
              exact
              path={SITE_LINKS.REMINDER_ACTIONS_ADMIN}
              render={props => (
                <ReminderActionsAdminPage
                  {...props}
                  currRoute={SITE_LINKS.REMINDER_ACTIONS_ADMIN}
                  devicesState={devicesState}
                />
              )}
            />
            <Route
              exact
              path={SITE_LINKS.ALERT_DETAIL}
              render={props => (
                <AlertDetailPage
                  {...props}
                  allowModify={installations?.[selectedInst]?.userCapabilities?.includes('alerts-management')}
                  currRoute={SITE_LINKS.ALERT_DETAIL}
                />
              )}
            />
            <Route
              exact
              path={SITE_LINKS.REPORTS}
              render={props => <ReportsPage {...props} currRoute={SITE_LINKS.REPORTS} />}
            />
            {process.env.REACT_APP_PRIVACY_LINK.charAt(0) === '/' && (
              <Route exact path={SITE_LINKS.PRIVACY} render={props => <Privacy {...props} />} />
            )}
            {process.env.REACT_APP_COOKIE_LINK.charAt(0) === '/' && (
              <Route exact path={SITE_LINKS.COOKIES} render={props => <Cookies {...props} />} />
            )}
            <Route component={Error404} path='/' />
          </Switch>
          {/* </Router> */}
        </InstallationsContext.Provider>
      </MqttContext.Provider>
    </GenericContext.Provider>
  );
}

const defaultEmptyMqttState = {
  mqttConnected: MQTT_CONN_STATE.DISCONNECTED,
};

export default App;
