import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavMenu from './NavMenu';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { SITE_LINKS } from '../Contants';
import Breadcrumb from './Breadcrumb';
import { InstallationsContext, MqttContext, GenericContext } from './App';
import LanguageSelector from './LanguageSelector';
import UserInformationModal from './UserInformationModal';
import AlertsBar from './AlertsBar';

export default function HeaderSection(props) {
  const { 
    mainContentRef, 
    currRoute
  } = props;

  const { 
    selectedInst, 
    handleSelectInstallation,
    countUnreadReports
  } = React.useContext(InstallationsContext);
  
  const {
    mqttStatus
  } = React.useContext(MqttContext);

  const { 
    userFirstName,
    userLastName,
    userLanguage,
    handleLanguageChange 
  } = React.useContext(GenericContext);

  const [userInfoModalOpen, setUserInfoModalOpen] = useState(false);

  let sideMenuRef = React.createRef();
  const {t/*, i18n*/} = useTranslation();

  function handleNavMenuClick() {
    sideMenuRef.current.classList.toggle("active");
    mainContentRef.current.classList.toggle("narrow");
  }

  function handleLogoClick() {
    handleSelectInstallation(null);
  }

  return (
    <>
      <header className="main-header">
        <div
          className="menu-toggle"
          id={'menu-toggle'}
          onClick={() => handleNavMenuClick()}
          title={t('menuDescription')}
        >
          <FontAwesomeIcon icon={faBars} />
          {countUnreadReports(null) !== 0 && <div className="notification-circle"/>}
        </div>
        <Link className="logo-wimonitor-header" onClick={() => handleLogoClick()} to={SITE_LINKS.INSTALLATIONS}>
          <span>
            {!process.env.REACT_APP_LOGO_LINK_HOME && (
              <img alt="logo wimonitor" src="images/logo.png"/>
            )}
            {process.env.REACT_APP_LOGO_LINK_HOME && (
              <img alt="logo wimonitor" src="images/logo-only.png"/>
            )}
          </span>
        </Link>
        <Link className="logo-wimonitor-header-small" onClick={() => handleLogoClick()} to={SITE_LINKS.INSTALLATIONS}>
          <span>
              <img alt="logo wimonitor" src="images/logo-only.png"/>
          </span>
        </Link>
        {process.env.REACT_APP_LOGO_LINK_HOME && (
        <a alt="site home" href={process.env.REACT_APP_LOGO_LINK_HOME} rel="noopener noreferrer" target="_blank">
          <span className="logo">
            <img alt="logo home" src={process.env.REACT_APP_LOGO_LINK_HOME_IMG}/>
          </span>
        </a>)}
        <div className="header-user-info">
          <p>{t('loggedInUser')}<br/><span onClick={() => {setUserInfoModalOpen(true);}}>{userFirstName} {userLastName}</span></p>
        </div>
        <LanguageSelector 
          currentLanguage={userLanguage}
          languageSelector={handleLanguageChange}
        />
        {(userInfoModalOpen) && (
          <UserInformationModal 
            open={userInfoModalOpen} 
            setOpen={setUserInfoModalOpen}
          />
        )}
      </header>
      <Breadcrumb mqttStatus={mqttStatus}/>
      <NavMenu 
        currRoute={currRoute} 
        handleSelectInstallation={handleSelectInstallation} 
        selectedInst={selectedInst}
        sideMenuRef={sideMenuRef}
      />
      <AlertsBar />
    </>
  );
}
