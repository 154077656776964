import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';
import MainArea from './MainArea';
import ActiveTile from './ActiveTile';
import ProgrammedCalls from "./ProgrammedCalls";
import { InstallationsContext, GenericContext, MqttContext } from './App';
import { SITE_LINKS } from '../Contants';
import {dateToTimestamp, timestampToDate} from '../utilities';
import {
  ALERT_TYPE_ALARM,
  ALERT_TYPE_TECH_ALARM, ALERT_TYPE_TECH_WARNING, ALERT_TYPE_WARNING,
  INSTALLATION_ALERT_STATUS_ALARM,
  INSTALLATION_ALERT_STATUS_DISCONNECTED, INSTALLATION_ALERT_STATUS_NO_ALARM, INSTALLATION_ALERT_STATUS_WARNING,
  INSTALLATION_MODE_DISABLED, INSTALLATION_MODE_MAINTENANCE, INSTALLATION_MODE_MAINTENANCE_ALARM_TEST,
  INSTALLATION_MODE_NORMAL, JOURNAL_ENTRY_TYPE_PROG_CALL
} from "../api/wimhome-api";
import {ProgrammedCallCallInterface} from "./ProgrammedCallCallInterface";
import {confirmAlert} from "react-confirm-alert";
import dateAdd from "date-fns/add";
import JournalInterface from "./JournalInterface";

export default withRouter(function InstallationsPage(props) {
  const { 
    currRoute, 
    history, 
  } = props;
  const {t, i18n} = useTranslation();

  const {
    alerts,
    installations, 
    selectedInst, 
//    setSelectedInst,
    handleSelectInstallation,
    acknowledgeAlerts,
    alertCodes,
    createInstJournalEntry,
    reloadProgrammedCalls
  } = React.useContext(InstallationsContext);
  const { errors, userType } = React.useContext(GenericContext);
  const { mqttStatus } = React.useContext(MqttContext);

  let mainContentRef = React.createRef();

  const [programmedCall, setProgrammedCall] = useState(null)
  const [journalModalType, setJournalModalType] = useState('closed');

  useEffect(() => {
    if(userType !== "remote_assistance")
      return;
    if(journalModalType === 'closing' && selectedInst != null) {
      setJournalModalType('closed')
      handleSelectInstallation(null);
    }
  }, [journalModalType]);

  function installationOnClickHandler(e) {
//    setSelectedInst(e);
    if(alerts && alerts.get(e)) {
      acknowledgeAlerts(e, [...alerts.get(e).alerts]);
    }
    handleSelectInstallation(e);
    // console.log(e);
    history.push(SITE_LINKS.STATUS);
  }

  function decodeIconColor(installation) {
    if(installation.mode === INSTALLATION_MODE_NORMAL) {
      switch (installation.status) {
        case INSTALLATION_ALERT_STATUS_NO_ALARM:
          return "active-state";

        case INSTALLATION_ALERT_STATUS_WARNING:
          return "warning-state";

        case INSTALLATION_ALERT_STATUS_ALARM:
        case INSTALLATION_ALERT_STATUS_DISCONNECTED:
          return "alarm-state";

        default:
          return ""
      }
    }

    if(installation.mode === INSTALLATION_MODE_MAINTENANCE || installation.mode === INSTALLATION_MODE_MAINTENANCE_ALARM_TEST) {
      return "blue";
    }

    if(installation.mode === INSTALLATION_MODE_DISABLED) {
      return "gray";
    }

    return "";
  }

  function compareAlert(a, b) {
    if(a.value > 0 && b.value < 0)
      return -1;

    if(a.value < 0 && b.value > 0) {
      return 1;
    }

    if(a.type > b.type)
      return -1;

    if(a.type < b.type) {
      return 1;
    }

    if((alertCodes[a.code] === ALERT_TYPE_ALARM || alertCodes[a.code] === ALERT_TYPE_WARNING) &&
        (alertCodes[b.code] === ALERT_TYPE_TECH_ALARM || alertCodes[b.code] === ALERT_TYPE_TECH_WARNING)) {
      return -1;
    }

    if((alertCodes[a.code] === ALERT_TYPE_TECH_ALARM || alertCodes[a.code] === ALERT_TYPE_TECH_WARNING) &&
        (alertCodes[b.code] === ALERT_TYPE_ALARM || alertCodes[b.code] === ALERT_TYPE_WARNING)) {
      return 1;
    }

    if(a.date > b.date)
      return -1;

    if(a.date < b.date) {
      return 1;
    }

    return 0;
  }

  function calcHigherAlert(alertList) {
    if(alertList.length === 0) { return -1 }
    if(alertList.length === 1) { return 0 }

    let higherAlertIdx = 0;
    for (let i = 1; i < alertList.length; i++) {
      if(compareAlert(alertList[higherAlertIdx], alertList[i]) < 0) {
        higherAlertIdx = i;
      }
    }
    return higherAlertIdx;
  }

  function reorderInstallations(installationsObj) {
    if(!installationsObj)
      return [];
      
    return Object.values(installationsObj).sort( (a, b) => {
      if(a.mode === b.mode) {
        if (a.status === b.status) {
          if(a.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) {
            // Order disconnected installation from newer to older disconnection timestamp
            return b.lastStatusUpdate - a.lastStatusUpdate;
          }

          if(a.status === INSTALLATION_ALERT_STATUS_ALARM || a.status === INSTALLATION_ALERT_STATUS_WARNING ) {
            return compareAlert(calcHigherAlert(a.alerts), calcHigherAlert(b.alerts));
          }

          // Installations without alerts are ordered by title
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          }
          return 0;
        }
        return b.status - a.status;
      }
      const a_ord = a.mode === INSTALLATION_MODE_DISABLED ? 99 : a.mode
      const b_ord = b.mode === INSTALLATION_MODE_DISABLED ? 99 : b.mode
      return a_ord - b_ord;
    });
  }

  function reorderAlerts(alerts) {
    return alerts.sort( (a, b) => {
      if(a.type === b.type) {
        return a.date - b.date;
      }
      return b.type - a.type;
    });
  }

  function decodeIcon(installation) {
    if(installation.mode === INSTALLATION_MODE_NORMAL)
      return installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED ? "icon-pericolo" : "icon-casa"

    if(installation.mode === INSTALLATION_MODE_DISABLED)
      return "icon-casa"

    if(installation.mode === INSTALLATION_MODE_MAINTENANCE || installation.mode === INSTALLATION_MODE_MAINTENANCE_ALARM_TEST)
      return "icon-wrench"
  }

  function extractInstallationAlerts(installation) {
    if(typeof installation === 'undefined' || installation === null)
      return;

    if(installation.mode === INSTALLATION_MODE_DISABLED) {
      return (
        <div className="installation-alarm-summary">
          <span className="installation-inhibited-line">
            {`${t('installations.disabledSince')}`} {timestampToDate(installation.lastModeUpdate).toLocaleString(i18n.language)}
          </span>
          <br/>
        </div>
      )
    }

    if(installation.mode === INSTALLATION_MODE_MAINTENANCE) {
      return (
          <div className="installation-alarm-summary">
          <span className="installation-inhibited-line">
            {`${t('installations.inMaintenanceSince')}`} {timestampToDate(installation.lastModeUpdate).toLocaleString(i18n.language)}
          </span>
            <br/>
          </div>
      )
    }

    if(installation.mode === INSTALLATION_MODE_MAINTENANCE_ALARM_TEST) {
      return (
          <div className="installation-alarm-summary">
          <span className="installation-warning-line">
            {`${t('installations.inMaintenanceAlarmSince')}`} {timestampToDate(installation.lastModeUpdate).toLocaleString(i18n.language)}
          </span>
            <br/>
          </div>
      )
    }

    if(installation.status === INSTALLATION_ALERT_STATUS_NO_ALARM)
      return;

    return (
      <div className="installation-alarm-summary">
        {(installation.status === INSTALLATION_ALERT_STATUS_DISCONNECTED) && (
          <>
            <span className="installation-alarm-line">
              {`${t('installations.disconnectedSince1')}`} {timestampToDate(installation.lastStatusUpdate).toLocaleString(i18n.language)}
            </span>
            <br/>
          </>
        )}
        {reorderAlerts(installation.alerts).map( (alert) => {
          let date = new Date();
          date.setTime(alert.date * 1000);
          let lineClassName = ""
          switch(alert.type) {
            case 0:
              lineClassName = "installation-inhibited-line";
            break;

            case 1: 
              lineClassName = "installation-warning-line";
            break;

            default:
            case 2: 
              lineClassName = "installation-alarm-line";
            break;
          }
          return (
            <React.Fragment key={alert.deviceId}>
              <span className={lineClassName}>
                {date.toLocaleString(i18n.language)} {alert.title}
              </span>
              <br/>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  const installationsArray = reorderInstallations(installations);
  // console.log("installationsArray: ", installationsArray);

  function saveCall(installationSerial, lastCall, description, result, willRetry) {
    // console.log(lastCall)

    // let nextCallTime = dateToTimestamp(dateAdd(timestampToDate(lastCall.caseData.nextCall), {days: lastCall.caseData.daysBetweenCalls}))
    let nextCallTime = dateAdd(timestampToDate(lastCall.caseData.nextCall), {days: lastCall.caseData.daysBetweenCalls})
    const now = new Date();
    while(nextCallTime < now) {
      nextCallTime = dateAdd(nextCallTime, {days: lastCall.caseData.daysBetweenCalls})
    }

    if(willRetry) {
      nextCallTime = lastCall.caseData.nextCall; // does not change the next try date to not throw off the periodicity
    } else {
      nextCallTime = dateToTimestamp(nextCallTime)
    }
    // console.log(nextCallTime)

    let newCall = {...lastCall,
      title: lastCall.title,
      description: description,
      type: JOURNAL_ENTRY_TYPE_PROG_CALL,
      data: {
        daysBetweenCalls: lastCall.caseData.daysBetweenCalls,
        nextCall: nextCallTime,
        result: result,
        willRetry: willRetry
      }
    };
    createInstJournalEntry(installationSerial, newCall)
    .then((savedJournal) => {
      if(savedJournal != null) {
        setProgrammedCall({"installationSerial": null, "installationTitle": null, "lastCall": null})
        reloadProgrammedCalls();
      } else {
        confirmAlert({
          title: `${t('programmedCalls.errorTitle')}`,
          message: `${t('programmedCalls.cannotSaveCall')}`,
          buttons: [
            {
              label: t('OK'),
            }
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
          overlayClassName: "overlay-confirm-alert-over-all"
        })
      }
    })
    .catch((err) => {
      confirmAlert({
        title: `${t('programmedCalls.errorTitle')}`,
        message: `${t('programmedCalls.cannotSaveCall') + " error: " + err.message}`,
        buttons: [
          {
            label: t('OK'),
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-confirm-alert-over-all"
      })
    })
  }

  function abortCall() {
    setProgrammedCall({"installationSerial": null, "installationTitle": null, "lastCall": null})
  }

  return (
    <>
      <ProgrammedCallCallInterface
          abort={abortCall}
          installationSerial={programmedCall?.installationSerial}
          installationTitle={programmedCall?.installationTitle}
          lastCall={programmedCall?.lastCall}
          saveCall={saveCall}
      />
      <HeaderSection 
        currRoute={currRoute} 
        errors={errors} 
        mainContentRef={mainContentRef}
        mqttStatus={mqttStatus}
        selectedInst={selectedInst}
      />
      <section className="main-content" ref={mainContentRef}>
        <MainArea title={t('installations.title')}>
          {installationsArray.map( (installation) => {
            // console.log('installation: ', installation);
            return <ActiveTile
              key={installation.id}
              descType={""}
              details={installation.description}
              iconName={ decodeIcon(installation) }
              moreDetails={extractInstallationAlerts(installation)}
              onClickHandler={ () => installationOnClickHandler(installation.id) }
              tileId={installation.id}
              tileState={decodeIconColor(installation)}
              title={installation.title}
            />
          })}
          {userType === "remote_assistance" &&
            <>
              {(journalModalType !== 'closed') && (
                  <JournalInterface
                      onlyProgrammedCalls={true}
                      setType={setJournalModalType}
                      showInstallationTitle={true}
                      standalone={true} type={journalModalType}
                  />
              )}
              <ProgrammedCalls
                  setJournalModalType={setJournalModalType}
                  setProgrammedCall={setProgrammedCall}
                  title={t('programmedCalls.title')}
              />
            </>
          }
        </MainArea>
      </section>
      <FooterSection/>
    </>
  )
});
