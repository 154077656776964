import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import HeaderSection from './HeaderSection';
import Alerts from './Alerts';
import { useTranslation } from 'react-i18next';
import { InstallationsContext, hasCapability } from './App';
import { extractFileName, extractFullFileName, timestampToLocalDateString, timestampToLocalTimeString, 
  timestampToDate, dateToTimestamp, parseTimeDuration, getDaysInMonth } from '../utilities';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBook, faCheck, faClock, faEdit, faEye, faEyeSlash, faInfoCircle, faLongArrowAltDown, 
  faLongArrowAltUp, faMobileAlt, faPause, faPlay, faRedoAlt, faThumbsDown, faTrashAlt, faUpload, faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
import FooterSection from './FooterSection';
import { format as formatDate } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { SITE_LINKS } from '../Contants';
import { null_or_undefined, not_null_or_undefined } from '../utilities';
import Map from "lodash/_Map";
import {confirmAlert} from "react-confirm-alert";

const en = enUS;
//Used by date-fns/esm format 
const locales = {it, de, fr, en, enGB, enUS};

const MAX_UPLOADABLE_IMAGE_SIZE = 100 * 1024;
const MAX_UPLOADABLE_AUDIO_SIZE = 5 * 1048576;

//Used by react-datepicker 
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

export default function RemindersAdminPage({ currRoute }) {
  const {t, i18n} = useTranslation();
  const { 
    selectedInst, 
    installations,
    loadInstReminders,
    loadInstReminderActions,
    deleteReminder,
    createReminder,
    updateReminder,
    requestReminderIcons,
    requestReminderImages,
    postReminderImage,
    deleteReminderImage,
    requestReminderSounds,
    postReminderSound,
    deleteReminderSound,
    requestReminderRecipients,
    requestReminderActionDetails
  } = useContext(InstallationsContext);
  const [requestingReminders, setRequestingReminders] = useState(false);  //Loading reminders
  const [reminders, setReminders] = useState(null); //Installation reminders
  const [remindersFilter, setRemindersFilter] = useState(
    {
      iconList: [],
      typeList: [],
      returnExpired: false, 
      returnFuture: true, 
      startDateInterval: null, 
      endDateInterval: null,
      requestedStartDate: null,
      requestedEndDate: null,
      type: '*',
      state: '*',
      icon: null
    }
  );
  const [reminderListOptions, setReminderListOptions] = useState(
    {
      orderByField: "date",
      orderDirectionAsc: true,
      elementPerPage: -1,  //-1 => all
      pageOffset: 0
    }
  );
  const [orderedReminderRefs, setOrderedReminderRefs] = useState(null);
  const [reminderInfoModal, setReminderInfoModal] = useState(null);  //Information about reminder
  const [reminderWizardModal, setReminderWizardModal] = useState({reminder: null, page: 0, periodicityType: 0});  //Add/Modify reminder
  const [showStateLegend, setShowStateLegend] = useState(false);
  const [reminderActions, setReminderActions] = useState(new Map());
  const [reminderActionsRead, setReminderActionsRead] = useState(false);
  const [replyModal, setReplyModal] = useState(null);
  const [uploadModalType, setUploadModalType] = useState(null);
  const [uploadImageSelected, setUploadImageSelected] = useState(null);
  const [uploadAudioSelected, setUploadAudioSelected] = useState(null);
  const [uploadFileInput, setUploadFileInput] = useState(null);
  const [cachedSoundOptions, setCachedSoundOptions] = useState(false);
  const [cachedImageOptions, setCachedImageOptions] = useState(false);
  const [cachedRecipients, setCachedRecipients] = useState(null);
  const [showSaving, setShowSaving] = useState(false);

  let mainContentRef = React.createRef();
  const selectedInstallationData = (selectedInst && installations) ? installations[selectedInst] : null;

  const IMAGE_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;
  const SOUNDS_BASE_URL = process.env.REACT_APP_WIMHOME_WEB_SERVER_URL;

  const newEmptyReminder = {
    isNew: true,
    installationSerial: null,
    id: null,
    state: 'scheduled',
    timestamp: null,
    audio: null,
    icon: null,
    photo: null,
    priority: 0,
    reminderType: 0,
    messageText: "",
    messageTextA: "",
    langCode: (not_null_or_undefined(i18n.language)) ? i18n.language.substring(0,2) : null,
    timeout: 0,
    repeatOptions: {
        periodicity: 'none'
    },  
    recipients: ['*'],
    replies: [{optionValue: 'OK', optionText: `${t('OK')}`, optionTextA: `${t('OK')}`, optionIcon: null}],
    actionOnShowId: null,
    actionOnTimeoutId: null,
    actionOnReplyId: null
  };

  const newEmptyReply = {
    isNew: true,
    optionText: '',
    optionTextA: '',
    optionValue: null
  };

  useEffect(async () => {
    await getUsersDescriptions();
  }, []);
  
  function reminderInfoOnClickHandler(reminder) {
    setReminderInfoModal(reminder);
  }

  function closeReminderInfoModal() {
    setReminderInfoModal(null);
  }

  function closeStateLegendModal() {
    setShowStateLegend(false);
  }

  function closeReminderWizardModal() {
    setReminderWizardModal({reminder: null, page: 0, periodicityType: 0});
  }

  async function saveReminderWizardModal() {
    if(reminderWizardModal.reminder.isNew) {
      reminderWizardModal.reminder.isNew = null;
      const reminder = await createReminder(selectedInst, reminderWizardModal.reminder);
      if(not_null_or_undefined(reminder) && not_null_or_undefined(reminder.id)) {
        let newReminderList = cloneDeep(reminders);
        newReminderList.set(reminder.id, reminder);
        setReminders(newReminderList);
        setReminderWizardModal({reminder: null, page: 0, periodicityType: 0});
      } else {
        alert(reminder);
      }
    } else {
      const reminder = await updateReminder(selectedInst, reminderWizardModal.reminder.id, reminderWizardModal.reminder);
      if(not_null_or_undefined(reminder) && not_null_or_undefined(reminder.id)) {
        const newReminderList = await loadInstReminders(selectedInst, remindersFilter.returnExpired, remindersFilter.returnFuture,
          remindersFilter.startDateInterval, remindersFilter.endDateInterval);
        setReminders(newReminderList);
        setReminderWizardModal({reminder: null, page: 0, periodicityType: 0});
      } else {
        alert(reminder);
      }
    }
    // setReminderWizardModal({reminder: null, page: 0, periodicityType: 0});
  }

  function renderUsersCheckboxes() {
    let recipients = ['*'];
    if(null_or_undefined(reminderWizardModal.reminder.recipients)) {      
      setReminderWizardModal((cur) => {
        cur.reminder.recipients = ['*'];
        return cloneDeep(cur);
      });
    } else {
      recipients = reminderWizardModal.reminder.recipients;
    }
    const users = cachedRecipients;
    let checkboxes = [];
    let a = 0;
    users?.forEach( (user, userId) => {
      checkboxes[a++] = (
        <label key={a} className="checkbox-label">
          {user}
          <input 
            data-user-id={userId} 
            defaultChecked={recipients.includes(`${userId}`)} 
            onClick={ (e) => {
              const checked = e.target.checked;
              if(checked) {
                if(!recipients.includes(`${userId}`)) {
                  let newActionModal = cloneDeep(recipients);
                  newActionModal.push(`${userId}`);
                  setReminderWizardModal((cur) => {
                    cur.reminder.recipients = newActionModal;
                    return cloneDeep(cur);
                    }
                  )
                }
              } else {
                if(recipients.includes(`${userId}`)) {
                  const newActionModal = recipients.filter((el) => {
                    return el !== `${userId}`;
                  });
                  setReminderWizardModal((cur) => {
                    cur.reminder.recipients = newActionModal;
                    return cloneDeep(cur);
                    }
                  )
                }
              }
            }}
            type="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      );
    });
    return checkboxes;
  }  

  async function actionOptions() {
    let opts = [];
    if(!reminderActionsRead) {
      const actionInfos = await loadInstReminderActions(selectedInst);      
      setReminderActions(actionInfos);
      setReminderActionsRead(true);
      actionInfos.forEach((action, actionId) => {
        opts.push({value: actionId, label: `${action.label}`});
      });
    } else {
      reminderActions.forEach((action, actionId) => {
        opts.push({value: actionId, label: `${action.label}`});
      });
    }
    return opts;
  }

  function decodeActionOption(actionId) {
    if(null_or_undefined(actionId)) {
      return '';
    }
    if(reminderActions.has(actionId)) {
      const action = reminderActions.get(actionId);
      return {value: actionId, label: `${action.label}`};
    }
    return {value: actionId, label: `? ${actionId} ?`};
  }

  function decodePeriodicity(reminderInfo) {
    let periodicity = null;
    let intervalLabel = null;
    let interval = null;
    let month = null;
    let dayOfMonth = null;
    let holidayRepetition = null;
    let weekDaysList = null;

    switch(reminderInfo.repeatOptions.periodicity) {
      case "none":
        periodicity = `${t('remindersAdminPage.repetitionNone')}`;
      break;

      case "n-day":
        if(reminderInfo.repeatOptions.period === 1) {
          periodicity = `${t('remindersAdminPage.repetitionDaily')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionDaysLabel')}`;
        } else if(reminderInfo.repeatOptions.period === 7) {
          periodicity = `${t('remindersAdminPage.repetitionWeekly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionWeeksLabel')}`;
        } else if(reminderInfo.repeatOptions.period === 30) {
          periodicity = `${t('remindersAdminPage.repetitionMonthly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionMonthsLabel')}`;
        } else if(reminderInfo.repeatOptions.period === 365) {
          periodicity = `${t('remindersAdminPage.repetitionYearly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionMonthsLabel')}`;
        } else {
          periodicity = `${t('remindersAdminPage.repetitionNDays')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionDaysLabel')}`;
          interval = (not_null_or_undefined(reminderInfo.repeatOptions.period)) ? `${reminderInfo.repeatOptions.period}` : '1';
        }
      break;  

      case "n-week":
        if(reminderInfo.repeatOptions.period === 1) {
          periodicity = `${t('remindersAdminPage.repetitionWeekly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionWeeksLabel')}`;
        } else {
          periodicity = `${t('remindersAdminPage.repetitionNWeeks')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionWeeksLabel')}`;
          interval = (not_null_or_undefined(reminderInfo.repeatOptions.period)) ? `${reminderInfo.repeatOptions.period}` : '1';
        }
        if((typeof reminderInfo.repeatOptions.weekdays === "object") && (reminderInfo.repeatOptions.weekdays !== null)) {
          if(reminderInfo.repeatOptions.weekdays.length > 0) {
            const localizedDays = reminderInfo.repeatOptions.weekdays.map( day => `${t(day)}`);
            weekDaysList = localizedDays.join(", ");
          }
        }
      break;

      case "monthly":
        if(reminderInfo.repeatOptions.period === 1) {
          periodicity = `${t('remindersAdminPage.repetitionMonthly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionMonthsLabel')}`;
        } else {
          periodicity = `${t('remindersAdminPage.repetitionNMonths')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionMonthsLabel')}`;
          interval = (not_null_or_undefined(reminderInfo.repeatOptions.period)) ? `${reminderInfo.repeatOptions.period}` : '1';
          dayOfMonth = (not_null_or_undefined(reminderInfo.repeatOptions.day)) ? `${reminderInfo.repeatOptions.day}` : '1';
        }
      break;
  
      case "yearly":
        if(reminderInfo.repeatOptions.period === 1) {
          periodicity = `${t('remindersAdminPage.repetitionYearly')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionMonthsLabel')}`;
        } else {
          periodicity = `${t('remindersAdminPage.repetitionNYears')}`;
          intervalLabel = `${t('remindersAdminPage.repetitionYearsLabel')}`;
          interval = (not_null_or_undefined(reminderInfo.repeatOptions.period)) ? `${reminderInfo.repeatOptions.period}` : '1';
          month = (not_null_or_undefined(reminderInfo.repeatOptions.month)) ? `${reminderInfo.repeatOptions.month}` : '1';
          dayOfMonth = (not_null_or_undefined(reminderInfo.repeatOptions.day)) ? `${reminderInfo.repeatOptions.day}` : '1';
        }
      break;
  
      default:
        return `${t('unknown')}`;
    }

    if(not_null_or_undefined(reminderInfo.repeatOptions.repeatOnHoliday)) {
      switch(reminderInfo.repeatOptions.repeatOnHoliday) {
        case "yes":
          holidayRepetition = `${t('yes')}`;
        break;
  
        case "no":
          holidayRepetition = `${t('no')}`;
        break;
  
        case "postpone":
          holidayRepetition = `${t('remindersAdminPage.repetitionPostpone')}`;
        break;
  
        case "anticipate":  
          holidayRepetition = `${t('remindersAdminPage.repetitionAnticipate')}`;
        break;
  
        default: 
          holidayRepetition = "?";
      }
    }
    
    return {
      periodicity: periodicity,
      intervalLabel: intervalLabel,
      interval: interval,
      month: month,
      dayOfMonth: dayOfMonth,
      holidayRepetition: holidayRepetition,
      weekDaysList: weekDaysList
    };
  }

  function renderPeriodicityInfo(reminderInfo) {
    const { 
      periodicity, 
      intervalLabel, 
      interval,
      month,
      dayOfMonth,
      holidayRepetition,
      weekDaysList
    } = decodePeriodicity(reminderInfo);

    return (
      <>
        <div className="reminder-info">
          <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoPeriodicity')}`}: </span>
          <span className="reminder-info-value">{periodicity}</span>
        </div>
        {((intervalLabel !== null) && (interval !== null)) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{intervalLabel}:</span> 
            <span className="reminder-info-value">{interval}</span>
          </p>
        )}
        {((month !== null)) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMonth')}`}</span> 
            <span className="reminder-info-value">{month}</span>
          </p>
        )}
        {((dayOfMonth !== null)) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoDayMonth')}`}</span> 
            <span className="reminder-info-value">{dayOfMonth}</span>
          </p>
        )}
        {(holidayRepetition !== null) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoHolidays')}`}</span> 
            <span className="reminder-info-value">{holidayRepetition}</span>
          </p>
        )}
        {((reminderInfo.repeatOptions.periodicity === "n-week") && (weekDaysList !== null)) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoWeekdays')}`}</span> 
            <span className="reminder-info-value">{weekDaysList}</span>
          </p>
        )}
        {((reminderInfo.repeatOptions.periodicity !== "none") && (weekDaysList !== null)) && (
          <p className="reminder-info">
            <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoRepeat')}`}</span> 
            <span className="reminder-info-value">{`${t('until')}`} {`${formatDate(timestampToDate(reminderInfoModal.repeatOptions.repeatBefore), "P", {locale: locales[i18n.language]})}` }</span>
          </p>
        )}
      </>
    );
  }

  function renderWeekdayCheckbox(day) {
    return (
      <label className="checkbox-label">
        {`${t(day)}`}
        <input 
          defaultChecked={reminderWizardModal.reminder.repeatOptions.weekdays.includes(day)} 
          onClick={ (e) => {
            const checked = e.target.checked;
            if(checked) {
              if(!reminderWizardModal.reminder.repeatOptions.weekdays.includes(day)) {
                let newWeekdays = cloneDeep(reminderWizardModal.reminder.repeatOptions.weekdays);
                newWeekdays.push(day);
                setReminderWizardModal((cur) => {
                  cur.reminder.repeatOptions.weekdays = newWeekdays;
                  return cloneDeep(cur);
                  }
                )
              }
            } else {
              if(reminderWizardModal.reminder.repeatOptions.weekdays.includes(day)) {
                const newWeekdays = reminderWizardModal.reminder.repeatOptions.weekdays.filter((el) => {
                  return el !== day;
                });
                setReminderWizardModal((cur) => {
                  cur.reminder.repeatOptions.weekdays = newWeekdays;
                  return cloneDeep(cur);
                  }
                )
              }
            }
          }}
          type="checkbox"
        />
        <span className="checkmark"></span>
      </label>
    );
  }

  const periodicityOptions = [
    {value: {periodicity: "none",   type: 0}, label: `${t('remindersAdminPage.repetitionNone')}`},
    {value: {periodicity: "n-day",  type: 0}, label: `${t('remindersAdminPage.repetitionDaily')}`},
    {value: {periodicity: "n-day",  type: 1}, label: `${t('remindersAdminPage.repetitionNDays')}`},
    {value: {periodicity: "n-week", type: 0}, label: `${t('remindersAdminPage.repetitionWeekly')}`},
    {value: {periodicity: "n-week", type: 1}, label: `${t('remindersAdminPage.repetitionNWeeks')}`},
    {value: {periodicity: "monthly", type: 0}, label: `${t('remindersAdminPage.repetitionMonthly')}`},
    {value: {periodicity: "monthly", type: 1}, label: `${t('remindersAdminPage.repetitionNMonths')}`},
    {value: {periodicity: "yearly", type: 0}, label: `${t('remindersAdminPage.repetitionYearly')}`},
    {value: {periodicity: "yearly", type: 1}, label: `${t('remindersAdminPage.repetitionNYears')}`}
  ];

  function renderPeriodicitySelection() {
    const { 
      //periodicity, 
      intervalLabel, 
      //interval,
      month,
      dayOfMonth,
      holidayRepetition,
      //weekDaysList
    } = decodePeriodicity(reminderWizardModal.reminder);

    return (
      <>
        <div className="reminder-info">
          <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoPeriodicity')}`}</label>
          <Select
            // className="dropdown-select"
            // classNamePrefix="select"
            defaultValue={periodicityOptions.find(
                opt => ((opt.value.periodicity === reminderWizardModal.reminder.repeatOptions.periodicity) && 
                  (opt.value.type === reminderWizardModal.periodicityType))
              )
            }
            isSearchable={false}
            maxMenuHeight={150}
            name="language"
            onChange={(option) => {
              setReminderWizardModal((cur) => {
                cur.reminder.repeatOptions.periodicity = option.value.periodicity; 
                cur.periodicityType = option.value.type; 
                if(option.value.type === 0) {
                  cur.reminder.repeatOptions.period = 1;
                } else if(cur.reminder.isNew) {
                  cur.reminder.repeatOptions.period = 1;
                }
                if(option.value.periodicity === 'n-week') {
                  if(null_or_undefined(cur.reminder.repeatOptions.weekdays)) {
                      cur.reminder.repeatOptions.weekdays = [];
                  }
                } 
                return cloneDeep(cur)})
              }
            }
            options={periodicityOptions}
            placeholder={t('remindersAdminPage.filterSelectIcon')}
          />
        </div>
        {((reminderWizardModal.periodicityType !== 0) && (intervalLabel !== null)) && (
          <>
            {(reminderWizardModal.periodicityType !== 0) && (
              <div className="reminder-info">
                <label className="reminder-info-field">{intervalLabel}</label>
                <input 
                  className="reminder-dialog-input" 
                  defaultValue={reminderWizardModal.reminder.repeatOptions.period} 
                  id="interval" 
                  max={999}
                  min={1} 
                  name="timeout"
                  onChange={(e) => {
                      const interval = e.target.value;
                      setReminderWizardModal((cur) => {
                        cur.reminder.repeatOptions.period = parseInt(interval);
                        return cloneDeep(cur);
                      });
                    }
                  } 
                  size={4}
                  type="number"
                />
              </div>
            )}
          </>
        )}
        {((reminderWizardModal.reminder.repeatOptions.periodicity === 'monthly') || (reminderWizardModal.reminder.repeatOptions.periodicity === 'yearly')) && (
            <div className="reminder-info">
              <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoDayMonth')}`}</label>
              <input 
                className="reminder-dialog-input" 
                defaultValue={dayOfMonth} 
                id="interval" 
                max={getDaysInMonth(reminderWizardModal.reminder.repeatOptions.month, 2000)}
                min={1} 
                name="timeout"
                onChange={(e) => {
                    const newDayOfMonth = e.target.value;
                    setReminderWizardModal((cur) => {
                      cur.reminder.repeatOptions.day = parseInt(newDayOfMonth);
                      return cloneDeep(cur);
                    });
                  }
                } 
                size={4}
                type="number"
              />
            </div>
        )}
        {(reminderWizardModal.reminder.repeatOptions.periodicity === 'yearly') && (
            <div className="reminder-info">
              <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMonth')}`}</label>
              <input 
                className="reminder-dialog-input" 
                defaultValue={month} 
                id="interval" 
                max={12}
                min={1} 
                name="timeout"
                onChange={(e) => {
                    const newMonth = e.target.value;
                    setReminderWizardModal((cur) => {
                      cur.reminder.repeatOptions.month = parseInt(newMonth);
                      return cloneDeep(cur);
                    });
                  }
                } 
                size={4}
                type="number"
              />
            </div>
        )}
        {(reminderWizardModal.reminder.repeatOptions.periodicity !== 'none') && (
          <div className="reminder-info">
            <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoHolidays')}`}</label>
            <Select
              // className="dropdown-select"
              // classNamePrefix="select"
              defaultValue={
                (not_null_or_undefined(reminderWizardModal.reminder.repeatOptions.repeatOnHoliday)) ? 
                {
                  value: reminderWizardModal.reminder.repeatOptions.repeatOnHoliday,
                  label: holidayRepetition
                } : 
                {
                  value: "yes",
                  label: `${t('Yes')}`
                }
              }
              isSearchable={false}
              name="repetition-on-holidays"
              onChange={(option) => {setReminderWizardModal((cur) => {
                  cur.reminder.repeatOptions.repeatOnHoliday = option.value; 
                  return cloneDeep(cur)})
                }
              }
              options={[
                {value: "yes", label: `${t('Yes')}`},
                {value: "no", label: `${t('No')}`},
                {value: "postpone", label: `${t('remindersAdminPage.repetitionPostpone')}`},
                {value: "anticipate", label: `${t('remindersAdminPage.repetitionAnticipate')}`},
              ]}
              placeholder={t('remindersAdminPage.filterSelectIcon')}
            />
          </div>
        )}
        {(reminderWizardModal.reminder.repeatOptions.periodicity === 'n-week') && (
          // <div className="reminder-dialog-full-width">
          <>
            <div className="week-days-checks" style={{gridColumn: "1 / 2"}}>
              {renderWeekdayCheckbox('mon')}
              {renderWeekdayCheckbox('tue')}
              {renderWeekdayCheckbox('wed')}
              {renderWeekdayCheckbox('thu')}
            </div>
            <div className="week-days-checks">
              {renderWeekdayCheckbox('fri')}
              {renderWeekdayCheckbox('sat')}
              {renderWeekdayCheckbox('sun')}
            </div>
          </>
          // </div>
        )}
      </>
    );
  }

  function closeReplyModal() {
    setReplyModal(null);
  }

  function saveReplyModal() {
    if(null_or_undefined(replyModal.optionValue) || (replyModal.optionValue === '')) {
      alert(`${t('remindersAdminPage.errorInvalidOptionValue')}`);
      return;
    }

    if(null_or_undefined(replyModal.optionText) || (replyModal.optionText === '')) {
      alert(`${t('remindersAdminPage.errorInvalidOptionText')}`);
      return;
    }

    if((replyModal.optionTextA === null) || (replyModal.optionTextA === '')) {
      replyModal.optionTextA = replyModal.optionText;
    }

    if(replyModal.isNew) {
      const existingReplyIdx = reminderWizardModal.reminder.replies.findIndex((r) => {
        return (r.optionValue === replyModal.optionValue) || 
          (r.optionText === replyModal.optionText) || 
          (r.optionTextA === replyModal.optionTextA) || 
          ((r.optionIcon !== null) && (r.optionIcon === replyModal.optionIcon));
      });
  
      if(existingReplyIdx !== -1) {
        alert(`${t('remindersAdminPage.errorInvalidOptionValue')}`);
        return;
      }
    } else {
      for(let i = 0; i < reminderWizardModal.reminder.replies.length; ++i) {
        if(reminderWizardModal.reminder.replies[i].optionValue === replyModal.optionValue) {
          if(replyModal.optionValue !== replyModal.originalOptionValue) {
            alert(`${t('remindersAdminPage.errorInvalidOptionValue')}`);
            return;
          }
        }
        if((reminderWizardModal.reminder.replies[i].optionText === replyModal.optionText) && 
          (reminderWizardModal.reminder.replies[i].optionValue !== replyModal.originalOptionValue)) {
          alert(`${t('remindersAdminPage.errorInvalidOptionValue')}`);
          return;
        }
        if((reminderWizardModal.reminder.replies[i].optionTextA === replyModal.optionTextA) && 
          (reminderWizardModal.reminder.replies[i].optionValue !== replyModal.originalOptionValue)) {
          alert(`${t('remindersAdminPage.errorInvalidOptionValue')}`);
          return;
        }
        if((not_null_or_undefined(replyModal.optionIcon) && (replyModal.optionIcon !== "")) && 
          (reminderWizardModal.reminder.replies[i].optionIcon === replyModal.optionIcon) && 
          (reminderWizardModal.reminder.replies[i].optionValue !== replyModal.originalOptionValue)) {
          alert(`${t('remindersAdminPage.errorInvalidOptionIcon')}`);
          return;
        }
      }
    }   

    if(replyModal.optionIcon === "") {
      replyModal.optionIcon = null;
    }

    if(replyModal.isNew) {
      let newReminder = cloneDeep(reminderWizardModal.reminder);
      newReminder.replies.push({...replyModal, isNew: null, originalOptionValue: null});
      setReminderWizardModal((cur) => {
        return {...cur, reminder: newReminder};
      });
    } else {
      const replyIdx = reminderWizardModal.reminder.replies.findIndex((r) => {
        return r.optionValue === replyModal.originalOptionValue;
      });
      let newReminder = cloneDeep(reminderWizardModal.reminder);
      newReminder.replies[replyIdx] = {...replyModal, originalOptionValue: null};
      setReminderWizardModal((cur) => {
        return {...cur, reminder: newReminder};
      });
    }

    setReplyModal(null);
  }

  function renderReplyModal() {
    if(replyModal === null) {
      return;
    }

    if((replyModal.isNew !== true) && (null_or_undefined(replyModal.originalOptionValue))) {
      replyModal.originalOptionValue = replyModal.optionValue;
    }

    return(
      <Modal
        onClose={closeReplyModal}
        open={replyModal !== null}
      >
        <>
          <div className="reminder-options-dialog-container">
            <h3 className="reminder-dialog-title reminder-dialog-full-width">
              {`${t('remindersAdminPage.reminderReplyModalTitle')}`}
              <hr/>
            </h3>
            <div className="reminder-info">
              <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoRepliesTableOptionCode')}`}</label>
              <input 
                className="reminder-dialog-input" 
                defaultValue={replyModal.optionValue} 
                name="optionValue"
                onChange={(e) => {
                  const value = e.target.value;
                  setReplyModal(cur => ({...cur, optionValue: value}));
                  }
                } 
                size={10}
                type="text"
              />
            </div>
            <div className="reminder-info">
              <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoRepliesTableOption')}`}</label>
              <input 
                className="reminder-dialog-input" 
                defaultValue={replyModal.optionText} 
                name="optionValue"
                onChange={(e) => {
                  const value = e.target.value;
                  setReplyModal(cur => ({...cur, optionText: value}));
                  }
                } 
                size={10}
                type="text"
              />
            </div>
            <div className="reminder-info">
              <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoRepliesTableOptionA')}`}</label>
              <input 
                className="reminder-dialog-input" 
                defaultValue={replyModal.optionTextA} 
                name="optionValue"
                onChange={(e) => {
                  const value = e.target.value;
                  setReplyModal(cur => ({...cur, optionTextA: value}));
                  }
                } 
                size={10}
                type="text"
              />
            </div>
            <div className="reminder-info-centered">
              <label className="reminder-info-field" htmlFor="image-wizard">{`${t('remindersAdminPage.reminderInfoImage')}`}</label>
              <br/>
              <label>
                <AsyncSelect
                  cacheOptions
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultOptions
                  defaultValue={(not_null_or_undefined(replyModal.optionIcon)) ? 
                    {
                      value: '', 
                      label: <div>{`${t('none_F')}`}</div>
                    } : 
                    {
                      value: replyModal.optionIcon, 
                      label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+replyModal.optionIcon} width="64px"/></div>
                    }
                  }
                  isClearable={false}
                  isSearchable={false}
                  loadOptions={imagesOptions}
                  maxMenuHeight={150}
                  menuPlacement="top"
                  name="image-wizard"
                  onChange={(option) => {
                    setReplyModal(cur => ({...cur, optionIcon: option === null ? null : option.value}));
                  }}
                  placeholder={t('remindersAdminPage.filterSelectIcon')}
                  styles={customStyles}
                />
              </label>
            </div>
            <p className="reminder-info-full-width"></p>
            <button 
              className="cbutton reminder-dialog-action-button cancel-button" 
              onClick={() => {closeReplyModal()}} 
              type="button"
            >
              {`${t('CANCEL')}`}
            </button>
            <button 
              className="cbutton reminder-dialog-action-button ok-button" 
              onClick={() => {saveReplyModal()}} 
              type="button"
            >
              {`${t('OK')}`}
            </button>
          </div>
        </>
      </Modal>
    );
  }

  function renderRepliesOptionsSelections()
  {
    function setRepliesType(newType) {
      setReminderWizardModal((cur) => {
        switch(newType) {
          case 0:
            cur.reminder.replies = [{optionValue: 'OK', optionText: `${t('OK')}`, optionTextA: `${t('OK')}`, optionIcon: null}];
            break;
          case 1:
            cur.reminder.replies = [
              {optionValue: 'NO', optionText: `${t('NO')}`, optionTextA: `${t('NO')}`, optionIcon: null}, 
              {optionValue: 'YES', optionText: `${t('YES')}`, optionTextA: `${t('YES')}`, optionIcon: null}
            ];
            break;
          case 2:
            cur.reminder.replies = [
              {optionValue: '1', optionText: '1', optionTextA: '1', optionIcon: null}, 
              {optionValue: '2', optionText: '2', optionTextA: '2', optionIcon: null}, 
              {optionValue: '3', optionText: '3', optionTextA: '3', optionIcon: null}, 
              {optionValue: '4', optionText: '4', optionTextA: '4', optionIcon: null}, 
              {optionValue: '5', optionText: '5', optionTextA: '5', optionIcon: null}
            ];
            break;
          case 3:
            cur.reminder.replies = [
              {optionValue: '1', optionText: '1', optionTextA: '1', optionIcon: null}, 
              {optionValue: '2', optionText: '2', optionTextA: '2', optionIcon: null}, 
              {optionValue: '3', optionText: '3', optionTextA: '3', optionIcon: null}, 
              {optionValue: '4', optionText: '4', optionTextA: '4', optionIcon: null}, 
              {optionValue: '5', optionText: '5', optionTextA: '5', optionIcon: null},
              {optionValue: '6', optionText: '6', optionTextA: '6', optionIcon: null}, 
              {optionValue: '7', optionText: '7', optionTextA: '7', optionIcon: null}, 
              {optionValue: '8', optionText: '8', optionTextA: '8', optionIcon: null}, 
              {optionValue: '9', optionText: '9', optionTextA: '9', optionIcon: null}, 
              {optionValue: '10', optionText: '10', optionTextA: '10', optionIcon: null}
            ];
            break;
          case 4:
            cur.reminder.replies = [];
            break;
          default:
            break;
        }
        return cloneDeep(cur);
      });
    }

    const curReplyType = reminderWizardModal.reminder !== null ? decodeRepliesType(reminderWizardModal.reminder.replies) : 0;
    
    return (
      <>
        <div className="reminder-info">
          <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoReplies')}`}</label>
          <Select
            // className="dropdown-select"
            // classNamePrefix="select"
            defaultValue={curReplyType}
            isSearchable={false}
            maxMenuHeight={150}
            name="language"
            onChange={option => setRepliesType(option.value)}
            options={[
              {value: 0, label: `${t('remindersAdminPage.replyTypeConfirmOnly')}`},
              {value: 1, label: `${t('remindersAdminPage.replyTypeYesNo')}`},
              {value: 2, label: `${t('remindersAdminPage.replyType1to5')}`},
              {value: 3, label: `${t('remindersAdminPage.replyType1to10')}`},
              {value: 4, label: `${t('remindersAdminPage.replyTypeCustom')}`}
            ]}
            placeholder={t('remindersAdminPage.filterSelectIcon')}
          />
        </div>
        {(decodeRepliesType(reminderWizardModal.reminder.replies).value === 4) && (
          <>
            <button 
              className="cbutton reminder-dialog-action-button" 
              id="add-reply-button" 
              onClick={() => setReplyModal({...newEmptyReply})}
              type="button"
            >
              {`${t('remindersAdminPage.addReplyButtonLabel')}`}
            </button>
            {renderReplyModal()}
            <div className="reminder-table-container reminder-dialog-full-width">
              <table className="reminder-table">
                <thead>
                  <tr>
                    <th className="reminder-image">
                      {`${t('remindersAdminPage.reminderInfoRepliesTableImage')}`}
                    </th>
                    <th className="reminder-option">
                      {`${t('remindersAdminPage.reminderInfoRepliesTableOption')}`} 
                    </th>
                    <th className="reminder-optiona">
                      {`${t('remindersAdminPage.reminderInfoRepliesTableOptionA')}`}
                    </th>
                    <th className="reminder-code">
                      {`${t('remindersAdminPage.reminderInfoRepliesTableOptionCode')}`}
                    </th>
                    <th className="reminder-details"></th>
                  </tr>
                </thead>
                <tbody>
                  {(reminderWizardModal.reminder.replies.length === 0) && (
                      <tr >
                        <td className="reminder-image"></td>
                        <td className="reminder-option">{`${t('remindersAdminPage.addReplies')}`}</td>
                        <td className="reminder-optiona"></td>
                        <td className="reminder-code"></td>
                        <td className="reminder-details"></td>
                      </tr>
                )}
                  {reminderWizardModal.reminder.replies.map((replyOpt) => {
                    return (
                      <tr key={replyOpt.optionValue}>
                        <td className="reminder-image">
                        {(not_null_or_undefined(replyOpt.optionIcon)) && (
                          <img alt="" src={IMAGE_BASE_URL+replyOpt.optionIcon}/>)}
                        </td>
                        <td className="reminder-option">{replyOpt.optionText}</td>
                        <td className="reminder-optiona">{replyOpt.optionTextA}</td>
                        <td className="reminder-code">{replyOpt.optionValue}</td>
                        <td className="reminder-details">
                          <FontAwesomeIcon 
                            className="clickable" 
                            icon={faTrashAlt} 
                            onClick={ () => { 
                                setReminderWizardModal((cur) => {
                                  const newReplies = cur.reminder.replies.filter(reply => reply !== replyOpt);
                                  cur.reminder.replies = newReplies;
                                  return cloneDeep(cur);
                                });
                              }
                            } 
                            title={`${t('remindersAdminPage.actionsDelete')}`} 
                          />
                          <FontAwesomeIcon 
                            className="clickable" 
                            icon={faEdit} 
                            onClick={ () => { setReplyModal({...replyOpt}); } } 
                            title={`${t('remindersAdminPage.actionsEdit')}`} 
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  }

  async function decodeActionId(actionId) {
    if(!reminderActions.has(actionId)) {
      const actionInfo = await requestReminderActionDetails(selectedInst, actionId);      
      setReminderActions( (actions) => {actions.set(actionId, actionInfo); return new Map(actions);});
    }
    return reminderActions;
  }

  function decodeLanguage(langCode) {
    switch(langCode) {
      case "it":
        return `${t('languages.italian')}`;
      case "en":
        return `${t('languages.english')}`;
      case "fr":
        return `${t('languages.french')}`;
      case "de":
        return `${t('languages.german')}`;
      default:
        return "unknown";
    }
  }

  function decodeTimeout(seconds, showSeconds, showZeros, extendedTimenames) {
    if(seconds === 0) {
      return `${t('remindersAdminPage.noTimeout')}`;
    }
    const days =  Math.floor(seconds / (60*60*24));
    const hours = Math.floor((seconds - (days * (60*60*24))) / (60*60));
    const minutes = Math.floor((seconds - (days * (60*60*24)) - hours * (60*60)) / 60);
    const secs = (seconds - (days * (60*60*24)) - hours * (60*60) - minutes * 60);
    
    let res = "";
    if(days > 0) {
      if(extendedTimenames) {
        res = `${days} ${days > 1 ? t('days') : t('day')} `;
      } else {
        res = `${days}${t('dayAbbrev')} `;
      }
    }
    if((days > 0) || (hours > 0)) {
      if(showZeros || (hours > 0)) {
        if(extendedTimenames) {
          res = res + `${hours} ${hours > 1 ? t('hours') : t('hour')} `;
        } else {
          res = res + `${hours}${t('hourAbbrev')} `;
        }
      }
    }
    if((days > 0) || (hours > 0) || (minutes > 0)) {
      if(showZeros || (minutes > 0)) {
        if(extendedTimenames) {
          res = res + `${minutes} ${minutes > 1 ? t('minutes') : t('minute')} `;
        } else {
          res = res + `${minutes}${t('minAbbrev')} `;
        }
      }
    }
    if(showSeconds) {
      if(showZeros || (secs > 0)) {
        if(extendedTimenames) {
          res = res + `${secs} ${secs > 1 ? t('seconds') : t('second')} `;
        } else {
          res = res + `${secs}${t('secAbbrev')} `;
        }
      }
    }
    return res.trim();
  }

  function decodeRepliesType(replies) {
    if((replies.length === 1) && (replies[0].optionValue === "OK"))
      return {value: 0, label: `${t('remindersAdminPage.replyTypeConfirmOnly')}`};

    if((replies.length === 2) && (replies[0].optionValue === "NO") && (replies[1].optionValue === "YES"))
        return {value: 1, label: `${t('remindersAdminPage.replyTypeYesNo')}`};

    if((replies.length === 5) && 
      (replies[0].optionValue === "1") && (replies[1].optionValue === "2") && (replies[2].optionValue === "3") && 
      (replies[3].optionValue === "4") && (replies[4].optionValue === "5"))
        return {value: 2, label: `${t('remindersAdminPage.replyType1to5')}`};

    if((replies.length === 10) && 
      (replies[0].optionValue === "1") && (replies[1].optionValue === "2") && (replies[2].optionValue === "3") && 
      (replies[3].optionValue === "4") && (replies[4].optionValue === "5") && (replies[5].optionValue === "6") && 
      (replies[6].optionValue === "7") && (replies[7].optionValue === "8") && (replies[8].optionValue === "9") && 
      (replies[9].optionValue === "10"))
        return {value: 3, label: `${t('remindersAdminPage.replyType1to10')}`};

    return {value: 4, label: `${t('remindersAdminPage.replyTypeCustom')}`};    
  }

  function decodeReplyToString(replies, reply) {
    if(replies == null || reply == null)
      return '-';
    for (const option of replies) {
      if(reply === option.optionValue)
        return option.optionText;
    }
  }

  function decodeUser(userId) {
    const users = cachedRecipients;
    if(users?.has(userId)) {
      return users.get(userId);
    } else {
      return `?${userId}`;
    }
  }

  // function getUsersDescriptions() {
  //   let users = new Map();
  //   selectedInstallationData.contactPerson.forEach( (contact) => {
  //     let desc = `${contact.firstName} ${contact.lastName}`;
  //     if(not_null_or_undefined(contact.organization))
  //       desc = desc + ` (${contact.organization})`;
  //     users.set(contact.userId, desc);
  //   });
  //   return users;
  // }

  async function getUsersDescriptions(remindersResultRecipients) {
    let contacts = await requestReminderRecipients(selectedInst, remindersResultRecipients)
    if(null_or_undefined(contacts)) {
      setCachedRecipients(new Map());
    }
    let users = new Map();
    contacts.forEach( (contact) => {
      let desc = `${contact.firstName} ${contact.lastName}`;
      if(not_null_or_undefined(contact.organization))
        desc = desc + ` (${contact.organization})`;
      if(!contact.push) {
        desc = desc + " (NO PUSH)";
      }
      users.set(contact.userId, desc);
    });
    return setCachedRecipients(users);
  }
  
  function userListToString(usersList) {
    if(usersList == null)
      return `${t('all')}`;
    if(usersList === '*')
      return `${t('all')}`;
    if(Array.isArray(usersList)) {
      if(usersList.length === 0)
        return `${t('all')}`;

      if(usersList[0] === '*')
        return `${t('all')}`;

      const users = cachedRecipients;
      const usersDescs = usersList.map( (user) => {
        let userInt = parseInt(user);
        if(isNaN(userInt)) {
          return user;
        }
        if(users?.has(userInt)) {
            return users.get(userInt);
        } else {
          return '?';
        }
      });

      return usersDescs.join(', ');
    }
    return '?';
  }

  function statusToIcon(status) {
    switch(status) {
      case 'scheduled':
        return (
          <FontAwesomeIcon icon={faClock} title={`${t('remindersAdminPage.stateScheduled')}`}/>
        );

      case 'shown':
        return (
          <FontAwesomeIcon icon={faEye} title={`${t('remindersAdminPage.stateShown')}`}/>
        );
  
      case 'taken':
        return (
          <FontAwesomeIcon icon={faCheck} title={`${t('remindersAdminPage.stateTaken')}`}/>
        );
              
      case 'expired':
        return (
          <FontAwesomeIcon icon={faThumbsDown} title={`${t('remindersAdminPage.stateExpired')}`}/>
        );
      
      case 'canceled':
        return (
          <FontAwesomeIcon icon={faBan} title={`${t('remindersAdminPage.stateCanceled')}`}/>
        );
      
      case 'postponed':
        return (
          <FontAwesomeIcon icon={faRedoAlt} title={`${t('remindersAdminPage.statePostponed')}`}/>
        );
      
      case 'skipped':
        return (
          <FontAwesomeIcon icon={faEyeSlash} title={`${t('remindersAdminPage.stateSkipped')}`}/>
        );

      default:
        return;
    }
  }

  function statusToLabel(status) {
    switch(status) {
      case 'scheduled':
        return (`${t('remindersAdminPage.stateScheduled')}`);

      case 'shown':
        return (`${t('remindersAdminPage.stateShown')}`);
  
      case 'taken':
        return (`${t('remindersAdminPage.stateTaken')}`);
              
      case 'expired':
        return (`${t('remindersAdminPage.stateExpired')}`);
      
      case 'canceled':
        return (`${t('remindersAdminPage.stateCanceled')}`);
      
      case 'postponed':
        return (`${t('remindersAdminPage.statePostponed')}`);
      
      case 'skipped':
        return (`${t('remindersAdminPage.stateSkipped')}`);

      default:
        return;
    }
  }

  function renderStateLegendModal() {
    return (
      <Modal
        onClose={closeStateLegendModal}
        open={showStateLegend}
      >
        <>
          <div className="legend">
            <h3>{`${t('remindersAdminPage.stateLegendTitle')}`}</h3>
            <FontAwesomeIcon icon={faClock} /><span>{`${t('remindersAdminPage.stateScheduled')}`}</span>
            <FontAwesomeIcon icon={faEye} /><span>{`${t('remindersAdminPage.stateShown')}`}</span>
            <FontAwesomeIcon icon={faCheck} /><span>{`${t('remindersAdminPage.stateTaken')}`}</span>
            <FontAwesomeIcon icon={faThumbsDown} /><span>{`${t('remindersAdminPage.stateExpired')}`}</span>
            <FontAwesomeIcon icon={faBan} /><span>{`${t('remindersAdminPage.stateCanceled')}`}</span>
            <FontAwesomeIcon icon={faEyeSlash} /><span>{`${t('remindersAdminPage.stateSkipped')}`}</span>
            <FontAwesomeIcon icon={faRedoAlt} /><span>{`${t('remindersAdminPage.statePostponed')}`}</span>
            <div className="reminder-buttons">
              <button className="cbutton reminder-button" onClick={() => { closeStateLegendModal() }} type="button">{`${t('back')}`}</button>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  function renderReminderInfoModal() {
    if(null_or_undefined(reminderInfoModal))
      return;

    if(not_null_or_undefined(reminderInfoModal.actionOnReminderId))
      decodeActionId(reminderInfoModal.actionOnReminderId);
    if(not_null_or_undefined(reminderInfoModal.actionOnReplyId))
      decodeActionId(reminderInfoModal.actionOnReplyId);
    if(not_null_or_undefined(reminderInfoModal.actionOnTimeoutId))
      decodeActionId(reminderInfoModal.actionOnTimeoutId);

    const decodedRepliesType = decodeRepliesType(reminderInfoModal.replies);

    return (
      <Modal
        onClose={closeReminderInfoModal}
        open={reminderInfoModal !== null}
        styles={{modal: {minWidth: 'min(80%, 900px)'}}}
      >
        <>
          <div className="reminder-dialog-container">
            <h3 className="reminder-dialog-title reminder-dialog-full-width">
              {`${t('remindersAdminPage.reminderInfoTitle')}`} - {statusToLabel(reminderInfoModal.state)}
            </h3>
            <p className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoDate')}`}:</span> 
              <span className="reminder-info-value">{`${formatDate(timestampToDate(reminderInfoModal.timestamp), "Pp", {locale: locales[i18n.language]})}` }</span>
            </p>
            <p className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoTimeout')}`}:</span> 
              <span className="reminder-info-value">{decodeTimeout(reminderInfoModal.timeout, false, false, true)}</span>
            </p>
            <p className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoLanguage')}`}:</span>
              <span className="reminder-info-value">{decodeLanguage(reminderInfoModal.langCode)}</span>
            </p>
            <p className="reminder-info-full-width">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMessage')}`}:</span> 
              <span className="reminder-info-value">{reminderInfoModal.messageText}</span>
            </p>
            <p className="reminder-info-full-width">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMessageA')}`}:</span> 
              <span className="reminder-info-value">{reminderInfoModal.messageTextA}</span>
            </p>
            <div className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoIcon')}`}:</span>
              <br/>
              <img alt="" src={IMAGE_BASE_URL+reminderInfoModal.icon}/>
            </div>
            {(typeof reminderInfoModal.photo === 'string') && (
              <div className="reminder-info">
                <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoImage')}`}:</span>
                <br/>
                <img alt="" src={IMAGE_BASE_URL+reminderInfoModal.photo}/>
              </div>
            )}              
            <div className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoAudio')}`}:</span>
              <span className="reminder-info-value">
                {null_or_undefined(reminderInfoModal.audio) ? `${t('remindersAdminPage.reminderNoAudio')}` : extractFileName(reminderInfoModal.audio)}
              </span>
              {(reminderInfoModal.audio != null) && (
                <div>
                  <FontAwesomeIcon 
                    className="valign2 clickable" 
                    icon={faPlay} 
                    onClick={ () => {
                      const x = document.getElementById("reminder-audio"); 
                      x.play(); 
                    }} 
                    title={`${t('remindersAdminPage.play')}`}
                  />
                  <FontAwesomeIcon 
                    className="valign2 clickable" 
                    icon={faPause} 
                    onClick={ () => {
                      const x = document.getElementById("reminder-audio"); 
                      x.pause(); 
                    }} 
                    title={`${t('remindersAdminPage.pause')}`}
                  />
                  <audio id="reminder-audio">
                    <source src={SOUNDS_BASE_URL+reminderInfoModal.audio} type="audio/wav"/>
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
            {/*<p className="reminder-info">*/}
            {/*  <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoPriority')}`}:</span> */}
            {/*  <span className="reminder-info-value">{reminderInfoModal.priority}</span>*/}
            {/*</p>*/}
            {/*<p className="reminder-info">*/}
            {/*  <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoType')}`}:</span> */}
            {/*  <span className="reminder-info-value">{reminderInfoModal.reminderType}</span>*/}
            {/*</p>*/}

            {renderPeriodicityInfo(reminderInfoModal)}

            <p className="reminder-info">
              <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoReplies')}`}:</span> 
              <span className="reminder-info-value">{decodedRepliesType.label}</span>
            </p>

            {(decodedRepliesType.value === 4) && (
              <div className="reminder-table-container reminder-dialog-full-width">
                <table className="reminder-table">
                  <thead>
                    <tr>
                      <th className="reminder-image">
                        {`${t('remindersAdminPage.reminderInfoRepliesTableImage')}`}
                      </th>
                      <th className="reminder-option">
                        {`${t('remindersAdminPage.reminderInfoRepliesTableOption')}`} 
                      </th>
                      <th className="reminder-optiona">
                        {`${t('remindersAdminPage.reminderInfoRepliesTableOptionA')}`}
                      </th>
                      <th className="reminder-code">
                        {`${t('remindersAdminPage.reminderInfoRepliesTableOptionCode')}`}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reminderInfoModal.replies.map((replyOpt) => {
                      return (
                        <tr key={replyOpt.optionValue}>
                          <td className="reminder-image">
                          {not_null_or_undefined(replyOpt.optionIcon) && (
                            <img alt="" src={IMAGE_BASE_URL+replyOpt.optionIcon}/>)}
                          </td>
                          <td className="reminder-option">{replyOpt.optionText}</td>
                          <td className="reminder-optiona">{replyOpt.optionTextA}</td>
                          <td className="reminder-code">{replyOpt.optionValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {(not_null_or_undefined(reminderInfoModal.recipients)) && (
              <p className="reminder-info">
                <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoRecipients')}`}:</span> 
                <span className="reminder-info-value">{userListToString(reminderInfoModal.recipients)}</span>
              </p>
            )}

            {(typeof reminderInfoModal.actionOnReminderId === "number") && (
              <p className="reminder-info">
                <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnShow')}`}:</span> 
                <span className="reminder-info-value">
                  {reminderActions.has(reminderInfoModal.actionOnReminderId) && (
                    reminderActions.get(reminderInfoModal.actionOnReminderId).label
                  )}                  
                  {!reminderActions.has(reminderInfoModal.actionOnReminderId) && (
                    "..."
                  )}
                </span>
              </p>
            )}
            {(typeof reminderInfoModal.actionOnReplyId === "number") && (
              <p className="reminder-info">
                <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnReply')}`}:</span> 
                <span className="reminder-info-value">
                  {reminderActions.has(reminderInfoModal.actionOnReplyId) && (
                    reminderActions.get(reminderInfoModal.actionOnReplyId).label
                  )}
                  {!reminderActions.has(reminderInfoModal.actionOnReplyId) && (
                    "..."
                  )}
                </span>
              </p>
            )}
            {(typeof reminderInfoModal.actionOnTimeoutId === "number") && (
              <p className="reminder-info">
                <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnTimeout')}`}:</span> 
                <span className="reminder-info-value">
                  {reminderActions.has(reminderInfoModal.actionOnTimeoutId) && (
                    reminderActions.get(reminderInfoModal.actionOnTimeoutId).label
                  )}
                  {!reminderActions.has(reminderInfoModal.actionOnTimeoutId) && (
                    "..."
                  )}
                </span>
              </p>
            )}            
            {(not_null_or_undefined(reminderInfoModal.reply)) && (
              <>
                <p className="reminder-dialog-title reminder-dialog-full-width">
                  <hr/>
                </p>
                <p className="reminder-info" style={{gridColumn: "1 / 2"}}>
                  <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoReply')}`}:</span> 
                  <span className="reminder-info-value">{reminderInfoModal.reply.selectedOptionValue}</span>
                </p>
                <p className="reminder-info">
                  <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoReplyTimestamp')}`}:</span> 
                  <span className="reminder-info-value">{`${formatDate(timestampToDate(reminderInfoModal.reply.timestamp), "Pp", {locale: locales[i18n.language]})}` }</span>
                </p>
                <p className="reminder-info">
                  <span className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoReplyUser')}`}:</span> 
                  <span className="reminder-info-value">{decodeUser(reminderInfoModal.reply.userId)}</span>
                </p>
              </>
            )}
            <div className="reminder-info-full-width">
              <button 
                className="cbutton reminder-dialog-action-button ok-button" 
                onClick={() => {closeReminderInfoModal()}} 
                type="button"
              >
                {`${t('OK')}`}
              </button>
            </div>
          </div>
        </>
      </Modal>
    );
  }
  
  async function iconsOptions() {
    const icons = await requestReminderIcons(selectedInst);
    if(null_or_undefined(icons)) {
      return [];
    }
    const options = icons.map( icon =>
      ({value: icon, label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+icon} width="64px"/></div>})
    );
    return options;
  }  

  async function imagesOptions() {
    const images = await requestReminderImages(selectedInst);
    if(null_or_undefined(images)) {
      return [];
    }
    const options = images.map( (image) => {
      if(image.type === "common") {
        return ({value: image.image, label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+image.image} width="64px"/></div>});
      } else {
        return ({value: image.image, label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+image.image} width="64px"/></div>});
      }
    });
    options.unshift({value: '', label: <div>{`${t('none_F')}`}</div>});

    return options;
  }  

  async function customImagesOptions() {
    const images = await requestReminderImages(selectedInst);
    if(null_or_undefined(images)) {
      setCachedImageOptions([]);
      return [];
    }
    const options = images.map( (image) => {
      if(image.type !== "common") {
        return ({
          value: image.image, 
          label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+image.image} width="64px"/></div>
        });
      } else {
        return null;
      }
    });
    const res = options.filter(e => e != null);
    setCachedImageOptions(res);
    return res;
  }

  async function soundsOptions() {
    const sounds = await requestReminderSounds(selectedInst);
    if(null_or_undefined(sounds)) {
      return [];
    }
    const options = sounds.map( (sound) => {
      if(sound.type === "app") {
        return ({value: sound.sound, label: <div><FontAwesomeIcon icon={faMobileAlt} title={`${t('remindersAdminPage.reminderSoundApp')}`}/> {extractFileName(sound.sound)}</div>});
      } else if(sound.type === "common") {
        return ({value: sound.sound, label: <div><FontAwesomeIcon icon={faBook} title={`${t('remindersAdminPage.reminderSoundCommon')}`}/> {extractFileName(sound.sound)}</div>});
      } else {
        const name = extractFileName(sound.sound).split("-")[1];
        return ({value: sound.sound, label: <div><FontAwesomeIcon icon={faUser} title={`${t('remindersAdminPage.reminderSoundCustom')}`}/> {name}</div>});
      }
    });
    options.unshift({value: 'default', label: <div>{`${t('remindersAdminPage.reminderSoundDefault')}`}</div>});

    return options;
  }  

  async function customSoundsOptions() {
    const sounds = await requestReminderSounds(selectedInst);
    if(null_or_undefined(sounds)) {
      setCachedSoundOptions([]);
      return [];
    }
    const options = sounds.map( (sound) => {
      if((sound.type !== "app") && (sound.type !== "common")) {
        const name = extractFileName(sound.sound).split("-")[1];
        return ({
          value: sound.sound, 
          label: <div><FontAwesomeIcon icon={faUser} title={`${t('remindersAdminPage.reminderSoundCustom')}`}/> {name}</div>
        });
      } else {
        return null;
      }
    });

    const res = options.filter(e => e != null);
    setCachedSoundOptions(res);
    return res;
  }  
  

  function reminderWizardNext() {    
    setReminderWizardModal((cur) => {
      if(cur.page === 1) {
        if(cur.reminder.messageText == null || cur.reminder.messageText === '') {
          alert(`${t('remindersAdminPage.errorMustInputMessage')}`);
          return cur;
        }
        if(cur.reminder.timestamp == null || cur.reminder.timestamp < dateToTimestamp(new Date())) {
          alert(`${t('remindersAdminPage.errorMustInputDateTime')}`);
          return cur;
        }
        if(cur.reminder.icon == null) {
          alert(`${t('remindersAdminPage.errorMustSelectIcon')}`);
          return cur;
        }
      } else if(cur.page === 2) {
        if((reminderWizardModal.reminder.repeatOptions.periodicity !== 'none') && 
          (reminderWizardModal.reminder.repeatOptions.period <= 0)) {
          alert(`${t('remindersAdminPage.errorInvalidPeriod')}`);
          return cur;
        }
        if(((reminderWizardModal.reminder.repeatOptions.periodicity === 'monthly') || 
          (reminderWizardModal.reminder.repeatOptions.periodicity === 'yearly')) && 
          (reminderWizardModal.reminder.repeatOptions.day <= 0)) {
          alert(`${t('remindersAdminPage.errorInvalidDay')}`);
          return cur;
        }
        if((reminderWizardModal.reminder.repeatOptions.periodicity === 'yearly') && 
          (reminderWizardModal.reminder.repeatOptions.month <= 0)) {
          alert(`${t('remindersAdminPage.errorInvalidMonth')}`);
          return cur;
        }
      } else if(cur.page === 3) {
        if(reminderWizardModal.reminder.replies.length === 0) {
          alert(`${t('remindersAdminPage.errorNoReplies')}`);
          return cur;
        }

        let hasImages = 0;
        for(let i = 0; i < reminderWizardModal.reminder.replies.length; ++i) {
          if(not_null_or_undefined(reminderWizardModal.reminder.replies[i].optionIcon) && 
            (reminderWizardModal.reminder.replies[i].optionIcon !== "")) {
            ++hasImages;
          }
        }

        if((hasImages > 0) && (hasImages !== reminderWizardModal.reminder.replies.length)) {
          alert(`${t('remindersAdminPage.errorIncoherentImages')}`);
          return cur;
        }
      } else if(cur.page === 4) {
        if(not_null_or_undefined(cur.reminder.recipients) && (cur.reminder.recipients.length === 0)) {
          alert(`${t('remindersAdminPage.errorMustHaveRecipients')}`);
          return cur;
        }
      }
      return ({...cur, page: (cur.page + 1)});
    });
  }

  const wizardSoundCustomSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      width: '100%',
      // height: 64
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
  //    height: 72
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: '100%',
  //    height: 72
    })
  };

  const langOptions = [
    {value: 'it', label: `${t('languages.italian')}`},
    {value: 'en', label: `${t('languages.english')}`},
    {value: 'fr', label: `${t('languages.french')}`},
    {value: 'de', label: `${t('languages.german')}`}
  ];

  function langOption(lang) {
    return langOptions.find(opt => opt.value === lang);
  }

  async function sendUploadImage() {
    if(null_or_undefined(uploadFileInput)) {
      alert(`${t('remindersAdminPage.selectFile')}`);
      return;
    }
    const res = await postReminderImage(selectedInst, uploadFileInput);
    if(res === void 0) {
      alert(`${t('uploadFailed')}`);
    } else {
      alert(`${t('uploadCompleted')}`);
      setCachedImageOptions(false);
      setUploadFileInput(null);
    }
  }

  async function removeUploadImageSelected() {
    const res = await deleteReminderImage(selectedInst, extractFullFileName(uploadImageSelected));
    if(res === void 0) {
      alert(`${t('deleteFailed')}`);
    } else {
      alert(`${t('deleted')}`);
      setCachedImageOptions(false);
      if(reminderWizardModal.reminder.photo === uploadImageSelected) {
        setReminderWizardModal( (cur) => {
          cur.reminder.photo = null;
          return cloneDeep(cur);
        });
      }      
      setUploadImageSelected(null);
    }
  }

  async function sendUploadAudio() {
    if(null_or_undefined(uploadFileInput)) {
      alert(`${t('remindersAdminPage.selectFile')}`);
      return;
    }
    const res = await postReminderSound(selectedInst, uploadFileInput);
    if(res === void 0) {
      alert(`${t('uploadFailed')}`);
    } else {
      alert(`${t('uploadCompleted')}`);
      setCachedSoundOptions(false);
      setUploadFileInput(null);
    }
  }

  async function removeUploadAudioSelected() {
    const res = await deleteReminderSound(selectedInst, extractFullFileName(uploadAudioSelected));
    if(res === void 0) {
      alert(`${t('deleteFailed')}`);
    } else {
      alert(`${t('deleted')}`);
      setCachedSoundOptions(false);
      if(reminderWizardModal.reminder.audio === uploadAudioSelected) {
        setReminderWizardModal( (cur) => {
          cur.reminder.audio = null;
          return cloneDeep(cur);
        });
      }
      setUploadAudioSelected(null);
    }
  }

  function showUploadModal(type) {
    setUploadModalType(type);
  }

  function closeUploadModal() {
    setUploadModalType(null);
  } 

  useEffect(() => {
    let el = document.getElementById("file-custom");
    if(el !== null) {
      if(uploadFileInput == null) {
        el.setAttribute('data-content', `${t('remindersAdminPage.selectFile')}`);
      } else {
        if(uploadModalType === 'image') {
          if(uploadFileInput.size > MAX_UPLOADABLE_IMAGE_SIZE) {
            alert(`${t('remindersAdminPage.errorFileTooBig')}: ${Math.round(uploadFileInput.size/1024)}k >= ${Math.round(MAX_UPLOADABLE_IMAGE_SIZE/1024)}k`);
            setUploadFileInput(null);
          }
          el.setAttribute('data-content', `${uploadFileInput.name} (${Math.round(uploadFileInput.size/1024)}k)`);
        } else if(uploadModalType === 'sound') {
          if(uploadFileInput.size > MAX_UPLOADABLE_AUDIO_SIZE) {
            alert(`${t('remindersAdminPage.errorFileTooBig')}: ${Math.round(uploadFileInput.size/1024)}k >= ${Math.round(MAX_UPLOADABLE_AUDIO_SIZE/1024)}k`);
            setUploadFileInput(null);
          }
          el.setAttribute('data-content', `${uploadFileInput.name} (${Math.round(uploadFileInput.size/1024)}k)`);
        }
      }
    }
  }, [t, uploadFileInput, uploadModalType]);

  function renderUploadModal() {
    return (
      <Modal
        onClose={closeUploadModal}
        open={uploadModalType !== null}
        styles={{modal: {minWidth: 'min(80%, 900px)', minHeight: '21em'}}}        
      >
        <div className="reminder-dialog-container" style={{gridRowGap: '1em'}}>
          <h3 className="reminder-dialog-title reminder-dialog-full-width">
            {(uploadModalType === 'image') && (`${t('remindersAdminPage.manageCustomImages')}`)}
            {(uploadModalType === 'sound') && (`${t('remindersAdminPage.manageCustomAudio')}`)}
            <hr/>
          </h3>
          <div className="reminder-info-centered reminder-dialog-full-width">
            <label className="file">
              <input 
                accept={uploadModalType === 'image' ? "image/png,image/jpeg" : "audio/wav"} 
                id="file" 
                onChange={e => setUploadFileInput(e.target.files[0])}
                type="file"
              />
              <span className="file-custom" data-content={`${t('remindersAdminPage.selectFile')}`} id="file-custom"></span>
            </label>
            <FontAwesomeIcon 
              className="valign3 clickable" 
              icon={faUpload} 
              onClick={() => {
                if(uploadModalType === 'image')
                  sendUploadImage()
                else if(uploadModalType === 'sound')
                  sendUploadAudio()
              }}
            />
          </div>
          {(uploadModalType === 'image') && (
            <div className="reminder-info-centered reminder-dialog-full-width">
              <label className="reminder-info-field" htmlFor="image-edit">{`${t('remindersAdminPage.reminderInfoImage')}`}</label>
              <br/>
              <FontAwesomeIcon 
                className="valign clickable" 
                icon={faTrashAlt} 
                onClick={() => removeUploadImageSelected()}
              />
              <label>
                <AsyncSelect
                  key={cachedImageOptions}
                  cacheOptions={false}
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultOptions
                  isClearable={false}
                  isSearchable={false}
                  loadOptions={customImagesOptions}
                  maxMenuHeight={150}
                  menuPlacement="top"
                  name="image-wizard"
                  noOptionsMessage={() => t('remindersAdminPage.noCustomImage')}
                  onChange={(option) => {
                    setUploadImageSelected(option.value);
                  }}
                  placeholder={t('remindersAdminPage.filterSelectIcon')}
                  styles={customStyles}
                />
              </label>
            </div>
          )}
          {(uploadModalType === 'sound') && (
            <div className="reminder-info-centered reminder-dialog-full-width">
              <label className="reminder-info-field" htmlFor="sound-wizard" style={{textAlign: "center"}}>{`${t('remindersAdminPage.reminderInfoAudio')}`}</label>
              <br/>
              <FontAwesomeIcon 
                className="valign clickable" 
                icon={faTrashAlt} 
                onClick={() => removeUploadAudioSelected()}
              />
              <label className="audio-selector">
                <AsyncSelect
                  key={cachedSoundOptions}
                  cacheOptions={false}
                  className="dropdown-select"
                  classNamePrefix="select"
                  defaultOptions
                  isClearable={false}
                  isSearchable={false}
                  loadOptions={customSoundsOptions}
                  menuPlacement="top"
                  name="sound-wizard"
                  noOptionsMessage={() => t('remindersAdminPage.noCustomSound')}
                  onChange={(option) => {
                    setUploadAudioSelected(option.value);
                  }}
                  placeholder={t('remindersAdminPage.filterSelectIcon')}
                  styles={wizardSoundCustomSelectStyles}
                />
              </label>
              {((uploadAudioSelected !== null) && (uploadAudioSelected !== 'default')) && (
                <>
                  <FontAwesomeIcon 
                    className="valign2 clickable" 
                    icon={faPlay} 
                    onClick={ () => {
                      const x = document.getElementById("reminder-audio"); 
                      try {
                        x.load(); 
                        x.play(); 
                      } catch(ex) {
                        //ignore exceptions...
                      }
                    }} 
                    title={`${t('remindersAdminPage.play')}`}/>
                  <FontAwesomeIcon 
                    className="valign2 clickable" 
                    icon={faPause} 
                    onClick={ () => {
                      const x = document.getElementById("reminder-audio"); 
                      try {
                        x.pause(); 
                      } catch(ex) {
                        //ignore exceptions...
                      }
                    }} 
                    title={`${t('remindersAdminPage.play')}`}/>
                  <audio id="reminder-audio">
                    <source src={SOUNDS_BASE_URL+uploadAudioSelected} type="audio/wav"/>
                    Your browser does not support the audio element.
                  </audio>
                </>
              )}
              </div>
            )}
          <button 
            className="cbutton reminder-dialog-action-button reminder-dialog-full-width ok-button" 
            onClick={() => {closeUploadModal()}} 
            type="button"
          >
            {`${t('CLOSE')}`}
          </button>
        </div>
      </Modal>
    );
  }

  function renderReminderWizard() {
    if(null_or_undefined(reminderWizardModal.reminder))
      return;

    let recipients = ['*'];
    if(null_or_undefined(reminderWizardModal.reminder.recipients)) {      
      setReminderWizardModal((cur) => {
        cur.reminder.recipients = ['*'];
        return cloneDeep(cur);
      });
    } else {
      recipients = reminderWizardModal.reminder.recipients;
    }
    
    let steps = 4;
    actionOptions().then((options) => {if(options.length > 0) steps = 5;});

    return (
      <Modal
        onClose={closeReminderWizardModal}
        open={reminderWizardModal.reminder !== null}
        styles={{modal: {minWidth: 'min(80%, 900px)', minHeight: '21em'}}}
      >
        <>
          {renderUploadModal()}
          <div className="reminder-wizard-container">
            <h3 className="reminder-dialog-title reminder-dialog-full-width">
              {`${t('remindersAdminPage.reminderSetupTitle')} (${reminderWizardModal.page}/${steps})`}
              <hr/>
            </h3>
            {(reminderWizardModal.page === 1) && (
              <>
                <div className="reminder-info-full-width">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMessage')}`}:</label>
                  <input 
                    className="reminder-dialog-input" 
                    defaultValue={reminderWizardModal.reminder.messageText} 
                    id="timeout" 
                    name="timeout"
                    onChange={(e) => {
                        const value = e.target.value;
                        setReminderWizardModal((cur) => {
                          cur.reminder.messageText = value;
                          return cloneDeep(cur);
                        });
                      }
                    } 
                    type="text"
                  />
                </div>
                <div className="reminder-info-date">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoDate')}`}:</label> 
                  <DatePicker 
                    dateFormat="Pp"
                    locale={i18n.language}
                    minDate={new Date()}
                    onChange={
                      (date) => {
                        setReminderWizardModal( (cur) => {
                          cur.reminder.timestamp = dateToTimestamp(date); 
                          return cloneDeep(cur);
                        })
                      }
                    }
                    selected={(reminderWizardModal.reminder.timestamp > 0) ? 
                      timestampToDate(reminderWizardModal.reminder.timestamp) : null}
                    showDisabledMonthNavigation 
                    showMonthDropdown 
                    showTimeSelect
                    showYearDropdown
                    timeIntervals={15}
                  />
                </div>
                {/* <div className="reminder-info">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoTimeout')}`}:</label> 
                  <input 
                    id="timeout" 
                    name="timeout" 
                    type="text" 
                    className="reminder-dialog-input"
                    defaultValue={decodeTimeout(reminderWizardModal.reminder.timeout)} 
                    onChange={(e) => {
                        const value = e.target.value;
                        setReminderWizardModal((cur) => {
                          cur.reminder.timeout = parseTimeDuration(value);
                          return cloneDeep(cur);
                        });
                      }
                    }
                  />
                </div> */}
                <div className="reminder-info">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoTimeout')}`}:</label> 
                  <Select 
                    defaultValue={{value: reminderWizardModal.reminder.timeout, label: decodeTimeout(reminderWizardModal.reminder.timeout, false, false, true)}} 
                    name="timeout"
                    onChange={(option) => {
                        const value = option.value;
                        setReminderWizardModal((cur) => {
                          cur.reminder.timeout = value;
                          return cloneDeep(cur);
                        });
                      }
                    }
                    options={[
                      {value: parseTimeDuration('00:00:00'), label: decodeTimeout(parseTimeDuration('00:00:00'), false, false, true)},
                      {value: parseTimeDuration('00:01:00'), label: decodeTimeout(parseTimeDuration('00:01:00'), false, false, true)},
                      {value: parseTimeDuration('00:05:00'), label: decodeTimeout(parseTimeDuration('00:05:00'), false, false, true)},
                      {value: parseTimeDuration('00:10:00'), label: decodeTimeout(parseTimeDuration('00:10:00'), false, false, true)},
                      {value: parseTimeDuration('00:15:00'), label: decodeTimeout(parseTimeDuration('00:15:00'), false, false, true)},
                      {value: parseTimeDuration('00:20:00'), label: decodeTimeout(parseTimeDuration('00:20:00'), false, false, true)},
                      {value: parseTimeDuration('00:25:00'), label: decodeTimeout(parseTimeDuration('00:25:00'), false, false, true)},
                      {value: parseTimeDuration('00:30:00'), label: decodeTimeout(parseTimeDuration('00:30:00'), false, false, true)},
                      {value: parseTimeDuration('00:45:00'), label: decodeTimeout(parseTimeDuration('00:45:00'), false, false, true)},
                      {value: parseTimeDuration('01:00:00'), label: decodeTimeout(parseTimeDuration('01:00:00'), false, false, true)},
                      {value: parseTimeDuration('01:30:00'), label: decodeTimeout(parseTimeDuration('01:30:00'), false, false, true)},
                      {value: parseTimeDuration('02:00:00'), label: decodeTimeout(parseTimeDuration('02:00:00'), false, false, true)},
                      {value: parseTimeDuration('03:00:00'), label: decodeTimeout(parseTimeDuration('03:00:00'), false, false, true)},
                      {value: parseTimeDuration('04:00:00'), label: decodeTimeout(parseTimeDuration('04:00:00'), false, false, true)},
                      {value: parseTimeDuration('05:00:00'), label: decodeTimeout(parseTimeDuration('05:00:00'), false, false, true)},
                      {value: parseTimeDuration('06:00:00'), label: decodeTimeout(parseTimeDuration('06:00:00'), false, false, true)},
                      {value: parseTimeDuration('09:00:00'), label: decodeTimeout(parseTimeDuration('09:00:00'), false, false, true)},
                      {value: parseTimeDuration('12:00:00'), label: decodeTimeout(parseTimeDuration('12:00:00'), false, false, true)},
                      {value: parseTimeDuration('24:00:00'), label: decodeTimeout(parseTimeDuration('24:00:00'), false, false, true)},
                      {value: parseTimeDuration('48:00:00'), label: decodeTimeout(parseTimeDuration('48:00:00'), false, false, true)}
                    ]}
                  />
                </div>
                <div className="reminder-info"></div>
                <div className="reminder-info-centered">
                  <label className="reminder-info-field" htmlFor="icon-wizard">{`${t('remindersAdminPage.reminderInfoIcon')}`}:</label>
                  <br/>
                  <label>
                    <AsyncSelect
                      cacheOptions
                      className="dropdown-select"
                      classNamePrefix="select"
                      defaultOptions
                      defaultValue={reminderWizardModal.reminder.icon == null ? '-' :
                        {
                          value: reminderWizardModal.reminder.icon, 
                          label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+reminderWizardModal.reminder.icon} width="64px"/></div>
                        }
                      }
                      isClearable={false}
                      isSearchable={false}
                      loadOptions={iconsOptions}
                      menuPlacement="top"
                      name="icon-wizard"
                      onChange={(option) => {
                        setReminderWizardModal((cur) => {
                          cur.reminder.icon = (option == null) ? null : option.value;
                          return cloneDeep(cur);
                        });
                      }}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={customStyles}
                    />
                  </label>
                </div>
                <div className="reminder-info-centered">
                  <label className="reminder-info-field" htmlFor="image-wizard">{`${t('remindersAdminPage.reminderInfoImage')}`}</label>
                  <br/>
                  <FontAwesomeIcon 
                    className="valign clickable" 
                    icon={faUpload} 
                    onClick={() => showUploadModal('image')}
                  />
                  <label>
                    <AsyncSelect
                      key={cachedImageOptions}
                      cacheOptions={false}
                      className="dropdown-select"
                      classNamePrefix="select"
                      defaultOptions
                      defaultValue={reminderWizardModal.reminder.photo == null ?
                        {
                          value: '', 
                          label: <div>{`${t('none_F')}`}</div>
                        } : 
                        {
                          value: reminderWizardModal.reminder.photo, 
                          label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+reminderWizardModal.reminder.photo} width="64px"/></div>
                        }
                      }
                      isClearable={false}
                      isSearchable={false}
                      loadOptions={imagesOptions}
                      menuPlacement="top"
                      name="image-wizard"
                      onChange={(option) => {
                        setReminderWizardModal((cur) => {
                          cur.reminder.photo = (option === null) ? null : option.value;
                          return cloneDeep(cur);
                        });
                      }}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                      styles={customStyles}
                    />
                  </label>
                </div>
                <h3 className="reminder-dialog-title reminder-dialog-full-width">
                  {`${t('remindersAdminPage.reminderWizardAdvanced')}`}
                  <hr/>
                </h3>
                <div className="reminder-info-full-width">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoMessageA')}`}</label>
                  <input
                      className="reminder-dialog-input"
                      defaultValue={reminderWizardModal.reminder.messageTextA}
                      id="timeout"
                      name="timeout"
                      onChange={(e) => {
                        const value = e.target.value;
                        setReminderWizardModal((cur) => {
                          cur.reminder.messageTextA = value;
                          return cloneDeep(cur);
                        });
                      }
                      }
                      type="text"
                  />
                </div>
                <div className="reminder-info">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoLanguage')}`}:</label>
                  <Select
                      // className="dropdown-select"
                      // classNamePrefix="select"
                      defaultValue={langOption(reminderWizardModal.reminder.langCode)}
                      isSearchable={false}
                      name="language"
                      onChange={(option) => {setReminderWizardModal((cur) => {
                        cur.reminder.langCode = option.value;
                        return cloneDeep(cur)})
                      }
                      }
                      options={langOptions}
                      placeholder={t('remindersAdminPage.filterSelectIcon')}
                  />
                </div>
                <div className="reminder-info">
                  <label className="reminder-info-field" htmlFor="sound-wizard" style={{textAlign: "center"}}>{`${t('remindersAdminPage.reminderInfoAudio')}`}</label>
                  <br/>
                  <AsyncSelect
                    key={cachedSoundOptions}
                    cacheOptions={false}
                    className="dropdown-select"
                    classNamePrefix="select"
                    defaultOptions
                    defaultValue={reminderWizardModal.reminder.audio == null ? {
                        value: 'default', 
                        label: <div>{`${t('remindersAdminPage.reminderSoundDefault')}`}</div>
                      } : 
                      {
                        value: reminderWizardModal.reminder.audio, 
                        label: <div>{extractFileName(reminderWizardModal.reminder.audio)}</div>
                      }
                    }
                    isClearable={false}
                    isSearchable={false}
                    loadOptions={soundsOptions}
                    menuPlacement="top"
                    name="sound-wizard"
                    onChange={(option) => {
                      setReminderWizardModal((cur) => {
                        cur.reminder.audio = (option === null) ? null : option.value;
                        return cloneDeep(cur);
                      });
                    }}
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={wizardSoundCustomSelectStyles}
                  />
                  {/*<FontAwesomeIcon */}
                  {/*  className="valign2 clickable" */}
                  {/*  icon={faUpload} */}
                  {/*  onClick={() => showUploadModal('sound')}*/}
                  {/*/>*/}
                  {((reminderWizardModal.reminder.audio != null) && (reminderWizardModal.reminder.audio !== 'default')) && (
                    <>
                      <FontAwesomeIcon 
                        className="valign2 clickable" 
                        icon={faPlay} 
                        onClick={ () => {
                          const x = document.getElementById("reminder-audio"); 
                          try {
                            x.load(); 
                            x.play(); 
                          } catch(ex) {
                            //ignore exceptions...
                          }
                        }} 
                        title={`${t('remindersAdminPage.play')}`}/>
                      <FontAwesomeIcon 
                        className="valign2 clickable" 
                        icon={faPause} 
                        onClick={ () => {
                          const x = document.getElementById("reminder-audio"); 
                          try {
                            x.pause(); 
                          } catch(ex) {
                            //ignore exceptions...
                          }
                        }} 
                        title={`${t('remindersAdminPage.play')}`}/>
                      <audio id="reminder-audio">
                        <source src={SOUNDS_BASE_URL+reminderWizardModal.reminder.audio} type="audio/wav"/>
                        Your browser does not support the audio element.
                      </audio>
                    </>
                  )}
                </div>
                {/*<div className="reminder-info">*/}
                {/*  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoPriority')}`}:</label> */}
                {/*  <input */}
                {/*    className="reminder-dialog-input" */}
                {/*    defaultValue={reminderWizardModal.reminder.priority === null ? 0 : reminderWizardModal.reminder.priority} */}
                {/*    id="priority" */}
                {/*    name="priority"*/}
                {/*    onChange={(e) => {*/}
                {/*        const value = e.target.value;*/}
                {/*        setReminderWizardModal((cur) => {*/}
                {/*          cur.reminder.priority = parseInt(value);*/}
                {/*          return cloneDeep(cur);*/}
                {/*        });*/}
                {/*      }*/}
                {/*    } */}
                {/*    type="number"*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<div className="reminder-info">*/}
                {/*  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoType')}`}:</label> */}
                {/*  <input */}
                {/*    className="reminder-dialog-input" */}
                {/*    defaultValue={reminderWizardModal.reminder.reminderType === null ? 0 : reminderWizardModal.reminder.reminderType} */}
                {/*    id="type" */}
                {/*    name="type"*/}
                {/*    onChange={(e) => {*/}
                {/*        const value = e.target.value;*/}
                {/*        setReminderWizardModal((cur) => {*/}
                {/*          cur.reminder.reminderType = parseInt(value);*/}
                {/*          return cloneDeep(cur);*/}
                {/*        });*/}
                {/*      }*/}
                {/*    } */}
                {/*    type="number"*/}
                {/*  />*/}
                {/*</div>*/}
              </>
            )}
            {(reminderWizardModal.page === 2) && (
              <>
                {renderPeriodicitySelection(reminderWizardModal.reminder)}
              </>
            )}
            {(reminderWizardModal.page === 3) && (
              <>
                {renderRepliesOptionsSelections()}
              </>
            )}
            {(reminderWizardModal.page === 4) && (
              <>
                <div className="reminder-dialog-selection reminder-dialog-full-width" id="recipient-selection">
                  <p className="reminder-standalone-label">{`${t('remindersAdminPage.inviareA')}`}</p>
                  <label className="radio-label">
                    <input 
                      defaultChecked={recipients === null ||
                        (recipients.length > 0 || recipients[0] === '*')} 
                      id="all-recipients" 
                      name="recipient-group" 
                      onClick={(e) => {
                          setReminderWizardModal((cur) => {
                            cur.reminder.recipients = ['*'];
                            return cloneDeep(cur);
                            }
                          )
                        }
                      } 
                      type="radio"
                      value="*"
                    /> {`${t('All')}`}
                    <span className="checkmark-radio"></span>
                  </label>
                  <label className="radio-label">
                    <input 
                      defaultChecked={recipients !== null && (recipients.length === 0 || 
                        (recipients.length > 0 && recipients[0] !== '*'))} 
                      id="some-recipient" 
                      name="recipient-group" 
                      onClick={(e) => {
                          setReminderWizardModal((cur) => {
                            cur.reminder.recipients = [];
                            return cloneDeep(cur);
                            }
                          )
                        }
                      }
                      type="radio"
                      value="0"
                    /> {`${t('remindersAdminPage.radioSelect')}`}
                    <span className="checkmark-radio"></span>
                  </label>
                </div>
                {(recipients !== null && (recipients.length === 0 || 
                  (recipients.length > 0 && recipients[0] !== '*'))) && (
                  <div className="reminder-action-users-check-list reminder-dialog-full-width">
                    {renderUsersCheckboxes()}
                  </div>
                )}
              </>
            )}
            {(reminderWizardModal.page === 5) && (
              <>
                <div className="reminder-info reminder-dialog-full-width">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnShow')}`}:</label>
                  <AsyncSelect
                    // className="dropdown-select"
                    // classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    defaultValue={decodeActionOption(reminderActions.get(reminderWizardModal.reminder.actionOnShowId))}
                    isClearable={true}
                    isSearchable={true}
                    loadOptions={actionOptions}
                    name="action-on-show"
                    onChange={(option) => {setReminderWizardModal((cur) => {
                        if(null_or_undefined(option)){
                          cur.reminder.actionOnShowId = null; 
                        } else {
                          cur.reminder.actionOnShowId = option.value; 
                        }
                        return cloneDeep(cur)})
                      }
                    }
                    placeholder={t('none_F')}
                  />
                </div>
                <div className="reminder-info reminder-dialog-full-width">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnTimeout')}`}:</label>
                  <AsyncSelect
                    // className="dropdown-select"
                    // classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    defaultValue={decodeActionOption(reminderActions.get(reminderWizardModal.reminder.actionOnTimeoutId))}
                    isClearable={true}
                    isSearchable={true}
                    loadOptions={actionOptions}
                    name="action-on-timeout"
                    onChange={(option) => {setReminderWizardModal((cur) => {
                        if(null_or_undefined(option)){
                          cur.reminder.actionOnTimeoutId = null; 
                        } else {
                          cur.reminder.actionOnTimeoutId = option.value; 
                        }
                        return cloneDeep(cur)})
                      }
                    }
                    placeholder={t('none_F')}
                  />
                </div>
                <div className="reminder-info reminder-dialog-full-width">
                  <label className="reminder-info-field">{`${t('remindersAdminPage.reminderInfoActionOnReply')}`}:</label>
                  <AsyncSelect
                    // className="dropdown-select"
                    // classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    defaultValue={decodeActionOption(reminderActions.get(reminderWizardModal.reminder.actionOnReplyId))}
                    isClearable={true}
                    isSearchable={true}
                    loadOptions={actionOptions}
                    name="action-on-show"
                    onChange={(option) => {setReminderWizardModal((cur) => {
                        if(null_or_undefined(option)) {
                          cur.reminder.actionOnReplyId = null; 
                        } else {
                          cur.reminder.actionOnReplyId = option.value; 
                        }
                        return cloneDeep(cur)})
                      }
                    }
                    placeholder={t('none_F')}
                  />
                </div>
              </>
            )}
            {(reminderWizardModal.page > 1) && (
              <>
                <button 
                  className="cbutton reminder-dialog-action-button cancel-button"
                  onClick={() => {setReminderWizardModal(cur => ({...cur, page: (cur.page - 1)}));}} 
                  style={{gridColumn: "1 / 2"}} 
                  type="button"
                >
                  {`${t('BACK')}`}
                </button>
              </>
            )}
            <button 
              className="cbutton reminder-dialog-action-button erase-button"
              onClick={() => {closeReminderWizardModal()}} 
              style={(reminderWizardModal.page === 1) ? {gridColumn: "1 / 2"} : {}} 
              type="button"
            >
              {`${t('CANCEL')}`}
            </button>
            {(reminderWizardModal.page < steps) && (
              <>
                <button 
                  className="cbutton reminder-dialog-action-button ok-button" 
                  onClick={() => {reminderWizardNext()}} 
                  type="button"
                >
                    {`${t('NEXT')}`}
                </button>
              </>
            )}
            {(reminderWizardModal.page === steps) && (
              <>
                <button 
                  className="cbutton reminder-dialog-action-button end-button" 
                  onClick={() => { setShowSaving(true); saveReminderWizardModal().then(() => {setShowSaving(false)})
                  }}
                  type="button"
                >
                    {`${t('END')} `}
                  {(showSaving) && (
                      <FontAwesomeIcon icon={faSpinner} spin title={t('Saving')}/>
                  )}
                </button>
              </>
            )}
            </div>
        </>
      </Modal>
    );
  }

  const asyncLoadInstReminders = useCallback(async function () {
    const rems = await loadInstReminders(selectedInst, remindersFilter.returnExpired, remindersFilter.returnFuture, 
      remindersFilter.startDateInterval, remindersFilter.endDateInterval);
    setRemindersFilter( opts => ({...opts, requestedStartDate:opts.startDateInterval, requestedEndDate:opts.endDateInterval}));
    let newIconList = [];
    let newTypeList = [];
    if(rems) {
      rems.forEach((reminder) => {
        if(newIconList.indexOf(reminder.icon) === -1)
        newIconList.push(reminder.icon);
        if(newTypeList.indexOf(reminder.type) === -1)
        newTypeList.push(reminder.type);
      });
      newIconList.sort();
      newTypeList.sort();
      setRemindersFilter( cur => ({...cur, iconList: newIconList, typeList: newTypeList}));
      setReminders(rems);
    } else {
      setRemindersFilter( cur => ({...cur, iconList: newIconList, typeList: newTypeList}));
      setReminders(null);
      alert("Interval server error");
    }
    //setRemindersFilter({...remindersFilter});
    setRequestingReminders(false);
  }, [loadInstReminders, remindersFilter.endDateInterval, remindersFilter.returnExpired, remindersFilter.returnFuture, remindersFilter.startDateInterval, selectedInst]);

  const isRemindersListValid = useCallback( function () {
    return (selectedInst !== null && reminders instanceof Map);
  }, [reminders, selectedInst]);

  function reminderShowOnClickHandler(reminderId)
  {
    reminderInfoOnClickHandler(reminders.get(reminderId));
  }

  async function reminderDeleteOnClickHandler(reminderId)
  {
    confirmAlert({
      title: `${t('remindersAdminPage.confirmDeletion')}`,
      buttons: [
        {
          label: `${t('Yes')}`,
          onClick: async () => {
            const res = await deleteReminder(selectedInst, reminderId);
            if(res === true) {
              let newReminderList = new Map();
              reminders.forEach((reminder) => {
                if(reminder.id !== reminderId)
                  newReminderList.set(reminder.id, reminder);
              });
              setReminders(newReminderList);
            } else {
              alert(res);
            }
          },
        },
        {
          label: `${t('No')}`,
        },
      ],
    });
  }

  function reminderEditOnClickHandler(reminderId)
  {
    let reminder = cloneDeep(reminders.get(reminderId));
    const periodicityType = (not_null_or_undefined(reminder.repeatOptions) && (reminder.repeatOptions.period > 1)) ? 1 : 0;
    setReminderWizardModal({reminder: reminder, page: 1, periodicityType: periodicityType});
  }

  useEffect(() => {
    if( (selectedInst !== null) && (!(reminders instanceof Map)) && (!requestingReminders)) {
      setRequestingReminders(true);
      asyncLoadInstReminders();
    } else {
      if((remindersFilter.startDateInterval !== null) && ((remindersFilter.requestedStartDate === null) ||
        (remindersFilter.requestedStartDate > remindersFilter.startDateInterval))) {
        setReminders(null);
      }
      if((remindersFilter.endDateInterval !== null) && ((remindersFilter.requestedEndDate === null) ||
        (remindersFilter.requestedEndDate < remindersFilter.endDateInterval))) {
        setReminders(null);
      }
    }
  }, [remindersFilter, asyncLoadInstReminders, requestingReminders, selectedInst, reminders]);

  function handleFilterShowPast() {
    setReminders(null);
    setRemindersFilter(
      (current) => {
        return {...current,
          returnExpired: !current.returnExpired, 
          returnFuture: current.returnFuture
        };
      }
    );
  }

  function handleFilterShowFuture() {
    setReminders(null);
    setRemindersFilter(
      (current) => {
        return {...current, 
          returnExpired: current.returnExpired, 
          returnFuture: !current.returnFuture
        };
      }
    );
  }

  function isReminderEditable(reminder) {
    switch(reminder.state) {
      case 'scheduled':
      case 'shown':
        return true;
      default:
        return false;
    }
  }

  function reminderRow(reminder)
  {
    if(null_or_undefined(reminder))
      return; 

    return(
      <tr key={reminder.id}>
        <td className="reminder-icon"><img alt="" src={IMAGE_BASE_URL+reminder.icon}></img></td>
        <td className="reminder-message">{reminder.messageText}</td>
        <td className="reminder-users">{userListToString(reminder.recipients || null)}</td>
        <td className="reminder-date">{timestampToLocalDateString(reminder.timestamp)} {timestampToLocalTimeString(reminder.timestamp)}</td>
        {/*<td className="reminder-type">{reminder.type || '-'}</td>*/}
        <td className="reminder-status">{statusToIcon(reminder.state)}</td>
        <td className="reminder-reply">{decodeReplyToString(reminder?.replies, reminder?.reply?.selectedOptionValue)}</td>
        <td className="reminder-details">
          <FontAwesomeIcon 
            className="clickable" 
            icon={faInfoCircle} 
            onClick={ () => reminderShowOnClickHandler(reminder.id)} 
            title={`${t('remindersAdminPage.reminderInfo')}`} 
          />
          {(isReminderEditable(reminder)) && (
            <FontAwesomeIcon 
              className="clickable" 
              icon={faTrashAlt} 
              onClick={ () => reminderDeleteOnClickHandler(reminder.id)} 
              title={`${t('remindersAdminPage.reminderDelete')}`} 
            />
          )}
          {(isReminderEditable(reminder)) && (
            <FontAwesomeIcon 
              className="clickable" 
              icon={faEdit} 
              onClick={ () => reminderEditOnClickHandler(reminder.id)} 
              title={`${t('remindersAdminPage.reminderEdit')}`} 
            />
          )}
        </td>
      </tr>
    );
  }

  const orderBy = useCallback(function (field) {
    let newOpts = {...reminderListOptions};
    if(reminderListOptions.orderByField === field) {
      newOpts.orderDirectionAsc = !reminderListOptions.orderDirectionAsc;
    } else {
      newOpts.orderByField = field;
      newOpts.orderDirectionAsc = true;
    }
    setReminderListOptions(newOpts);
  }, [reminderListOptions]);

  function filterReminders(reminderList, remindersFilterInfo, refs) {
    return refs.filter((ref) => {
      if(remindersFilterInfo.icon !== null) {
        if(reminderList.get(ref.id).icon !== remindersFilterInfo.icon.value) {
          return false;
        }
      }
      if(remindersFilterInfo.type !== null && remindersFilterInfo.type !== '*') {
        if(reminderList.get(ref.id).type !== remindersFilterInfo.type) {
          return false;
        }
      }
      if(remindersFilterInfo.state !== null && remindersFilterInfo.state !== '*') {
        if(reminderList.get(ref.id).state !== remindersFilterInfo.state) {
          return false;
        }
      }
      if(remindersFilterInfo.startDateInterval !== null) {
        if(reminderList.get(ref.id).timestamp < remindersFilterInfo.startDateInterval) {
          return false;
        }
      }
      if(remindersFilterInfo.endDateInterval !== null) {
        if(reminderList.get(ref.id).timestamp > remindersFilterInfo.endDateInterval) {
          return false;
        }
      }
      return true;
    });
  }

  function generateRemindersRefs(reminderList, orderByField, orderDirectionAsc) {
    let newRefs = [];
    switch(orderByField) {
      case 'icon':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.icon});
        });
      break;
      case 'message':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.messageText});
        });
      break;
      case 'users':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.recipients});
        });
      break;
      case 'date':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.timestamp});
        });
      break;
      case 'type':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.type});
        });
      break;
      case 'status':
        reminderList.forEach((reminder) => {
          newRefs.push({id: reminder.id, key: reminder.state});
        });
      break;
      case 'reply':
        reminderList.forEach((reminder) => {
          if(reminder.reply != null)
            newRefs.push({id: reminder.id, key: reminder.reply});
          else
            newRefs.push({id: reminder.id, key: ''});
        });
      break;
      default:          
    }
    newRefs.sort((a, b) => {
      if(orderDirectionAsc) {
        if(a.key < b.key)
          return -1;
        if(a.key > b.key)
          return 1;
      } else {
        if(a.key < b.key)
          return 1;
        if(a.key > b.key)
          return -1;
      }
      return 0;
    });
    return newRefs;
  }

  useEffect( () => {
    if(isRemindersListValid()) {
      const refs = generateRemindersRefs(reminders, reminderListOptions.orderByField, reminderListOptions.orderDirectionAsc);
      setOrderedReminderRefs(filterReminders(reminders, remindersFilter, refs));
    } else {
      return setOrderedReminderRefs(refs => null);
    }
  }, [isRemindersListValid, reminderListOptions.orderByField, reminderListOptions.orderDirectionAsc, reminders, remindersFilter]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 104,
      // height: 64
    }),
    control: (provided, state) => ({
      ...provided,
      width: 150,
      height: 72
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 150,
      height: 72
    }),
    singleValue: (provided, state) => ({
      ...provided,
      width: '100%'
    })
  };

  function OpenAddReminder() {
    if(cachedRecipients === null) {
      alert(t('remindersAdminPage.errorLoadingInfo'));
      return;
    }

    if(cachedRecipients.size === 0) {
      alert(t('remindersAdminPage.errorNoRecipients'))
      return
    }

    setReminderWizardModal({reminder: cloneDeep(newEmptyReminder), page: 1, periodicityType: 0});
  }

  return (
    <>
      <HeaderSection 
        currRoute={currRoute} 
        mainContentRef={mainContentRef} 
      />
      <section className="main-content" ref={mainContentRef}>
        {selectedInstallationData && (
          <>
            <Alerts alerts={selectedInstallationData.alerts} installationStatus={selectedInstallationData.status} />
            <div className="reminder-filter-div">
              <h1 className="main-content-header">{`${t('remindersAdminPage.filterHeader')}`}</h1>
              <hr/>
              <div className="reminder-filter">
              <div className="reminder-filter-control" id="icon-filter">
                <div className="select-label iconselect-label">{`${t('remindersAdminPage.filterLabelIcon')}`} </div>
                <label htmlFor="icon">
                  <Select
                    className="dropdown-select"
                    classNamePrefix="select"
                    defaultValue={"-"}
                    isClearable={true}
                    isSearchable={false}
                    name="icon"
                    onChange={(option) => {setRemindersFilter((filterOptions) => {
                        filterOptions.icon = option; 
                        return {...filterOptions}})
                      }
                    }
                    options={                        
                      remindersFilter.iconList.map((icon) => {
                        return {
                          value: icon, label: <div><img alt="" height="64px" src={IMAGE_BASE_URL+icon} width="64px"/></div>
                        };
                      })
                    }
                    placeholder={t('remindersAdminPage.filterSelectIcon')}
                    styles={customStyles}
                  />
                </label>
              </div>
              <div className="reminder-filter-control" id="type-filter">
                <div className="select-label">{`${t('remindersAdminPage.filterLabelType')}`} </div>
                <div className="select-control">
                  <select 
                    id="type-selection"
                    onChange={(e) => {
                      const value = e.target.value;
                      setRemindersFilter((filter) => {
                        if(value === '-')
                          filter.type = null;
                        else
                          filter.type = value;
                        return {...filter};
                      });
                    }}
                    value={remindersFilter.type}
                  >
                    <option key="*" value="*">{`${t('remindersAdminPage.filterLabelAll')}`}</option>
                    {remindersFilter.typeList.map((type) => {
                          return <option key={type || '-'} value={type}>{type || '-'}</option>
                        })}
                  </select>
                </div>
              </div>
              <div className="reminder-filter-control" id="status-filter">
                <div className="select-label">{`${t('remindersAdminPage.filterLabelStatus')}`} </div>
                <div className="select-control">
                  <select 
                    id="state-selection"
                    onChange={(e) => {
                      const value = e.target.value;
                      setRemindersFilter((filter) => {
                        filter.state = value;
                        return {...filter};
                      });
                    }}
                    value={remindersFilter.state}
                  >
                    <option value="*">{`${t('remindersAdminPage.filterLabelAll')}`}</option>
                    <option value="scheduled">{`${t('remindersAdminPage.filterLabelScheduled')}`}</option>
                    <option value="expired">{`${t('remindersAdminPage.filterLabelExpired')}`}</option>
                    <option value="shown">{`${t('remindersAdminPage.filterLabelShown')}`}</option>
                    <option value="taken">{`${t('remindersAdminPage.filterLabelTaken')}`}</option>
                    <option value="canceled">{`${t('remindersAdminPage.filterLabelCanceled')}`}</option>
                    <option value="postponed">{`${t('remindersAdminPage.filterLabelPostponed')}`}</option>
                    <option value="skipped">{`${t('remindersAdminPage.filterLabelSkipped')}`}</option>
                  </select>
                </div>
              </div>
              <div className="reminder-filter-control" id="date-filter">
                <span className="date-label">{`${t('remindersAdminPage.filterFrom')}`}</span>
                <DatePicker 
                  dateFormat="P"
                  endDate={timestampToDate(remindersFilter.endDateInterval)}
                  locale={i18n.language}
                  minDate={new Date('2020-01-01')} 
                  onChange={
                    (date) => {
                      setRemindersFilter( (curFilter) => {
                        curFilter.startDateInterval = dateToTimestamp(date); 
                        return {...curFilter}
                      })
                    }
                  } 
                  selected={(remindersFilter.startDateInterval !== null && remindersFilter.startDateInterval > 0) ? timestampToDate(remindersFilter.startDateInterval) : null}
                  selectsStart
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showYearDropdown
                  startDate={timestampToDate(remindersFilter.startDateInterval)}
                /><br/>
                <span className="date-label" style={{marginTop:'10px', display:'inline-block'}}>{` ${t('remindersAdminPage.filterTo')}`}</span>
                <DatePicker 
                  dateFormat="P"
                  endDate={timestampToDate(remindersFilter.endDateInterval)}
                  locale={i18n.language}
                  minDate={timestampToDate(remindersFilter.startDateInterval)} 
                  onChange={
                    (date) => {
                      setRemindersFilter( (curFilter) => {
                        curFilter.endDateInterval = dateToTimestamp(date); 
                        return {...curFilter}
                      })
                    }
                  } 
                  selected={(remindersFilter.endDateInterval !== null && remindersFilter.endDateInterval > 0) ? timestampToDate(remindersFilter.endDateInterval) : null}
                  selectsEnd
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showYearDropdown
                  startDate={timestampToDate(remindersFilter.startDateInterval)}
                />
              </div>
              <div className="reminder-filter-control" id="past-filter">
                <label className="checkbox-label" htmlFor="show-past-ckb">
                  <input 
                    defaultChecked={remindersFilter.returnExpired} 
                    id="show-past-ckb" 
                    name="show-past-ckb"
                    // onChange={(e) => {handleFilterShowPast()}}
                    onClick={(e) => {handleFilterShowPast()}}
                    // checked={remindersFilter.returnExpired}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  {`${t('remindersAdminPage.showPastFilter')}`}
                </label>
              </div>
              <div className="reminder-filter-control" id="future-filter">
                <label className="checkbox-label" htmlFor="show-future-ckb">
                  <input 
                    defaultChecked={remindersFilter.returnFuture} 
                    id="show-future-ckb"
                    name="show-future-ckb"                    
                    // onChange={(e) => {handleFilterShowFuture()}}
                    onClick={(e) => {handleFilterShowFuture()}}
                    // checked={remindersFilter.returnFuture}
                    type="checkbox"
                  />
                  {`${t('remindersAdminPage.showFutureFilter')}`}
                  <span className="checkmark"></span>
                </label>
              </div>            
              {/* <button 
                type="button" 
                id="filter-button" 
                className="cbutton reminder-button reminder-filter-control"
                onClick={() => filterReminders()}
              >
                {`${t('remindersAdminPage.filterButton')}`}
              </button> */}
            </div>
            </div>
            <div className="main-inner-div">
              <h1 className="main-content-header">
                <span>{`${t("remindersAdminPage.title")}`}</span>
                <Link className="reminder-actions-control" to={SITE_LINKS.REMINDER_ACTIONS_ADMIN}>
                  <button 
                    className="cbutton reminder-button" 
                    id="reminder-actions-button" 
                    type="button"
                  >
                    {`${t('remindersAdminPage.actionsButton')}`}
                  </button>
                </Link>

                {(hasCapability(selectedInstallationData, 'reminders')) && (
                <button 
                  className="cbutton reminder-button reminder-add-control" 
                  id="add-button" 
                  onClick={() => {OpenAddReminder()}}
                  type="button"
                >
                  {`${t('ADD')}`}
                </button>)}
              </h1>
              <hr/>
              {renderStateLegendModal()}
              {renderReminderInfoModal()}
              {renderReminderWizard()}
              {(orderedReminderRefs !== null) && isRemindersListValid() && (
                <>
                  <div className="reminder-table-container">
                    <table className="reminder-table">
                      <thead>
                        <tr>
                          <th className="reminder-icon" onClick={() => orderBy('icon')}>
                            {`${t('remindersAdminPage.reminderTableIcon')}`}
                            {reminderListOptions.orderByField === 'icon' && reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'icon' && !reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-message" onClick={() => orderBy('message')}>
                            {`${t('remindersAdminPage.reminderTableMessage')}`}
                            {reminderListOptions.orderByField === 'message' && reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'message' && !reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-users" onClick={() => orderBy('users')}>
                            {`${t('remindersAdminPage.reminderTableUsers')}`}
                            {reminderListOptions.orderByField === 'users' && reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'users' && !reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-date" onClick={() => orderBy('date')}>
                            {`${t('remindersAdminPage.reminderTableDate')}`}
                            {reminderListOptions.orderByField === 'date' && reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'date' && !reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          {/*<th className="reminder-type" onClick={() => orderBy('type')}>*/}
                          {/*  {`${t('remindersAdminPage.reminderTableType')}`}*/}
                          {/*  {reminderListOptions.orderByField === 'type' && reminderListOptions.orderDirectionAsc && (*/}
                          {/*    <FontAwesomeIcon icon={faLongArrowAltUp} />*/}
                          {/*  )}*/}
                          {/*  {reminderListOptions.orderByField === 'type' && !reminderListOptions.orderDirectionAsc && (*/}
                          {/*    <FontAwesomeIcon icon={faLongArrowAltDown} />*/}
                          {/*  )}*/}
                          {/*</th>*/}
                          <th className="reminder-status" onClick={() => orderBy('status')}>
                            <FontAwesomeIcon icon={faBook} onClick={(e) => {e.stopPropagation(); setShowStateLegend(true);}} title={`${t('remindersAdminPage.stateLegendTitle')}`} />
                            {`${t('remindersAdminPage.reminderTableStatus')}`}
                            {reminderListOptions.orderByField === 'status' && reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'status' && !reminderListOptions.orderDirectionAsc && (
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-reply" onClick={() => orderBy('reply')}>
                            {`${t('remindersAdminPage.reminderTableReply')}`}
                            {reminderListOptions.orderByField === 'reply' && reminderListOptions.orderDirectionAsc && (
                                <FontAwesomeIcon icon={faLongArrowAltUp} />
                            )}
                            {reminderListOptions.orderByField === 'reply' && !reminderListOptions.orderDirectionAsc && (
                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                            )}
                          </th>
                          <th className="reminder-action-actions"></th>
                        </tr>
                      </thead>
                      <tbody>              
                        {orderedReminderRefs.map((reminderRef) => {
                          if(reminders.has(reminderRef.id))
                            return reminderRow(reminders.get(reminderRef.id));
                          return null;
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              )} 
              {((orderedReminderRefs === null) || !isRemindersListValid()) && (
                <p>{`${t("remindersAdminPage.loadingMessage")}`}</p>
              )} 
            </div>
          </>
        )}
      </section>
      <FooterSection/>
    </>
  )
}
