import React from 'react';
import {InstallationsContext} from "./App";
import {ProgrammedCallEntry} from "./ProgrammedCallEntry";
import {useTranslation} from "react-i18next";

export default function ProgrammedCalls(props) {
  const {
    title,
    setJournalModalType,
    setProgrammedCall,
    standalone,
  } = props;
  const {
    programmedCalls
  } = React.useContext(InstallationsContext);
  const {t} = useTranslation();

  // console.log(programmedCalls);

  programmedCalls.calls.sort((a, b) => {
    const valA = a?.caseData?.nextCall;
    const valB = b?.caseData?.nextCall;
    if (valA < valB) {
      return -1;
    }
    if (valA > valB) {
      return 1;
    }
    return 0;
  });

  return (
    <div  className={standalone ? 'programmed-calls-div' : 'programmed-calls-div-embedded'}>
      <h1 className="programmed-calls-header">
        {title}
      </h1>
      <hr/>
      <div className="programmed-calls">
        {programmedCalls.calls?.map?.((entry, idx) => {
          // console.log(entry);
          return <ProgrammedCallEntry
              key={entry.installationSerial + entry.id}
              entry={entry}
              idx={idx}
              setJournalModalType={setJournalModalType}
              setProgrammedCall={setProgrammedCall}
          />
        })}
        {!programmedCalls.loaded && (<div>
          <p>{t('statusPage.loadingMessage')}</p>
        </div>)}
        {programmedCalls.loaded && programmedCalls.calls?.length === 0 && (<div>
          <p>{t('statusPage.noProgrammedCalls')}</p>
        </div>)}
      </div>
    </div>
  );
}
