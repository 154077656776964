import FooterSection from "./FooterSection";
import StaticBanner from "./StaticBanner";
import HeaderSection from "./HeaderSection";
import {timestampToDate} from "../utilities";
import {INSTALLATION_ALERT_STATUS_DISCONNECTED} from "../api/wimhome-api";
import {useTranslation} from "react-i18next";
import React from "react";
import {InstallationsContext, MqttContext} from "./App";
import cloneDeep from "lodash/cloneDeep";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEnvelope, faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";

import '../css/reports.css';
import dateAdd from "date-fns/add";
import dateSub from "date-fns/sub";

export default function ReportsPage({ currRoute }) {
  const { t , i18n } = useTranslation();
  let mainContentRef = React.createRef();
  const {
    selectedInst,
    installations,
    installationReports,
    setInstallationReports,
    downloadInstReport,
    countUnreadReports
  } = React.useContext(InstallationsContext);

  const { mqttStatus } = React.useContext(MqttContext);
  const selectedInstallationData =
      selectedInst && installations ? installations[selectedInst] : null;

  async function downloadReport(id) {
    try {
      const res = await downloadInstReport(selectedInst, id);
      if(res !== true) {
        alert(t('reports.cannotDownload'));
        return;
      }
      setInstallationReports((cur) => {
        let newReps = cloneDeep(cur);
        for(let repTypeIdx in newReps) {
          for(let rep of newReps[repTypeIdx].reports) {
            if(rep.id === id) {
              rep.shown = true;
            }
          }
        }
        return newReps
      })
    } catch(ex) {
      console.log(ex)
      alert(t('reports.cannotDownload'));
    }
  }

  return (
      <>
        <HeaderSection
            currRoute={currRoute}
            mainContentRef={mainContentRef}
            mqttStatus={mqttStatus}
            selectedInst={selectedInst}
        />
        <section className="main-content" ref={mainContentRef} style={{ lineHeight: 1 }}>
          {selectedInstallationData?.status === INSTALLATION_ALERT_STATUS_DISCONNECTED && (
              <StaticBanner
                  backgroundColor='#d9534f'
                  color='white'
                  icon='exclamation-triangle'
                  message={`${t('statusPage.disconnectionAlertBanner')} ${timestampToDate(
                      selectedInstallationData?.lastStatusUpdate
                  ).toLocaleString(i18n.language)}`}
              />
          )}
          {selectedInst !== null && (
              <div className="main-inner-div">
                <div className="content-wrap">
                  {installationReports && installationReports.map((report, reportId) => {
                    return (
                        <div key={reportId}>
                          <h2>{`${report.name} [${countUnreadReports(report.name)}]`}</h2>
                          <hr/>
                          <div>
                            <table className={'reports-table'}>
                              <thead>
                              <tr>
                                <th>{t('reports.read')}</th>
                                <th>{t('reports.startDate')}</th>
                                <th>{t('reports.endDate')}</th>
                                <th>{t('reports.download')}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {report.reports.map((r) => {
                                let rEnd =  timestampToDate(r.end)
                                if(rEnd.getHours() === 0 && rEnd.getMinutes() === 0 && rEnd.getSeconds() === 0)
                                  rEnd = dateSub(rEnd, { days: 1 })
                                return <tr key={r.id}>
                                  {!r.shown && <td className={'icon'} title={t('reports.notRead')}><FontAwesomeIcon icon={faEnvelope}/></td>}
                                  {r.shown && <td className={'icon'} title={t('reports.read')}><FontAwesomeIcon icon={faEnvelopeOpen}/></td>}
                                  <td className={'date'}>{`${timestampToDate(r.start).toLocaleDateString(i18n.language)}`}</td>
                                  <td className={'date'}>{`${rEnd.toLocaleDateString(i18n.language)}`}</td>
                                  <td
                                      className={'icon download'}
                                      onClick={() => downloadReport(r.id)}
                                      title={t('reports.download')}
                                  ><FontAwesomeIcon icon={faDownload}/></td>
                                </tr>
                              })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                    )
                  })}
                </div>
              </div>
          )}
        </section>
        <FooterSection />
      </>
  );
}