import React, { useState, useEffect, useCallback, useRef } from 'react';
import JournalEntry from './JournalEntry';
import Select from 'react-select';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {GenericContext, InstallationsContext} from './App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCaretLeft, faCaretRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import {
  CASE_DESCRIPTION_TYPE_PROG_CALL, JOURNAL_ENTRY_TYPE_CAREGIVER,
  JOURNAL_ENTRY_TYPE_NORMAL,
  JOURNAL_ENTRY_TYPE_PROG_CALL, JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED, JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED
} from "../api/wimhome-api";
import {dateToTimestamp, timestampToDate} from "../utilities";
import dateAdd from "date-fns/add";
import {confirmAlert} from "react-confirm-alert";

//Used by react-datepicker
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

export default function JournalInterface({type, showInstallationTitle, standalone, setType, onlyProgrammedCalls}) {
  const JOURNAL_PROG_CALL_BG_COLOR = '#33ccff'

  const {t, i18n} = useTranslation();

  const {
    userType
  } = React.useContext(GenericContext);

  const { 
    selectedInst,
    installations,
    loadAllInstallationData,
    loadInstJournal,
    loadInstJournalCount,
    createInstJournalEntry,
    reloadProgrammedCalls,
  } = React.useContext(InstallationsContext);

  class JournalEntryData {
    constructor() {
      this.id = null;
      this.type = JOURNAL_ENTRY_TYPE_NORMAL;
      this.title = "";
      this.description = "";
      this.data = null;
    }
  }

  const [journalModal, setJournalModal] = useState(null);
  const [installationJournal, setInstallationJournal] = useState(new Map());
  const [latestFirst, setLatestFirst] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [startDateTime/*, setStartDateTime*/] = useState(null);
  const [endDateTime/*, setEndDateTime*/] = useState(null);
  const [cannotChangeParams, setCannotChangeParams] = useState(false);
  const [deviceInfos, setDeviceInfos] = useState(new Map())
  const [onlyProgCalls, setOnlyProgCalls] = useState(onlyProgrammedCalls === true);

  const journalLoaded = useRef(false);

  useEffect(() => {
    if (selectedInst == null) {
      return;
    }

    loadAllInstallationData(selectedInst, true, true, false).then((data) => {
      setDeviceInfos(data);
    });
  }, [loadAllInstallationData, selectedInst]);

  useEffect(() => {
    if (selectedInst == null) {
      if (deviceInfos?.size > 0) {
        setDeviceInfos(new Map());
      }
    }
  }, [selectedInst, deviceInfos]);

  function handleAddNewJournalEntry() {
    setType('create');
    setJournalModal(new JournalEntryData());
  }

  const loadJournal = useCallback(() => 
  {
    return loadInstJournal(selectedInst, latestFirst, offset, limit, startDateTime, endDateTime, onlyProgCalls)
    .then((retrievedJournal) => {
      setInstallationJournal(retrievedJournal);
      journalLoaded.current = true;
      setCannotChangeParams(false);
      if(offset === 0) {
        if(retrievedJournal?.size === limit) {
          return loadInstJournalCount(selectedInst, startDateTime, endDateTime, onlyProgCalls);
        } else {
          return retrievedJournal?.size;
        }
      } else {
        return null;
      }
    })
    .then((number) => {
      if(number !== null) {
        setCount(number);
      }
    });    
  }, [endDateTime, latestFirst, limit, loadInstJournal, loadInstJournalCount, offset, selectedInst, startDateTime, onlyProgCalls]);

  useEffect(() => {
    if(selectedInst == null) {
      return;
    }
    if(journalLoaded.current === false)
      loadJournal();
  }, [loadJournal, type, selectedInst]);

  function handleLimitChange(option) {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setLimit(option.value); 
    setOffset(0);
  }

  function handleToggleLatestFirst() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setLatestFirst(cur => !cur);
  }

  function handleToggleOnlyProgCalls() {
    journalLoaded.current = false;
    setCannotChangeParams(true);
    setOnlyProgCalls(cur => !cur);
    setOffset(0)
  }

  function handleFirstPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(0);
  }

  function handlePrevPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(cur => (cur - limit));
  }

  function handleNextPage() {
    journalLoaded.current = false; 
    setCannotChangeParams(true);
    setOffset(cur => (cur + limit));
  }

  //Journal Modal
  function closeJournalModal() {
    setJournalModal(null);
    if(standalone === true)
      setType('closing');
    else
      setType('closed');
  }

  const requestJournalAddition = useCallback((entry) => {
    createInstJournalEntry(selectedInst, entry)
    .then(() => {
      return loadJournal();
    })
    .then(() => {
      setType('show');
    });
  }, [createInstJournalEntry, loadJournal, selectedInst, setType]);

  return (
    <Modal
      closeOnEsc={false}
      onClose={closeJournalModal}
      open={type !== 'closed'}
      styles={{modal: {minWidth: 'min(80%, 900px)', maxHeight: '90vh'}}}
    >
      {
        installationJournal !== null && type === 'show' && (
          <div className="journal-modal-container">
            <h1 className="journal-title">
              <span>{t('journal.journalTitle') + ((showInstallationTitle === true) ? (" " + installations[selectedInst].title) : '')}</span>
              <span className="add-journal-icon-label" onClick={ () => {handleAddNewJournalEntry();} }>
                {t('journal.add')} <FontAwesomeIcon className="add-journal-icon" icon={faPlusCircle}/>
              </span>
            </h1>
            <hr/>
            <div className="journal-navigation">
              <div>
                <label className="checkbox-label show-latest-journal-checkbox" htmlFor="show-latest-cases-checkbox">
                  <input
                      defaultChecked={latestFirst}
                      disabled={cannotChangeParams}
                      id="show-latest-cases-checkbox"
                      name="show-latest-cases-checkbox"
                      onChange={handleToggleLatestFirst}
                      type="checkbox"
                  />
                  {t('alerts.showLatestCasesFirst')}
                  <span className="checkmark"/>
                </label>
              </div>
              <div>
                <label className="checkbox-label show-prog-call-only-checkbox" htmlFor="show-prog-call-only-checkbox">
                  <input
                      defaultChecked={onlyProgCalls}
                      disabled={cannotChangeParams}
                      id="show-prog-call-only-checkbox"
                      name="show-prog-call-only-checkbox"
                      onChange={handleToggleOnlyProgCalls}
                      type="checkbox"
                  />
                  {t('journal.showOnlyProgCalls')}
                  <span className="checkmark"/>
                </label>
              </div>
              <div>
                {(offset >= limit) && (
                    <button
                        className="cbutton prev-page-bt"
                        disabled={cannotChangeParams}
                        onClick={handleFirstPage}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </button>
                )}
                {(offset >= limit) && (
                    <button
                        className="cbutton prev-page-bt"
                        disabled={cannotChangeParams}
                        onClick={handlePrevPage}
                    >
                      <FontAwesomeIcon icon={faCaretLeft}/>
                    </button>
                )}
                <span
                    className="cases-page-position"
                >
                  {`${(count === 0) ? '0' : (offset + 1)}-${((offset + limit) < count) ? (offset + limit) : count} ${t('of')} ${count}`}
                </span>
                {((offset + limit) < count) && (
                    <button
                        className="cbutton next-page-bt"
                        disabled={cannotChangeParams}
                        onClick={handleNextPage}
                    >
                      <FontAwesomeIcon icon={faCaretRight}/>
                    </button>
                )}
              </div>
              <div>
                <label className="select-label show-cases-limit">
                  <div className="select-control show-cases-limit">
                    <Select
                        // className="dropdown-select"
                        // classNamePrefix="select"
                        isDisabled={cannotChangeParams}
                        isSearchable={false}
                        name="page-elements-limit"
                        onChange={handleLimitChange}
                        options={[
                          {value: 10, label: '10'},
                          {value: 20, label: '20'},
                          {value: 30, label: '30'},
                          {value: 50, label: '50'}
                        ]}
                        value={{value: limit, label: `${limit}`}}
                    />
                  </div>
                  <span className="page-size-suffix">{`${t('perPage')}`}</span>
                </label>
              </div>
            </div>
            {(installationJournal instanceof Map && installationJournal.size > 0) && (
                <div className="journal-entry-list">
                  {
                    Array.from(installationJournal.values()).map((entry) => {
                      return <JournalEntry
                          key={entry.id}
                          authorColor={entry.type === CASE_DESCRIPTION_TYPE_PROG_CALL ? JOURNAL_PROG_CALL_BG_COLOR : entry.userColor}
                          authorIcon={entry.userIconUrl}
                          authorName={entry.userFirstName}
                          authorOrg={entry.userOrganization}
                          authorSurname={entry.userLastName}
                      caseDesc={entry.description ? entry.description : null}
                      dateTime={entry.dateTimeUtc}
                      deviceInfo={
                        deviceInfos.has(entry?.entryDeviceId)
                            ? deviceInfos.get(entry?.entryDeviceId)
                            : null
                      }
                      entry={entry.entryDescription ? entry.entryDescription : (entry?.caseData?.result ? (entry?.caseData?.result === JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED ? t('journal.hasReplied') : t('journal.hasNotReplied')) : null)}
                      nextCall={entry?.caseData?.nextCall}
                      progCallHasReply={entry?.caseData?.result}
                      title={entry.title}
                    />
                  })
                }
              </div>
            )}
            {(!(installationJournal instanceof Map) || installationJournal.size === 0) && (
                <p>{t('journal.noEntries')}</p>
            )}
          </div>
        )
      }
      {
        journalModal !== null && type === 'create' && (
          <>
            <h1>{t('journal.journalTitleNew')}</h1>
            <hr/>
            <form className="journal-add-entry-form">
              {userType === "remote_assistance" &&
                <div className="journal-form-row">
                  <Select
                      className='dropdown-select'
                      classNamePrefix="select"
                      isSearchable={false}
                      name="journal-entry-type"
                      onChange={(option) => {
                        const value = option.value;
                        switch (value) {
                          case JOURNAL_ENTRY_TYPE_NORMAL:
                            setJournalModal(journalModalCur => ({
                              ...journalModalCur,
                              title: '',
                              type: value,
                              data: null
                            }));
                            break;
                          case JOURNAL_ENTRY_TYPE_PROG_CALL:
                            setJournalModal(journalModalCur => ({
                              ...journalModalCur,
                              title: t('journal.teleAssistance'),
                              type: value,
                              data: {"daysBetweenCalls": 0, "nextCall": null, "result": ""}
                            }));
                            break;
                        }
                      }}
                      options={[
                        {value: JOURNAL_ENTRY_TYPE_NORMAL, label: t('journal.journalEntryTypeJournal')},
                        {value: JOURNAL_ENTRY_TYPE_PROG_CALL, label: t('journal.journalEntryTypeProgCall')},
                      ]}
                      value={((j) => {
                        switch (j.type) {
                          case JOURNAL_ENTRY_TYPE_NORMAL:
                            return {value: j.type, label: `${t('journal.journalEntryTypeJournal')}`}
                          case JOURNAL_ENTRY_TYPE_PROG_CALL:
                            return {value: j.type, label: `${t('journal.journalEntryTypeProgCall')}`}
                        }
                      })(journalModal)}
                  />
                </div>
              }
              <div className="journal-form-row">
                <label htmlFor="journalEntryTitle">
                  <b>{`${t('journal.journalEntryTitle')}:`}</b>
                  <input
                      name="journalEntryTitle"
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setJournalModal(journalModalCur => ({...journalModalCur, title: newValue}));
                      }}
                      type="text"
                      value={`${journalModal.title}`}
                  />
                </label>
              </div>
              {journalModal.type === JOURNAL_ENTRY_TYPE_PROG_CALL && (
                <div className="journal-form-row">
                  <label htmlFor={"journal-result-type"} style={{display: 'flex'}}>
                    <b style={{display: 'inline-block', marginRight: '0.5em'}}>{`${t('journal.result')}:`}</b>
                    <Select
                      className='dropdown-select'
                      classNamePrefix="select"
                      isSearchable={false}

                      name="journal-result-type"
                      onChange={(option) => {
                        const value = option.value;
                        setJournalModal(journalModalCur => ({
                          ...journalModalCur,
                          data: {
                            "daysBetweenCalls": 0,
                            "nextCall": null,
                            "result": value
                          }
                        }));
                      }}
                      options={[
                        {value: JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED, label: t('journal.hasReplied')},
                        {value: JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED, label: t('journal.hasNotReplied')},
                      ]}
                      placeholder={t('journal.select')}
                      value={((j) => {
                        switch (j.result) {
                          case JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED:
                            return {value: JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED, label: `${t('journal.hasReplied')}`}
                          case JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED:
                            return {value: JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED, label: `${t('journal.hasNotReplied')}`}
                        }
                      })(journalModal.data)}
                    />
                  </label>
                </div>
                )}
              <div className="journal-form-row">
                <label htmlFor="journalEntryDescription">
                <b>{`${t('journal.journalEntryDescription')}:`}</b>
                  <textarea
                      defaultValue={`${journalModal.description}`}
                      name="journalEntryDescription"
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setJournalModal(journalModalCur => ({...journalModalCur, description: newValue}));
                      }}
                  />
                </label>
              </div>
              {journalModal.type === JOURNAL_ENTRY_TYPE_PROG_CALL && (
                  <div className="journal-form-row" style={{display: 'flex', flexDirection: 'row'}}>
                    <label htmlFor={"journal-periodicity-type"} style={{width: '50%'}}>
                      <b>{`${t('journal.periodicity')}:`}</b>
                      <Select
                          className='dropdown-select'
                          classNamePrefix="select"
                          isSearchable={false}
                          menuPlacement='top'
                          name="journal-periodicity-type"
                          onChange={(option) => {
                            const value = option.value;
                            if (value >= 0) {
                              switch (value) {
                                case 0:
                                  setJournalModal(journalModalCur => ({
                                    ...journalModalCur,
                                    data: {
                                      "daysBetweenCalls": 0,
                                      "nextCall": null,
                                      "result": journalModalCur.data.result
                                    }
                                  }));
                                  break;
                                default:
                                  setJournalModal(journalModalCur => ({
                                    ...journalModalCur,
                                    data: {
                                      "daysBetweenCalls": value,
                                      "nextCall": dateToTimestamp(dateAdd(new Date(), {days: value})),
                                      "result": journalModalCur.data.result,
                                    }
                                  }));
                                  break;
                              }
                            }
                          }}
                          options={[
                            {value: 0, label: `${t('none')}`},
                            {value: 1, label: `1 ${t('day')}`},
                            {value: 7, label: `7 ${t('days')}`},
                            {value: 14, label: `14 ${t('days')}`},
                            {value: 30, label: `30 ${t('days')}`},
                          ]}
                          value={((v) => {
                            switch (v) {
                              case 0:
                                return {value: 0, label: `${t('none')}`}
                              case 1:
                                return {value: 1, label: `1 ${t('day')}`}
                              case 7:
                                return {value: 1, label: `7 ${t('day')}`}
                              case 14:
                                return {value: 1, label: `14 ${t('day')}`}
                              case 30:
                                return {value: 30, label: `30 ${t('days')}`}
                            }
                          })(journalModal.data.daysBetweenCalls)}
                      />
                    </label>
                    {journalModal.data.daysBetweenCalls > 0 && (
                        <label htmlFor={"journal-next-execution"} style={{width: '50%'}}>
                          <b style={{marginLeft: '0.5em'}}>{`${t('journal.nextExecution')}:`}</b>
                          <DatePicker
                              dateFormat="Pp"
                              locale={i18n.language}
                              minDate={new Date()}
                              name="journal-next-execution"
                              onChange={
                                (date) => {
                                  setJournalModal(journalModalCur => ({
                                    ...journalModalCur,
                                    data: {
                                      "daysBetweenCalls": journalModalCur.data.daysBetweenCalls,
                                      "nextCall": dateToTimestamp(date),
                                      "result": journalModalCur.data.result,
                                    }
                                  }));
                                }
                              }
                              selected={(journalModal.data.nextCall > 0) ?
                                  timestampToDate(journalModal.data.nextCall) : null}
                              showDisabledMonthNavigation
                              showMonthDropdown
                              showTimeSelect
                              showYearDropdown
                              timeIntervals={15}
                          />
                        </label>
                    )}
                  </div>
              )}
              <div className="journal-add-entry-form-buttons">
                <div></div>
                <div>
                  <button
                      className="cbutton cancelBt"
                      onClick={() => {
                        // closeJournalModal();
                        setType('show');
                      }}
                      type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button
                      className="cbutton saveBt"
                      onClick={() => {
                        if(journalModal.title === "") {
                          confirmAlert({
                            title: `${t('Error')}`,
                            message: `${t('journal.missingTitle')}`,
                            buttons: [
                              {
                                label: `${t('OK')}`,
                              },
                            ],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                            overlayClassName: "overlay-confirm-alert-over-all"
                          });
                          return
                        }
                        if(journalModal.type === JOURNAL_ENTRY_TYPE_NORMAL || journalModal.type === JOURNAL_ENTRY_TYPE_CAREGIVER) {
                          if(journalModal.description === "") {
                            confirmAlert({
                              title: `${t('Error')}`,
                              message: `${t('journal.missingDescription')}`,
                              buttons: [
                                {
                                  label: `${t('OK')}`,
                                },
                              ],
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              overlayClassName: "overlay-confirm-alert-over-all"
                            });
                            return
                          }
                          requestJournalAddition(journalModal);
                        } else if(journalModal.type === JOURNAL_ENTRY_TYPE_PROG_CALL) {
                          if(journalModal.data.result === "") {
                            confirmAlert({
                              title: `${t('Error')}`,
                              message: `${t('journal.missingResult')}`,
                              buttons: [
                                {
                                  label: `${t('OK')}`,
                                },
                              ],
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              overlayClassName: "overlay-confirm-alert-over-all"
                            });
                            return
                          }
                          if(journalModal.data.daysBetweenCalls === 0) {
                            // console.log(journalModal)
                            confirmAlert({
                              title: `${t("journal.noMoreProgrammedCalls")}`,
                              buttons: [
                                {
                                  label: `${t("Yes")}`,
                                  onClick: () => {
                                    requestJournalAddition(journalModal);
                                    reloadProgrammedCalls();
                                  },
                                },
                                {
                                  label: `${t("No")}`,
                                },
                              ],
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              overlayClassName: "overlay-confirm-alert-over-all"
                            });
                          } else {
                            requestJournalAddition(journalModal);
                            reloadProgrammedCalls();
                          }
                        }
                      }}
                      type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
          )
      }
    </Modal>
  )
}
