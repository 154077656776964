import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faTimes,
  faConciergeBell,
  faUserNurse,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ActiveTile from './ActiveTile';
import PhoneContact from './PhoneContact';
import EmailContact from './EmailContact';
import { hexColourIsLight, colourNameToHex, timestampToDate, dateToTimestamp } from '../utilities';
import { CSSTransition } from 'react-transition-group';
import { format as formatDate } from 'date-fns/esm';
import { it, enUS, enGB, de, fr } from 'date-fns/esm/locale';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import Select from 'react-select';
import { InstallationsContext } from './App';
import cloneDeep from 'lodash/cloneDeep';
import { not_null_or_undefined } from '../utilities';
import { confirmAlert } from 'react-confirm-alert';

import 'react-datepicker/dist/react-datepicker.css';

const EmptyService = {
  id: 0,
  title: '',
  description: '',
  color: 'white',
  type: 0,
  startDateUtc: 0,
  expireDateUtc: 0,
};

//Used by date-fns/esm format
const locales = { it, de, fr, enGB, enUS };

//Used by react-datepicker
registerLocale('it', it);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en-US', enUS);
registerLocale('en-GB', enGB);
registerLocale('en', enUS);
setDefaultLocale('it');

export default function Services(props) {
  const { allowModify, standalone, installationServices, setInstallationServices } = props;

  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [serviceModal, setServiceModal] = useState(null);
  const [showExpired, setShowExpired] = useState(false);
  const [modalType, setModalType] = useState('show');
  const [extServiceProv, setExtServiceProv] = useState('unset');
  const [serviceTemplates, setServiceTemplates] = useState(null);
  const [selServiceTemplate, setSelServiceTemplate] = useState(1);

  const ALLOWED_COLORS = [
    { value: 'white', label: t('white') },
    { value: 'black', label: t('black') },
    { value: 'green', label: t('green') },
    { value: 'blue', label: t('blue') },
    { value: 'gray', label: t('gray') },
    { value: 'yellow', label: t('yellow') },
    { value: 'red', label: t('red') },
    { value: 'orange', label: t('arancio') },
    { value: 'violet', label: t('viola') },
    { value: 'brown', label: t('marrone') },
    { value: '#16593a', label: t('dark green') },
  ];

  const ALLOWED_TYPES = [
    { value: 0, label: t('home service') },
    { value: 1, label: t('medical service') },
    { value: 2, label: t('shopping service') },
    { value: 3, label: t('nurse service') },
  ];

  const {
    selectedInst,
    requestExternalServiceProviders,
    requestServiceTemplates,
    deleteInstService,
    updateInstService,
    createInstService,
  } = React.useContext(InstallationsContext);

  const nodeRef = React.useRef(null)

  function convertColorNameToColor(color) {
    const colorFound = ALLOWED_COLORS.find(el => el.value === color);
    if (colorFound) return colorFound;

    return null;
  }

  async function handleServiceEditDataRequests() {
    if (extServiceProv === null || serviceTemplates === null) {
      setExtServiceProv('loading'); //Avoid multiple unnecessary calls
      setServiceTemplates('loading'); //Avoid multiple unnecessary calls
      const providers = await requestExternalServiceProviders();
      setExtServiceProv(
        providers.map((provider) => {
          return {
            value: provider,
            label: `${provider.name ? provider.name : ''}${provider.name && provider.surname ? ' ' : ''}${
              provider.surname ? provider.surname : ''
            }${provider.name || provider.surname ? ', ' : ''}${provider.organization ? provider.organization : ''}`,
          };
        })
      );
      const templates = await requestServiceTemplates();
      if (not_null_or_undefined(templates)) {
        setServiceTemplates(
          templates.map((template) => {
            return { value: template, label: `${template.title}` };
          })
        );
      }
    }
  }

  function findExternalServiceProvider(id, extServiceProvOptions) {
    if (id === null) return null;

    if (!Array.isArray(extServiceProvOptions)) return null;

    let selectedProvider = null;
    extServiceProvOptions.forEach((provider) => {
      if (provider.value.id === id) {
        selectedProvider = provider;
      }
    });

    return selectedProvider;
  }

  function handleAddNewService(e) {
    setModalType('create');
    setServiceModal({ ...EmptyService });
    handleServiceEditDataRequests();
  }

  async function requestServiceAddition(instSerial, newService) {
    const createdService = await createInstService(instSerial, newService);
    const newServices = cloneDeep(installationServices);
    newServices.set(createdService.id, createdService);
    setInstallationServices(newServices);
  }

  function toggleShow() {
    setShow((show) => {
      return !show;
    });
    setModalType('show');
  }

  function serviceOnClickHandler(service) {
    setServiceModal(service);
  }

  function closeServiceModal() {
    setServiceModal(null);
    setModalType('show');
  }

  function decodeTypeToIcon(type) {
    switch (type) {
      case 1:
        return faUserNurse;

      default:
      case 0:
        return faConciergeBell;
    }
  }

  function countUnexpiredServices() {
    let count = 0;
    [...installationServices.values()].forEach((service) => {
      if (service.expireDateUtc * 1000 >= new Date().getTime()) count++;
    });
    return count;
  }

  async function deleteService(serviceId) {
    confirmAlert({
      title: `${t('services.confirmDeletion')}`,
      buttons: [
        {
          label: `${t('Yes')}`,
          onClick: async () => {
            try {
              await deleteInstService(selectedInst, serviceId);
              const newServices = cloneDeep(installationServices);
              newServices.delete(serviceId);
              setInstallationServices(newServices);
            } catch (ex) {
              alert('Error: ' + ex.message());
            }
          },
        },
        {
          label: `${t('No')}`,
        },
      ],
    });
  }

  async function saveChangesToService(serviceInfo) {
    const updated = await updateInstService(selectedInst, serviceInfo.id, serviceInfo);
    const newServices = cloneDeep(installationServices);
    newServices.forEach((service, idx, services) => {
      if (service.id === serviceInfo.id) {
        services.set(idx, updated);
      }
    });
    setInstallationServices(newServices);
  }

  function renderModal() {
    console.log(installationServices);

    return (
      <Modal
        classNames={{
          // overlay: 'customOverlay',
          modal: 'fullWidthModal',
        }}
        onClose={closeServiceModal}
        open={serviceModal !== null}
      >
        {serviceModal !== null && modalType === 'show' && (
          <>
            <h1>{t('services.servicesDetailTitle')}</h1>
            <hr />
            <p>
              <b>{`${t('services.serviceTitle')}:`}</b> {`${serviceModal.title}`}
            </p>
            <p>
              <b>{`${t('services.serviceDescription')}:`}</b> {`${serviceModal.description}`}
            </p>
            {serviceModal.startDateUtc != null && (
                <p>
                  <b>{`${t('services.startDate')}:`}</b>{' '}
                  {`${formatDate(timestampToDate(serviceModal.startDateUtc), 'Pp', {locale: locales[i18n.language]})}`}
                </p>
            )}
            {serviceModal.expireDateUtc != null && (
                <p>
                  <b>{`${t('services.expireDate')}:`}</b>{' '}
                  {`${formatDate(timestampToDate(serviceModal.expireDateUtc), 'Pp', {locale: locales[i18n.language]})}`}
                </p>
            )}
            {typeof serviceModal.extServiceProvId !== 'undefined' && (
              <>
                {serviceModal.extServiceProvName != null && (
                  <p>
                    <b>{`${t('services.extName')}:`}</b> {`${serviceModal.extServiceProvName ?? '-'}`}
                  </p>
                )}
                {serviceModal.extServiceProvSurname != null && (
                  <p>
                    <b>{`${t('services.extSurname')}:`}</b> {`${serviceModal.extServiceProvSurname ?? '-'}`}
                  </p>
                )}
                {serviceModal.extServiceProvOrganization != null && (
                  <p>
                    <b>{`${t('services.extOrganization')}:`}</b> {`${serviceModal.extServiceProvOrganization ?? '-'}`}
                  </p>
                )}
                {typeof serviceModal.extServiceProvPhoneNumber !== 'undefined' && (
                  <p>
                    <b>{`${t('services.extPhoneNumber')}:`}</b>{' '}
                    <PhoneContact phone={serviceModal.extServiceProvPhoneNumber} />
                  </p>
                )}
                {typeof serviceModal.extServiceProvEmail !== 'undefined' && (
                  <p>
                    <b>{`${t('services.extEmail')}:`}</b> <EmailContact email={serviceModal.extServiceProvEmail} />
                  </p>
                )}
              </>
            )}
            {allowModify && (
              <button
                className='cbutton'
                onClick={() => {
                  setModalType('edit');
                  setServiceModal((curServiceModal) => {
                    return { ...curServiceModal };
                  });
                  handleServiceEditDataRequests();
                }}
                type='button'
              >
                {t('edit')}
              </button>
            )}
          </>
        )}
        {serviceModal !== null && modalType === 'edit' && (
          <>
            <h1>{t('services.servicesEditDetailTitle')}</h1>
            <hr />
            <form className='service-details-modify-form'>
              <div className='service-form-row'>
                <label htmlFor='serviceTitle'>
                  <b>{`${t('services.serviceTitle')}:`}</b>
                  <input
                    defaultValue={`${serviceModal.title}`}
                    name='serviceTitle'
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.title = newValue;
                        return { ...serviceModalCur };
                      });
                    }}
                    type='text'
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='serviceDescription'>
                  <b>{`${t('services.serviceDescription')}:`}</b>
                  <textarea
                    defaultValue={`${serviceModal.description}`}
                    name='serviceDescription'
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.description = newValue;
                        return { ...serviceModalCur };
                      });
                    }}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <b>{`${t('services.startDate')}:`}</b>
                <DatePicker
                  dateFormat='Pp'
                  locale={i18n.language}
                  onChange={(date) => {
                    setServiceModal((serviceModalCur) => {
                      serviceModalCur.startDateUtc = dateToTimestamp(date);
                      return { ...serviceModalCur };
                    });
                  }}
                  selected={timestampToDate(serviceModal.startDateUtc)}
                  showTimeSelect
                  timeFormat='p'
                />
              </div>
              <div className='service-form-row'>
                <b>{`${t('services.expireDate')}:`}</b>
                <DatePicker
                  dateFormat='Pp'
                  locale={i18n.language}
                  onChange={(date) => {
                    setServiceModal((serviceModalCur) => {
                      serviceModalCur.expireDateUtc = dateToTimestamp(date);
                      return { ...serviceModalCur };
                    });
                  }}
                  selected={timestampToDate(serviceModal.expireDateUtc)}
                  showTimeSelect
                  timeFormat='p'
                />
              </div>
              <div className='service-form-row'>
                <label htmlFor='color'>
                  <b>{`${t('services.color')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select'
                    defaultValue={convertColorNameToColor(serviceModal.color)}
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='color'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.color = option.value;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={ALLOWED_COLORS}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='color'>
                  <b>{`${t('services.type')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select'
                    defaultValue={ALLOWED_TYPES[serviceModal.type]}
                    isSearchable={true}
                    menuPlacement='top'
                    name='type'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.type = option.value;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={ALLOWED_TYPES}
                    // getOptionLabel={option => `${t(option.value)}`}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='extServiceProvider'>
                  <b>{`${t('services.externatServiceProvider')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select2'
                    // defaultValue={selExtServiceProv}
                    // defaultValue={findExternalServiceProvider(serviceModal.extServiceProvId, extServiceProv)}
                    isClearable={true}
                    isDisabled={false}
                    isLoading={!Array.isArray(extServiceProv)}
                    isSearchable={true}
                    menuPlacement='top'
                    name='extServiceProvider'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.extServiceProvId = option ? option.value.id : null;
                        serviceModalCur.extServiceProvName = option ? option.value.name : null;
                        serviceModalCur.extServiceProvSurname = option ? option.value.surname : null;
                        serviceModalCur.extServiceProvOrganization = option ? option.value.organization : null;
                        serviceModalCur.extServiceProvPhoneNumber = option ? option.value.phoneNumber : null;
                        serviceModalCur.extServiceProvEmail = option ? option.value.email : null;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={extServiceProv}
                    // getOptionLabel={option => `${option.name} ${option.surname}, ${option.organization}`}
                    // getOptionValue={option => option.id}
                    placeholder={t('services.selectExternalServiceProvider')}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                    value={findExternalServiceProvider(serviceModal.extServiceProvId, extServiceProv)}
                  />
                </label>
              </div>
              {
                <div className='service-form-readonly-lines'>
                  <p>
                    <b>{`${t('services.extName')}:`}</b> {`${serviceModal.extServiceProvName ?? '-'}`}
                  </p>
                  <p>
                    <b>{`${t('services.extSurname')}:`}</b> {`${serviceModal.extServiceProvSurname ?? '-'}`}
                  </p>
                  <p>
                    <b>{`${t('services.extOrganization')}:`}</b> {`${serviceModal.extServiceProvOrganization ?? '-'}`}
                  </p>
                  {typeof serviceModal.extServiceProvPhoneNumber !== 'undefined' &&
                    serviceModal.extServiceProvPhoneNumber !== null && (
                      <p>
                        <b>{`${t('services.extPhoneNumber')}:`}</b>{' '}
                        <PhoneContact phone={serviceModal.extServiceProvPhoneNumber} />
                      </p>
                    )}
                  {typeof serviceModal.extServiceProvEmail !== 'undefined' &&
                    serviceModal.extServiceProvEmail !== null && (
                      <p>
                        <b>{`${t('services.extEmail')}:`}</b> <EmailContact email={serviceModal.extServiceProvEmail} />
                      </p>
                    )}
                </div>
              }

              <div className='service-details-modify-buttons'>
                <button
                  className='cbutton deleteBt'
                  onClick={() => {
                    deleteService(serviceModal.id);
                    closeServiceModal();
                  }}
                  type='button'
                >
                  {t('delete')}
                </button>
                <div>
                  <button
                    className='cbutton cancelBt'
                    onClick={() => {
                      closeServiceModal();
                    }}
                    type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button
                    className='cbutton saveBt'
                    onClick={() => {
                      saveChangesToService(serviceModal);
                      closeServiceModal();
                    }}
                    type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
        {serviceModal !== null && modalType === 'create' && (
          <>
            <h1>{t('services.servicesNewDetailTitle')}</h1>
            <hr />
            <div className='service-form-row'>
              <label htmlFor='serviceTemplate'>
                <b>{`${t('services.serviceTemplate')}:`}</b>
                <Select
                  className='dropdown-select'
                  classNamePrefix='select2'
                  isClearable={true}
                  isDisabled={false}
                  isLoading={!Array.isArray(serviceTemplates)}
                  isSearchable={true}
                  menuPlacement='bottom'
                  name='serviceTemplate'
                  onChange={(option) => {
                    setSelServiceTemplate(option);
                    setServiceModal((serviceModalCur) => {
                      serviceModalCur.title = option ? option.value.title : EmptyService.title;
                      serviceModalCur.description = option ? option.value.description : EmptyService.description;
                      serviceModalCur.color = option ? option.value.color : EmptyService.color;
                      serviceModalCur.type = option ? option.value.type : EmptyService.type;
                      serviceModalCur.extServiceProvId = option ? option.value.externalServiceProviderId : null;
                      const extProv = findExternalServiceProvider(serviceModalCur.extServiceProvId, extServiceProv);
                      serviceModalCur.extServiceProvName = extProv ? extProv.value.name : null;
                      serviceModalCur.extServiceProvSurname = extProv ? extProv.value.surname : null;
                      serviceModalCur.extServiceProvOrganization = extProv ? extProv.value.organization : null;
                      serviceModalCur.extServiceProvPhoneNumber = extProv ? extProv.value.phoneNumber : null;
                      serviceModalCur.extServiceProvEmail = extProv ? extProv.value.email : null;
                      serviceModalCur.startDateUtc = dateToTimestamp(new Date());
                      serviceModalCur.expireDateUtc = option
                        ? dateToTimestamp(new Date()) + 3600 * 24 * option.value.duration
                        : dateToTimestamp(new Date());
                      return { ...serviceModalCur };
                    });
                  }}
                  options={serviceTemplates}
                  placeholder={t('-')}
                  styles={{
                    singleValue: (styles, { data }) => {
                      return { ...styles, color: '#797979' };
                    },
                  }}
                  value={selServiceTemplate}
                />
              </label>
            </div>
            <form className='service-details-modify-form'>
              <div className='service-form-row'>
                <label htmlFor='serviceTitle'>
                  <b>{`${t('services.serviceTitle')}:`}</b>
                  <input
                    defaultValue={`${serviceModal.title}`}
                    name='serviceTitle'
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.title = newValue;
                        // return {...serviceModalCur}
                        return serviceModalCur;
                      });
                    }}
                    type='text'
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='serviceDescription'>
                  <b>{`${t('services.serviceDescription')}:`}</b>
                  <textarea
                    defaultValue={`${serviceModal.description}`}
                    name='serviceDescription'
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.description = newValue;
                        return { ...serviceModalCur };
                      });
                    }}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <b>{`${t('services.startDate')}:`}</b>
                <DatePicker
                  dateFormat='Pp'
                  locale={i18n.language}
                  onChange={(date) => {
                    setServiceModal((serviceModalCur) => {
                      serviceModalCur.startDateUtc = dateToTimestamp(date);
                      return { ...serviceModalCur };
                    });
                  }}
                  selected={timestampToDate(
                    serviceModal.startDateUtc > 0 ? serviceModal.startDateUtc : dateToTimestamp(new Date())
                  )}
                  showTimeSelect
                  timeFormat='p'
                />
              </div>
              <div className='service-form-row'>
                <b>{`${t('services.expireDate')}:`}</b>
                <DatePicker
                  dateFormat='Pp'
                  locale={i18n.language}
                  onChange={(date) => {
                    setServiceModal((serviceModalCur) => {
                      serviceModalCur.expireDateUtc = dateToTimestamp(date);
                      return { ...serviceModalCur };
                    });
                  }}
                  selected={timestampToDate(
                    serviceModal.expireDateUtc > 0 ? serviceModal.expireDateUtc : dateToTimestamp(new Date())
                  )}
                  showTimeSelect
                  timeFormat='p'
                />
              </div>
              <div className='service-form-row'>
                <label htmlFor='color'>
                  <b>{`${t('services.color')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select'
                    getOptionLabel={option => `${t(option.label)}`}
                    isSearchable={true}
                    menuPlacement='top'
                    name='color'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.color = option.value;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={ALLOWED_COLORS}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                    // defaultValue={{value: serviceModal.color, label: serviceModal.color}}
                    value={convertColorNameToColor(serviceModal.color)}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='color'>
                  <b>{`${t('services.type')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select'
                    isSearchable={true}
                    menuPlacement='top'
                    name='type'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.type = option.value;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={ALLOWED_TYPES}
                    //defaultValue={ALLOWED_TYPES[serviceModal.type]}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                    value={ALLOWED_TYPES[serviceModal.type]}
                    // getOptionLabel={option => `${t(option.value)}`}
                  />
                </label>
              </div>
              <div className='service-form-row'>
                <label htmlFor='extServiceProvider'>
                  <b>{`${t('services.externatServiceProvider')}:`}</b>
                  <Select
                    className='dropdown-select'
                    classNamePrefix='select2'
                    // defaultValue={selExtServiceProv}
                    // defaultValue={findExternalServiceProvider(serviceModal.extServiceProvId, extServiceProv)}
                    isClearable={true}
                    isDisabled={false}
                    isLoading={!Array.isArray(extServiceProv)}
                    isSearchable={true}
                    menuPlacement='top'
                    name='extServiceProvider'
                    onChange={(option) => {
                      setServiceModal((serviceModalCur) => {
                        serviceModalCur.extServiceProvId = option ? option.value.id : null;
                        serviceModalCur.extServiceProvName = option ? option.value.name : null;
                        serviceModalCur.extServiceProvSurname = option ? option.value.surname : null;
                        serviceModalCur.extServiceProvOrganization = option ? option.value.organization : null;
                        serviceModalCur.extServiceProvPhoneNumber = option ? option.value.phoneNumber : null;
                        serviceModalCur.extServiceProvEmail = option ? option.value.email : null;
                        return { ...serviceModalCur };
                      });
                    }}
                    options={extServiceProv}
                    // getOptionLabel={option => `${option.name} ${option.surname}, ${option.organization}`}
                    // getOptionValue={option => option.id}
                    placeholder={t('services.selectExternalServiceProvider')}
                    styles={{
                      singleValue: (styles, { data }) => {
                        return { ...styles, color: '#797979' };
                      },
                    }}
                    value={findExternalServiceProvider(serviceModal.extServiceProvId, extServiceProv)}
                  />
                </label>
              </div>
              {
                <div className='service-form-readonly-lines'>
                  <p>
                    <b>{`${t('services.extName')}:`}</b> {`${serviceModal.extServiceProvName ?? '-'}`}
                  </p>
                  <p>
                    <b>{`${t('services.extSurname')}:`}</b> {`${serviceModal.extServiceProvSurname ?? '-'}`}
                  </p>
                  <p>
                    <b>{`${t('services.extOrganization')}:`}</b> {`${serviceModal.extServiceProvOrganization ?? '-'}`}
                  </p>
                  {typeof serviceModal.extServiceProvPhoneNumber !== 'undefined' &&
                    serviceModal.extServiceProvPhoneNumber !== null && (
                      <p>
                        <b>{`${t('services.extPhoneNumber')}:`}</b>{' '}
                        <PhoneContact phone={serviceModal.extServiceProvPhoneNumber} />
                      </p>
                    )}
                  {typeof serviceModal.extServiceProvEmail !== 'undefined' &&
                    serviceModal.extServiceProvEmail !== null && (
                      <p>
                        <b>{`${t('services.extEmail')}:`}</b> <EmailContact email={serviceModal.extServiceProvEmail} />
                      </p>
                    )}
                </div>
              }

              <div className='service-details-modify-buttons'>
                <div></div>
                <div>
                  <button
                    className='cbutton cancelBt'
                    onClick={() => {
                      setSelServiceTemplate(null);
                      closeServiceModal();
                    }}
                    type='button'
                  >
                    {t('cancel')}
                  </button>
                  <button
                    className='cbutton saveBt'
                    onClick={() => {
                      requestServiceAddition(selectedInst, serviceModal);
                      setSelServiceTemplate(null);
                      closeServiceModal();
                    }}
                    type='button'
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </Modal>
    );
  }

  function renderServices() {
    return (
      <div className='info-services'>
        {installationServices instanceof Map &&
          [...installationServices.values()].map((service) => {
            let icon = decodeTypeToIcon(service.type);
            let tileState = 'active-state';
            let isExpired = false;

            if (service.expireDateUtc * 1000 < new Date().getTime()) {
              if (!showExpired) return null;

              isExpired = true;
            }

            return (
              <ActiveTile
                key={service.id}
                descType={''}
                details={`${service.description}`}
                iconBgColor={service.color}
                iconColor={hexColourIsLight(colourNameToHex(service.color)) ? 'black' : 'white'}
                iconName={icon}
                moreDetails={
                  <>
                    {service.extServiceProvId &&
                      `${service.extServiceProvName ? service.extServiceProvName : ''}${
                        service.extServiceProvName && service.extServiceProvSurname ? ' ' : ''
                      }${service.extServiceProvSurname ? service.extServiceProvSurname : ''}${
                        service.extServiceProvName || service.extServiceProvSurname ? ', ' : ''
                      }${service.extServiceProvOrganization ? service.extServiceProvOrganization : ''}`}
                    {(service.extServiceProvPhoneNumber || service.extServiceProvEmail) && <br />}
                    {service.extServiceProvPhoneNumber && (
                      <PhoneContact
                        phone={service.extServiceProvPhoneNumber}
                        prefix={t('services.phoneContactPrefix')}
                      />
                    )}
                    {(service.extServiceProvPhoneNumber || service.extServiceProvEmail) && <>,{'\u00A0'}</>}
                    {service.extServiceProvEmail && (
                      <EmailContact email={service.extServiceProvEmail} prefix={t('services.emailContactPrefix')} />
                    )}
                  </>
                }
                onClickHandler={() => serviceOnClickHandler(service)}
                overlayIcon={isExpired ? faTimes : null}
                // uppercaseDetails={true}
                overlayIconColor={
                  isExpired ? (hexColourIsLight(colourNameToHex(service.color)) ? 'white' : 'black') : null
                }
                tileId={service.id}
                tileState={tileState}
                title={`${service.title}`}
                useFontAwesome={true}
              />
            );
          })}
      </div>
    );
  }

  return (
    <div className={standalone ? 'services-div' : 'services-div-embedded'}>
      <h1
        className={standalone ? 'services-header' : 'services-header-embedded'}
        onClick={standalone ? toggleShow : null}
      >
        {standalone && (
          <>
            <span className='collapsable-section-desc'>
              <FontAwesomeIcon icon={show ? faArrowCircleUp : faArrowCircleDown} />
              {`${t('services.title')}: ${installationServices !== null ? countUnexpiredServices() : '...'}`}
            </span>
          </>
        )}
        {!standalone && <span>{`${t('services.title')}`}</span>}
        <label className='checkbox-label show-expired-services-checkbox' htmlFor='show-expired-checkbox'>
          <input
            defaultChecked={showExpired}
            id='show-expired-checkbox'
            name='show-expired-checkbox'
            onChange={() =>
              setShowExpired((showExp) => {
                return !showExp;
              })
            }
            type='checkbox'
          />
          {t('services.showExpired')}
          <span className='checkmark' />
        </label>
        {allowModify && (
          <span className='add-service-icon'>
            <FontAwesomeIcon icon={faPlusCircle} onClick={handleAddNewService} />
          </span>
        )}
      </h1>
      <hr />
      {renderModal()}
      {standalone && (
        <CSSTransition classNames='contacts-trans' in={show} nodeRef={nodeRef} timeout={250} unmountOnExit>
          <div ref={nodeRef}>
            {renderServices()}
          </div>
        </CSSTransition>
      )}
      {!standalone && renderServices()}
    </div>
  );
}
